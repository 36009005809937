import React from 'react'

export const SetupCard = (props) => {
  return (
    <div className="setup-card">
        <h5>{props.item.heading} <span className="bold cyan-text">{props.item.coloredHeading}</span></h5>
        <p className='mb-0 mt-2 l-blue-text'>{props.item.description}</p>
    </div>
  )
}
