import React, { useEffect, useState } from "react";
import { RentFilters } from "../../../../Components/UserComponents/RentFilters";
import { allRentPlaces } from "../../../../Data";
import { RentPlacesCard } from "../../../../Components/UserComponents/RentPlacesCard";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useSelector } from "react-redux";
import { Get } from "../../../../config/api/get";
import { ADS, RENTER } from "../../../../config/constants";
import Loader from "../../../../Components/Loader";
import { Post } from "../../../../config/api/post";
import { TableFooter } from "../../../../Components/TableFooter";

export const AllRentPlaces = (props) => {
  const [loading, setLoading] = React.useState(true);
  const MySwal = withReactContent(Swal);
  const token = useSelector((state) => state.user.userToken);
  const [ads, setAds] = React.useState([]);

  const [genderFilter, setGenderFilter] = useState("");
  const [interestFilter, setInterestFilter] = useState("");
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const handleSearchFilter = (data) => {
    setSearch(data);
  };
  const handleGenderFilter = (data) => {
    setGenderFilter(data);
  };
  const handleInterestFilter = (data) => {
    setInterestFilter(data);
  };
  const handleClearFilter = (data) => {
    setSearch("");
    setGenderFilter("");
    setInterestFilter("");
  };
  const handleApplyFilter = () => {
    getInterestedAds();
  };
  const getInterestedAds = async () => {
    setLoading(true);
    let url = `${
      token ? ADS.getAllAdds : ADS.getAllAdsWithoutToken
    }?page=${currentPage}`;
    if (search) url += `&search=${search}`;
    if (genderFilter) url += `&gender=${genderFilter}`;
    Get(url, token)
      .then((response) => {
        console.log(response.data);
        if (response?.status) {
          console.log(response.data);
          let total_records = response.data.meta.total;

          console.log(total_records);
          let records_per_page = response.data.meta.per_page;
          let total_pages = Math.ceil(total_records / records_per_page);
          setAds(response.data.data);
          setCurrentPage(response.data.meta.current_page);
          setTotalRecords(total_records);
          setTotalPages(total_pages);
          setLoading(false);
        }
        if (response?.response?.status === 422) {
          setLoading(false);
          MySwal.fire({
            title: "Error!",
            text: response.response.data.message,
            icon: "error",
            confirmButtonColor: "#19D8C5",
            confirmButtonText: "Cancel",
          });
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        MySwal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
          confirmButtonColor: "#19D8C5",
          confirmButtonText: "Cancel",
        });
        setLoading(false);
      });
  };

  const handleAddInterested = async (id) => {
    let data = {
      ads_id: id,
    };
    Post(ADS.addToInterested, data, token)
      .then((response) => {
        // console.log(response.data);
        setLoading(false);

        if (response?.data?.status) {
          MySwal.fire({
            title: "Success!",
            text: response?.data?.message,
            icon: "success",
            confirmButtonColor: "#19D8C5",
            confirmButtonText: "Done",
          });
          getInterestedAds();
          console.log("response", response?.data?.message);
        } else {
          MySwal.fire({
            title: "Error!",
            text: response?.data?.message,
            icon: "error",
            confirmButtonColor: "#19D8C5",
            confirmButtonText: "Cancel",
          });
        }
        if (response?.response?.status === 422) {
          setLoading(false);

          MySwal.fire({
            title: "Error!",
            text: response.response.data.message,
            icon: "error",
            confirmButtonColor: "#19D8C5",
            confirmButtonText: "Cancel",
          });
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        MySwal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
          confirmButtonColor: "#19D8C5",
          confirmButtonText: "Cancel",
        });
        setLoading(false);
      });
  };
  useEffect(() => {
    getInterestedAds();
  }, [currentPage]);
  return (
    <section className="all-rent-places">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <RentFilters
              onSearchFilter={handleSearchFilter}
              onGenderFilter={handleGenderFilter}
              onInterestFilter={handleInterestFilter}
              onClearFilter={handleClearFilter}
              onApplyFilter={handleApplyFilter}
            />
          </div>
        </div>
        <div className="row">
          {/* {allRentPlaces.map((ele) => (
            <div className="col-xl-4 col-md-6 my-3" key={ele.id}>
              <RentPlacesCard
                interestedAds={props.interestedAds ? props.interestedAds : ""}
                item={ele}
              />
            </div>
          ))} */}
          {loading ? (
            <Loader />
          ) : (
            ads.map((ele) =>
              true ? (
                <div className="col-xl-4 col-md-6 my-3" key={ele.id}>
                  <RentPlacesCard
                    onAddInterested={handleAddInterested}
                    item={ele}
                  />
                </div>
              ) : (
                ""
              )
            )
          )}
        </div>
        <TableFooter
          showingItem={ads.length}
          totalItems={totalRecords}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      </div>
    </section>
  );
};
