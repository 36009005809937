import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { images } from "../../Assets";
import { SiteModal } from "../SiteModal";
import { ADMIN } from "../../config/constants/admin";
import { Post } from "../../config/api/post";
import { useDispatch, useSelector } from "react-redux";
import { removeUser } from "../../redux/slice/authSlice";

export const SideBar = (props) => {
  const urlPath = window.location.pathname;
  let token = useSelector((state) => state.user.userToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [load, setLoad] = useState(false);

  const actionMethod = () => {
    setLoad(true);
    
    Post(ADMIN.auth.logout, {}, token)
    .then(() => {
      setTimeout(() => {
        setShow(false);
        setLoad(false);
        dispatch(removeUser());
        navigate('/admin');
      }, 750);
    })
    .catch(err => {
      console.error(err);
      setLoad(false);
    });
  };

  return (
    <>
      <div className={`sidebar ${props.sideClass}`} id="sidebar">
        <ul className="ps-0">
          <li className={`mt-3 ${urlPath.includes("dash") ? "active" : ""}`}>
            <Link className="sidebar-links" to="/admin/dashboard">
              <span className="dash-icons flex-shrink-0">
                <img
                  src={images.sideBarIcon1}
                  alt=""
                  className="img-fluid me-2"
                />
              </span>
              <span className="dash-link">Dashboard</span>{" "}
            </Link>
          </li>
          <li className={`mt-2 ${urlPath.includes("user") ? "active" : ""}`}>
            <Link className="sidebar-links" to="/admin/user">
              <span className="dash-icons flex-shrink-0">
                <img
                  src={images.sideBarIcon2}
                  alt=""
                  className="img-fluid me-2"
                />
              </span>
              <span className="dash-link">User Management</span>
            </Link>
          </li>
          <li className={`mt-2 ${urlPath.includes("renter") ? "active" : ""}`}>
            <Link className="sidebar-links" to="/admin/renter">
              <span className="dash-icons flex-shrink-0">
                <img
                  src={images.sideBarIcon3}
                  alt=""
                  className="img-fluid me-2"
                />
              </span>
              <span className="dash-link">Renter Management</span>
            </Link>
          </li>
          <li
            className={`mt-2 ${urlPath.includes("interest") ? "active" : ""}`}
          >
            <Link className="sidebar-links" to="/admin/interest">
              <span className="dash-icons flex-shrink-0">
                <img
                  src={images.sideBarIcon4}
                  alt=""
                  className="img-fluid me-2"
                />
              </span>
              <span className="dash-link">Interest Management</span>
            </Link>
          </li>
          <li
            className={`mt-2 ${
              urlPath.includes("subscription") ? "active" : ""
            }`}
          >
            <Link className="sidebar-links" to="/admin/subscription">
              <span className="dash-icons flex-shrink-0">
                <img
                  src={images.sideBarIcon5}
                  alt=""
                  className="img-fluid me-2"
                />
              </span>
              <span className="dash-link">Subscription Management</span>
            </Link>
          </li>
          <li className={`mt-2 ${urlPath.includes("credit") ? "active" : ""}`}>
            <Link className="sidebar-links" to="/admin/credit-fees">
              <span className="dash-icons flex-shrink-0">
                <img
                  src={images.sideBarIcon6}
                  alt=""
                  className="img-fluid me-2"
                />
              </span>
              <span className="dash-link">Credit Fees Management</span>
            </Link>
          </li>
          <li className={`mt-2 ${urlPath.includes("ads") ? "active" : ""}`}>
            <Link className="sidebar-links" to="/admin/ads">
              <span className="dash-icons flex-shrink-0">
                <img
                  src={images.sideBarIcon7}
                  alt=""
                  className="img-fluid me-2"
                />
              </span>
              <span className="dash-link">View Ads</span>
            </Link>
          </li>
          <li className={`mt-2 ${urlPath.includes("payment") ? "active" : ""}`}>
            <Link className="sidebar-links" to="/admin/payment">
              <span className="dash-icons flex-shrink-0">
                <img
                  src={images.sideBarIcon8}
                  alt=""
                  className="img-fluid me-2"
                />
              </span>
              <span className="dash-link">Payment Logs</span>
            </Link>
          </li>
          <li
            className={`mt-2 ${urlPath.includes("feedback") ? "active" : ""}`}
          >
            <Link className="sidebar-links" to="/admin/feedback">
              <span className="dash-icons flex-shrink-0">
                <img
                  src={images.sideBarIcon9}
                  alt=""
                  className="img-fluid me-2"
                />
              </span>
              <span className="dash-link">Feedback Logs</span>
            </Link>
          </li>
          {/* <li className={`mt-2 ${urlPath.includes("change") ? "active" : ""}`}>
            <Link className="sidebar-links" to="/admin/change-password">
              <span className="dash-icons flex-shrink-0">
                <img
                  src={images.sideBarIcon10}
                  alt=""
                  className="img-fluid me-2"
                />
              </span>
              <span className="dash-link">Change Password</span>
            </Link>
          </li> */}
          <li className={`mt-2 ${urlPath.includes("logout") ? "active" : ""}`}>
            <Link className="sidebar-links" onClick={() => setShow(true)}>
              <span className="dash-icons flex-shrink-0">
                <img
                  src={images.sideBarIcon11}
                  alt=""
                  className="img-fluid me-2"
                />
              </span>
              <span className="dash-link">Log Out</span>
            </Link>
          </li>
        </ul>
      </div>

      <SiteModal
        actionMethod={actionMethod}
        modalType
        btnLoad={load}
        show={show}
        handleClose={() => setShow(false)}
        modalImg={images.questionIcon}
        modalTitle="Logout"
        modalText="Are you sure you want to log out?"
      />
    </>
  );
};
