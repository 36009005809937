import {React, useState, useEffect} from 'react';
import { SiteHeader } from './header.js';
import { SiteFooter } from './footer.js';

export const UserLayout = (props) => {
    return (
        <>
            <div className="user-wrapper">
                <SiteHeader />
                {props.children}
                {props.noFooter?'':(<SiteFooter />)}
            </div>
        </>
    )
}
