import React from "react";
import { UserLayout } from "../../../Components/UserComponents/UserLayout/userLayout";
import { SiteModal } from "../../../Components/SiteModal";
import { images } from "../../../Assets";
import SiteInput from "../../../Components/Input/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SiteButton from "../../../Components/Button/button";
import { Link, useNavigate } from "react-router-dom";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Post } from "../../../config/api/post";
import { AUTH, SIGNUP } from "../../../config/constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CustomSelect } from "../../../Components/Select";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

export const RenterRegister = () => {
  const [eyeIcon, seteyeIcon] = useState(faEyeSlash);
  const [passwordType, setPasswordType] = useState(0);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [bio, setBio] = React.useState("");
  const [gender, setGender] = React.useState("male");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [isValid, setIsValid] = useState(true);
  const MySwal = withReactContent(Swal);

  const navigate = useNavigate();
  const toggleIcon = () => {
    if (eyeIcon == faEyeSlash) {
      seteyeIcon(faEye);
      setPasswordType(1);
    } else {
      seteyeIcon(faEyeSlash);
      setPasswordType(0);
    }
  };
  const [eyeIcon2, seteyeIcon2] = useState(faEyeSlash);
  const [passwordType2, setPasswordType2] = useState(0);
  const toggleIcon2 = () => {
    if (eyeIcon2 == faEyeSlash) {
      seteyeIcon2(faEye);
      setPasswordType2(1);
    } else {
      seteyeIcon2(faEyeSlash);
      setPasswordType2(0);
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    navigate("/renter/login");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    setLoading(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setLoading(false);
      return false;
    }
    {
      if (password !== confirmPassword) {
        MySwal.fire({
          title: "Error!",
          text: "Password and confirm password does not match",
          icon: "error",
          confirmButtonColor: "#19D8C5",
          confirmButtonText: "Cancel",
        });
        setLoading(false);
        return false;
      }
      if (!isValid) {
        setLoading(false);
        return false;
      }
      let data = {
        first_name: firstName,
        last_name: lastName,
        bio: bio,
        gender: gender,
        phone: phone,
        email: email,
        password: password,
      };
      console.log(data);
      Post(SIGNUP.signUpRenter, data)
        .then((response) => {
          // console.log(response.data);
          setLoading(false);
          if (response?.data?.status) {
            MySwal.fire({
              title: "Success!",
              text: response?.data?.message,
              icon: "success",
              confirmButtonColor: "#19D8C5",
              confirmButtonText: "Done",
            });
            setTimeout(() => {
              navigate("/renter/login");
            }, 1000);

            console.log("response", response?.data?.message);
          } else {
            MySwal.fire({
              title: "Error!",
              text: response?.data?.message,
              icon: "error",
              confirmButtonColor: "#19D8C5",
              confirmButtonText: "Cancel",
            });
          }
          if (response?.response?.status === 422) {
            setLoading(false);

            MySwal.fire({
              title: "Error!",
              text: response.response.data.message,
              icon: "error",
              confirmButtonColor: "#19D8C5",
              confirmButtonText: "Cancel",
            });
          }
        })
        .catch((e) => {
          console.log(":::;", e);
          MySwal.fire({
            title: "Error!",
            text: "Something went wrong",
            icon: "error",
            confirmButtonColor: "#19D8C5",
            confirmButtonText: "Cancel",
          });
          setLoading(false);
        });
    }
  };

  return (
    <UserLayout noFooter>
      <section className="registration">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="auth-card">
                <div className="text-center">
                  <h3 className="cyan-text bold">Sign Up</h3>
                  <p className="mb-0">Register Your Account</p>
                </div>
                <div className="row justify-content-center">
                  <div className="col-xxl-9 col-lg-10">
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <div className="row align-items-end py-md-5 py-sm-4 py-3">
                        <div className="col-md-6 my-2">
                          <Form.Group as={Col} controlId="validationCustom01">
                            <Form.Label className="ms-3 bold">
                              First Name<span className="red-text">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              className="grey-input with-shadow"
                              placeholder="First Name"
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter first name.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-md-6 my-2">
                          <Form.Group as={Col} controlId="validationCustom02">
                            <Form.Label className="ms-3 bold">
                              Last Name<span className="red-text">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              className="grey-input with-shadow"
                              placeholder="Last Name"
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter last name.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-md-6 my-2">
                          <Form.Group as={Col} controlId="validationCustom03">
                            <Form.Label className="ms-3 bold">
                              Phone<span className="red-text">*</span>
                            </Form.Label>
                            <IntlTelInput
                              preferredCountries={['us']}
                              inputClassName="with-shadow grey-input form-control"
                              onPhoneNumberChange={(isValid, value, selectedCountryData, fullNumber) => {
                                setPhone("+" + selectedCountryData.dialCode + value);
                                setIsValid(isValid);
                              }}
                            />
                            {!isValid ? (
                              <small className="red-text">
                                Please Enter valid contact number
                              </small>
                            ) : (
                              ""
                            )}
                            <Form.Control.Feedback type="invalid">
                              Please enter phone number.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-md-6 my-2">
                          <Form.Group as={Col} controlId="validationCustom03">
                            <Form.Label className="ms-3 bold">
                              Email<span className="red-text">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              type="email"
                              className="grey-input with-shadow"
                              placeholder="Email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter email.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-md-6 my-2">
                          <Form.Group controlId="validationCustom04">
                            <Form.Label className="bold ms-3 mb-1">
                              Password<span className="red-text">*</span>
                            </Form.Label>
                          </Form.Group>
                          <div className="position-relative">
                            <Form.Control
                              required
                              className="grey-input password-input with-shadow"
                              placeholder="Password"
                              value={password}
                              type={passwordType === 0 ? "password" : "text"}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter password.
                            </Form.Control.Feedback>
                            <FontAwesomeIcon
                              className="right-icon cyan-text"
                              onClick={toggleIcon}
                              icon={eyeIcon}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 my-2">
                          <Form.Group controlId="validationCustom05">
                            <Form.Label className="bold ms-3 mb-1">
                              Confirm Password
                              <span className="red-text">*</span>
                            </Form.Label>
                          </Form.Group>
                          <div className="position-relative">
                            <Form.Control
                              required
                              className="grey-input password-input with-shadow"
                              placeholder="Confirm Password"
                              value={confirmPassword}
                              type={passwordType2 === 0 ? "password" : "text"}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter confirm password.
                            </Form.Control.Feedback>
                            <FontAwesomeIcon
                              className="right-icon cyan-text"
                              onClick={toggleIcon2}
                              icon={eyeIcon2}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 my-2">
                          <Form.Group as={Col} controlId="validationCustom03">
                            <Form.Label className="ms-3 bold">
                              Gender<span className="red-text">*</span>
                            </Form.Label>
                            <CustomSelect
                              required
                              value={gender}
                              className="grey-input with-shadow form-select"
                              onChange={(e) => setGender(e.target.value)}
                            >
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </CustomSelect>
                            <Form.Control.Feedback type="invalid">
                              Please select gender.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-md-12 my-2">
                          <Form.Group as={Col} controlId="validationCustom02">
                            <Form.Label className="ms-3 bold">
                              Bio<span className="red-text">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              as="textarea"
                              rows={3}
                              className="grey-input with-shadow"
                              placeholder="Enter Bio"
                              value={bio}
                              onChange={(e) => setBio(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter bio.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-md-6 my-2">
                          <button
                            type="submit"
                            className="site-btn w-100"
                            disabled={loading ? true : false}
                          >
                            {loading ? "Loading..." : "Continue"}
                          </button>
                        </div>
                      </div>
                    </Form>
                    <div className="d-md-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center my-2 gap-1 justify-content-md-start justify-content-center">
                        <p className="grey-text mb-0">Already A User?</p>
                        <Link
                          to="/renter/login"
                          className="cyan-text semi-bold"
                        >
                          Login
                        </Link>
                      </div>
                      <div className="d-flex align-items-center my-2 gap-2 justify-content-md-end justify-content-center">
                        <Link
                          to="/terms-and-conditions"
                          className="cyan-text bold"
                        >
                          Terms & Condition
                        </Link>
                        <span className="cyan-text semi-bold">|</span>
                        <Link to="/privacy-policy" className="cyan-text bold">
                          Privacy Policy
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </UserLayout>
  );
};
