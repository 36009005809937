import React, { useEffect } from "react";
import { RentAdCard } from "../../Home/RecentAds/RentAdCard";
import { rentAds } from "../../../../Data";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { Get } from "../../../../config/api/get";
import { ADS, RENTER } from "../../../../config/constants";
import Loader from "../../../../Components/Loader";
import { Post } from "../../../../config/api/post";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const FriendlyApartments = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const MySwal = withReactContent(Swal);
  const token = useSelector((state) => state.user.userToken);
  const [ads, setAds] = React.useState([]);
  const getInterestedAds = async () => {
    setLoading(true);
    Get(ADS.getRecentAds)
      .then((response) => {
        console.log(response.data);
        if (response?.status) {
          setAds(response?.data);
          setLoading(false);
          //   console.log(ads);

          //   console.log("-------------", response?.data);
        }
        if (response?.response?.status === 422) {
          setLoading(false);
          MySwal.fire({
            title: "Error!",
            text: response.response.data.message,
            icon: "error",
            confirmButtonColor: "#19D8C5",
            confirmButtonText: "Cancel",
          });
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        MySwal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
          confirmButtonColor: "#19D8C5",
          confirmButtonText: "Cancel",
        });
        setLoading(false);
      });
  };
  useEffect(() => {
    getInterestedAds();
  }, []);
  return (
    <section className="recent-ads">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="regular">
              Introducing Roomies{" "}
              <span className="cyan-text semi-bold">
                {" "}
                & Rentals <br /> Friendly Apartments
              </span>
            </h2>
          </div>
        </div>
        <div className="row mt-lg-3">
          {loading ? (
            <Loader />
          ) : (
            ads.map((ele) => (
              <div className="col-xl-3 col-md-6 my-3" key={ele.id}>
                <RentAdCard item={ele} />
              </div>
            ))
          )}
        </div>
      </div>
    </section>
  );
};
