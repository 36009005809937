import React from "react";
import { images } from "../../../../Assets";
import SiteButton from "../../../../Components/Button/button";

export const AboutRoomies = () => {
  return (
    <div className="about-roomies py-sm-5 py-4">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 my-3">
            <img src={images.aboutImg} alt="" className="img-fluid w-100" />
          </div>
          <div className="col-lg-6 my-3">
            <h2 className="regular">
              About{" "}
              <span className="cyan-text semi-bold"> Roomies & Rentals</span>
            </h2>
            <p>
              At Roomies and Rentals, we welcome you to a virtual space designed
              to cater to all your needs and wants about safe and
              budget-friendly housing. Presently, it’s pretty challenging to
              find a place that is sound, decent, and affordable to call home.
              So, we intend to propose the best places to rent, including a
              trouble-free process. Besides, the platform is also instrumental
              for small-scale landlords to look for reliable tenants, initiating
              rental agreements and long-term happy relationships.
            </p>
            <ul className="about-list">
              <li>
                <div className="d-flex gap-3">
                  <div className="flex-shrink-0">
                    <span className="list-icon"></span>
                  </div>
                  <div className="flex-grow-1">
                    <p className="l-blue-text mb-0">
                      We believe in simplicity and convenience for both the
                      tenants and landlords.
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-3">
                <div className="d-flex gap-3">
                  <div className="flex-shrink-0">
                    <span className="list-icon"></span>
                  </div>
                  <div className="flex-grow-1">
                    <p className="l-blue-text mb-0">
                      Provide both parties with a simple and quick solution to
                      their housing requirements
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            {/* <SiteButton buttonText="Read More" className="web-btn mt-4" /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
