import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BASE_URL } from '../../config/constants';
import { ADMIN } from '../../config/constants/admin';
import { Layout } from '../../Components/Layout/layout'
import { CustomTable } from '../../Components/CustomTable';
import { CustomSelect } from '../../Components/Select';
import { TableFooter } from '../../Components/TableFooter';
import { BasicTableFilters } from '../../Components/CustomTable/BasicTableFilters';
import Loader from '../../Components/Loader';
import axios from 'axios';

export const userTitles = [
  "S.No",
  "Name",
  "Email",
  "Registration On",
  "Status",
  "Action",
]

const UserListing = () => {
  const token = useSelector(state => state.user.userToken);
  const [userData, setUserData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({records: 5});
  const [status, setStatus] = useState("");
  const [load, setLoad] = useState(true);

  const loadUserData = async () => {
    setLoad(true);
    let url = `${BASE_URL + ADMIN.user.get}?page=${currentPage}`;
    if(filters.records) url += `&records=${filters.records}`; 
    if(filters.from)    url += `&from=${filters.from}`; 
    if(filters.to)      url += `&to=${filters.to}`; 
    if(filters.search)  url += `&search=${filters.search}`; 
    if(filters.status)  url += `&status=${filters.status}`; 

    let data = await axios.get(url, {
      headers: {Authorization: `Bearer ${token}`}
    })
    .then(response => {
      let total_records    = response.data.data.meta.total;
      let records_per_page = response.data.data.meta.per_page;
      let total_pages      = Math.ceil(total_records / records_per_page);
      
      setUserData(response.data.data.data);
      setCurrentPage(response.data.data.meta.current_page);
      setTotalRecords(total_records);
      setTotalPages(total_pages);
      setLoad(false);
    })
    .catch(err => {
      setLoad(false);
      console.error(err.response);
    })
  }

  useEffect(() => {
    loadUserData();
  }, [filters, currentPage])

  return (
    <Layout>
      <h3 className="blue-text mb-0">User Management</h3>
      <BasicTableFilters status={status} filters={filters} setFilters={setFilters} setLoad={setLoad}>
        <label htmlFor="status" className="mt-4">Filter By Status</label>
        <CustomSelect id="status" value={status} onChange={e => setStatus(e.target.value)}>
          <option value="">All</option>
          <option value="1">Active</option>
          <option value="0">Inactive</option>
        </CustomSelect>
      </BasicTableFilters>
      <CustomTable tableTitles={userTitles}>
        {load ? (
          <tr>
            <td colSpan="100%"><Loader sm /></td>
          </tr>
        ) : (
          userData.length > 0 ? (
            userData.map((item, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>{item.date}</td>
                <td>
                  <span className={`table-tag ${item.status == 1 ? "green-tag" : 'red-tag'}`}>
                    <FontAwesomeIcon icon={faCircle}/>{item.status == 1 ? "Active" : "Inactive"}
                  </span>
                </td>
                <td>
                  <Link to={`/admin/user-details/${item.id}`}>View</Link>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="100%" className='text-center text-muted'>No record found!</td>
            </tr>
          )
        )}
      </CustomTable>
      <TableFooter 
        showingItem={userData.length} 
        totalItems={totalRecords} 
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </Layout>
  )
}

export default UserListing;
