import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { images } from "../../../Assets";
import { useNavigate } from "react-router";
import { useState } from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "../../../Components/Checkbox";
import { Link } from "react-router-dom";
import SiteButton from "../../../Components/Button/button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Post } from "../../../config/api/post";
import { AUTH } from "../../../config/constants";
import { addUser } from "../../../redux/slice/authSlice";

export const RenterLogin = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [validated, setValidated] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (user && token) {
      navigate("/", { replace: true });
    }
  }, [user, token]);

  const authCredentials = [
    {
      username: "johndoe@email.com",
      password: "Admin123",
      role: "user",
    },
    {
      username: "jason@email.com",
      password: "Renter123",
      role: "renter",
    },
  ];
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    setLoading(true);
    console.log(username + password);
    let data = {
      email: username,
      password: password,
    };
    Post(AUTH.signInRenter, data)
      .then((response) => {
        setLoading(false);
        if (response?.data?.status) {
          console.log("response", response.data.data);
          dispatch(
            addUser({
              user: response.data.data.user,
              token: response.data.data.access_token,
            })
          );
          navigate("/", { replace: true });
        } else {
          document
            .getElementById("falseCredentials")
            .classList.remove("d-none");
        }
        if (response.response.status === 422) {
          document
            .getElementById("falseCredentials")
            .classList.remove("d-none");
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  const handleLogin = () => {
    var a = authCredentials.find(
      (authCredential) =>
        username === authCredential.username &&
        password === authCredential.password
    );
    if (a ? a.role === "user" : (a = "")) {
      const token = a.role;
      localStorage.setItem("token", token);
      navigate("/");
    } else if (a.role == "renter") {
      const token = a.role;
      localStorage.setItem("token", token);
      navigate("/");
    } else {
      document.getElementById("falseCredentials").classList.remove("d-none");
    }
  };

  const [eyeIcon, seteyeIcon] = useState(faEyeSlash);
  const [passwordType, setPasswordType] = useState(0);
  const navigate = useNavigate();
  const toggleIcon = () => {
    if (eyeIcon == faEyeSlash) {
      seteyeIcon(faEye);
      setPasswordType(1);
    } else {
      seteyeIcon(faEyeSlash);
      setPasswordType(0);
    }
  };
  return (
    <section className="user-login">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 login-left d-lg-block d-none"></div>
          <div className="col-lg-6 px-lg-0">
            <div className="login-right">
              <div className="text-center">
                <img src={images.loginLogo} alt="" className="img-fluid" />
                <h3 className="mt-4 cyan-text bold">Renter Login</h3>
                <p className="grey-text">Log into Your Account</p>
              </div>
              <div className="text-center">
                <p className="red-text mb-3 d-none" id="falseCredentials">
                  Email Or Password Is Wrong !! TRY AGAIN
                </p>
              </div>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="my-3">
                  <Form.Group as={Col} controlId="validationCustom01">
                    <Form.Label className="ms-3 bold">
                      Email Address*
                    </Form.Label>
                    <Form.Control
                      required
                      type="email"
                      className="grey-input with-shadow"
                      placeholder="Email"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter email.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="my-3">
                  <Form.Group controlId="validationCustom02">
                    <Form.Label className="ms-3 bold">Password*</Form.Label>
                  </Form.Group>
                  <div className="position-relative password-input-wrapper">
                    <Form.Control
                      required
                      className="grey-input password-input with-shadow"
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type={passwordType === 0 ? "password" : "text"}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter password.
                    </Form.Control.Feedback>
                    <FontAwesomeIcon
                      className="right-icon cyan-text"
                      onClick={toggleIcon}
                      icon={eyeIcon}
                    />
                  </div>
                </div>
                <div className="d-flex black-check align-items-center justify-content-between">
                  <Checkbox
                    id="remember"
                    name="login"
                    for="remember"
                    labelText="Remember Me"
                  />
                  <Link to="/forget-password" className="black-text mt-3 mb-3">
                    Forgot Password?
                  </Link>
                </div>
                <div className="text-center mt-4 mb-3">
                  <SiteButton
                    type="submit"
                    buttonText={loading ? "Loading..." : "Login"}
                  />
                </div>
                <div className="d-flex align-items-center mt-4 gap-1 justify-content-center">
                  <p className="grey-text mb-0">Register?</p>
                  <Link to="/renter/register" className="cyan-text semi-bold">
                    Register
                  </Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
