import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

export const InnerBanner = (props) => {
  const navigate = useNavigate();
  return (
    <section className="inner-banner">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="d-flex align-items-center gap-2 mt-5">
              {props.backButton === "false" ? (
                ""
              ) : (
                <div className="flex-shrink-0">
                  <Link className="back-page-link" onClick={() => navigate(-1)}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </Link>
                </div>
              )}

              <div className="flex-grow-1">
                <h1 className="mb-0 regular">
                  {props.bannerHeading}{" "}
                  <span className="bold cyan-text">{props.coloredHeading}</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
