import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { images } from '../../Assets';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BASE_URL } from '../../config/constants';
import { ADMIN } from '../../config/constants/admin';
import { BasicTableFilters } from '../../Components/CustomTable/BasicTableFilters';
import { CustomSelect } from '../../Components/Select';
import { CustomTable } from '../../Components/CustomTable';
import { TableFooter } from '../../Components/TableFooter';
import { Layout } from '../../Components/Layout/layout'
import Loader from '../../Components/Loader';
import axios from 'axios';

export const adsTitles = [
  "S.No",
  "Appartment Name",
  "Renter Email",
  "Posted On",
  "Status",
  "Action",
]
export const adsItems = [
  {
    id: 1,
    name: "Mark Carson",
    aptName: "Abc Apartment",
    location: "49 street 2/A, prince road , owl boulevard LA",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    interest: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    features: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    email: "abc@example.com",
    imgs:[images.ad1,images.ad2,images.ad3,images.ad4,images.ad1,images.ad2,],
    date: "mm/dd/yyyy",
    status: "Active",
    action: true,
    gender: 'Male',
  },
  {
    id: 2,
    name: "John Doe",
    aptName: "Abc Apartment",
    location: "49 street 2/A, prince road , owl boulevard LA",
    interest: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    features: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    email: "abc@example.com",
    imgs:[images.ad1,images.ad2,images.ad3,images.ad4,images.ad1,images.ad2,],
    date: "mm/dd/yyyy",
    status: "Inactive",
    action: true,
    gender: 'Male',
  },
  {
    id: 3,
    name: "Mark Carson",
    aptName: "Abc Apartment",
    location: "49 street 2/A, prince road , owl boulevard LA",
    interest: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    features: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    email: "abc@example.com",
    imgs:[images.ad1,images.ad2,images.ad3,images.ad4,images.ad1,images.ad2,],
    date: "mm/dd/yyyy",
    status: "Active",
    action: true,
    gender: 'Male',
  },
  {
    id: 4,
    name: "John Doe",
    aptName: "Abc Apartment",
    location: "49 street 2/A, prince road , owl boulevard LA",
    interest: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    features: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    email: "abc@example.com",
    imgs:[images.ad1,images.ad2,images.ad3,images.ad4,images.ad1,images.ad2,],
    date: "mm/dd/yyyy",
    status: "Inactive",
    action: true,
    gender: 'Male',
  }
]

const AdsListing = () => {
  const token = useSelector(state => state.user.userToken);
  const [load, setLoad] = useState(true);
  const [ads, setAds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({records: 5});
  const [status, setStatus] = useState("");

  const loadAds = async () => {
    setLoad(true);
    let url = `${BASE_URL + ADMIN.ads.get}?page=${currentPage}`;
    if(filters.records) url += `&records=${filters.records}`; 
    if(filters.from)    url += `&from=${filters.from}`; 
    if(filters.to)      url += `&to=${filters.to}`; 
    if(filters.search)  url += `&search=${filters.search}`; 
    if(filters.status)  url += `&status=${filters.status}`; 

    let data = await axios.get(url, {
      headers: {Authorization: `Bearer ${token}`}
    })
    .then(response => {
      let total_records    = response.data.data.meta.total;
      let records_per_page = response.data.data.meta.per_page;
      let total_pages      = Math.ceil(total_records / records_per_page);
      
      setAds(response.data.data.data);
      setCurrentPage(response.data.data.meta.current_page);
      setTotalRecords(total_records);
      setTotalPages(total_pages);
      setLoad(false);
    })
    .catch(err => {
      setLoad(false)
      console.error(err.response);
    })
  }
  
  useEffect(() => {
    loadAds()
  }, [filters, currentPage])

  return (
    <Layout>
      <h3 className="blue-text mb-0">View Ads</h3>
      <BasicTableFilters status={status} filters={filters} setFilters={setFilters} setLoad={setLoad}>
        <label htmlFor="status" className="mt-4">Filter By Status</label>
        <CustomSelect id="status" value={status} onChange={e => setStatus(e.target.value)}>
          <option value="" selected>All</option>
          <option value="1">Active</option>
          <option value="0">Inactive</option>
        </CustomSelect>
      </BasicTableFilters>
      <CustomTable tableTitles={adsTitles}>
        {load ? (
          <tr>
            <td colSpan="100%"><Loader sm /></td>
          </tr>
        ) : (
          ads.length > 0 ? (
            ads.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.renter_name}</td>
                <td>{item.renter_email}</td>
                <td>{item.date}</td>
                <td>
                  <span className={`table-tag ${item.status == 1 ? "green-tag" : 'red-tag'}`}>
                    <FontAwesomeIcon icon={faCircle}/>{item.status == 1 ? "Active" : "Inactive"}
                  </span>
                </td>
                <td>
                  <Link to={`/admin/ads-details/${item.id}`}>View</Link>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="100%" className='text-center text-muted'>No record found!</td>
            </tr>
          )
        )}
      </CustomTable>
      <TableFooter 
        showingItem={ads.length} 
        totalItems={totalRecords} 
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </Layout>
  )
}

export default AdsListing;
