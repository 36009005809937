import axios from "axios";

import { removeUser } from "../../redux/slice/authSlice";
const { BASE_URL } = require("../constants");

async function Get(path, token, queryObj, abortController = {}) {
  try {
    let url = BASE_URL + path;
    const header = {
      headers: token
        ? {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        : {
            "Content-Type": "application/json",
          },
      signal: abortController.signal,
    };
    if (queryObj) {
      console.log("QueryObjheck", queryObj);

      let queryString = "";
      Object.keys(queryObj).forEach((val) => {
        if (queryObj[val]?.length > 0) {
          if (queryString.length > 0) {
            queryString += `&${val}=${queryObj[val]}`;
          } else {
            queryString += `?${val}=${queryObj[val]}`;
          }
        }
      });

      console.log("Query String", queryString);
      url += queryString;
    }
    const { data } = await axios.get(url, header);

    return data;
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.clear();
        // dispatch( removeUser() )
        // window.location.href = "/signin";
      }
    }
    return error;
  }
}

export { Get };
