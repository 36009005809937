import { images } from "../Assets";

export const rentAds = [
  {
    id: 1,
    name: "Bella Apartment",
    img: images.rentAd1,
  },
  {
    id: 2,
    name: "Bella Apartment",
    img: images.rentAd2,
  },
  {
    id: 3,
    name: "Bella Apartment",
    img: images.rentAd3,
  },
  {
    id: 4,
    name: "Bella Apartment",
    img: images.rentAd4,
  },
  {
    id: 5,
    name: "Bella Apartment",
    img: images.rentAd1,
  },
  {
    id: 6,
    name: "Bella Apartment",
    img: images.rentAd2,
  },
  {
    id: 7,
    name: "Bella Apartment",
    img: images.rentAd3,
  },
  {
    id: 8,
    name: "Bella Apartment",
    img: images.rentAd4,
  },
];

export const reviews = [
  {
    id: 1,
    img: images.reviewerImg1,
    review:
      "I had moved to Illionois got a new job and  here in Chicago it was a great challenge to look for rental studio rooms. But my God! Roomies and rentals helped a lot to get the perfect room for me and everything is pocket friendly.",
    name: "Sarah Murphy",
  },
  {
    id: 2,
    img: images.reviewerImg2,
    review:
      "Roomies n rentals are the guys, they not just  find me wonderful tenants but also released my stress that ive been into to for weeks to find someone to rent out my apartment in the NY city downtown. Happy though!",
    name: "Andy Kenan ",
  },
  {
    id: 3,
    img: images.reviewerImg2,
    review:
      "I’m not really good at finding a rental space. Roomies connected me to a fine man Mr. Smith who rented out his 1-bedroom space to me. It’s a decent place to live for a longer time in suburbs of Fairborn. Thanks and recommended…",
    name: "Jamie Butler ",
  },
];

export const subscriptionPackages = [
  {
    id: 1,
    name: "Weekly",
    price: 5.99,
    validity: 7,
  },
  {
    id: 2,
    name: "Monthly",
    price: 8.99,
    validity: 30,
  },
  {
    id: 3,
    name: "Yearly",
    price: 10.99,
    validity: 365,
  },
];

export const allRentPlaces = [
  {
    id: 1,
    name: "Bella Apartment",
    date: "15/April/2023",
    renterName: "Bella Edward",
    gender: "Female",
    address: "49 Street 2/A, Prince Road , Owl Boulevard LA",
    interested: true,
    img: images.rentAd1,
    additionalDetails:
      "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimenlorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Ext Ever Since THe 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimen Book. ",
    productImages: [
      images.rentAdThumbnail1,
      images.rentAdThumbnail2,
      images.rentAdThumbnail3,
    ],
    features: [
      "Kitchen",
      "Air Condition",
      "Washing Machine",
      "Street View",
      "Bathroom",
      "Air Condition",
    ],
    interests: ["Tiktok", "Cooking", "Football"],
    status: "Active",
    interestedUsers: [
      {
        id: 1,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
      {
        id: 2,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
      {
        id: 3,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
      {
        id: 4,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
    ],
  },
  {
    id: 2,
    name: "Bella Apartment",
    date: "15/April/2023",
    renterName: "Bella Edward",
    gender: "Female",
    address: "49 Street 2/A, Prince Road , Owl Boulevard LA",
    interested: true,
    img: images.rentAd2,
    additionalDetails:
      "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimenlorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Ext Ever Since THe 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimen Book. ",
    productImages: [
      images.rentAdThumbnail1,
      images.rentAdThumbnail2,
      images.rentAdThumbnail3,
    ],
    features: [
      "Kitchen",
      "Air Condition",
      "Washing Machine",
      "Street View",
      "Bathroom",
      "Air Condition",
    ],
    interests: ["Tiktok", "Cooking", "Football"],
    status: "Inactive",
    interestedUsers: [
      {
        id: 1,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
      {
        id: 2,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
      {
        id: 3,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
      {
        id: 4,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
    ],
  },
  {
    id: 3,
    name: "Bella Apartment",
    date: "15/April/2023",
    renterName: "Bella Edward",
    gender: "Female",
    address: "49 Street 2/A, Prince Road , Owl Boulevard LA",
    interested: true,
    img: images.rentAd3,
    additionalDetails:
      "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimenlorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Ext Ever Since THe 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimen Book. ",
    productImages: [
      images.rentAdThumbnail1,
      images.rentAdThumbnail2,
      images.rentAdThumbnail3,
    ],
    features: [
      "Kitchen",
      "Air Condition",
      "Washing Machine",
      "Street View",
      "Bathroom",
    ],
    interests: ["Tiktok", "Cooking", "Football"],
    status: "Active",
    interestedUsers: [
      {
        id: 1,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
      {
        id: 2,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
      {
        id: 3,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
      {
        id: 4,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
    ],
  },
  {
    id: 4,
    name: "Bella Apartment",
    date: "15/April/2023",
    renterName: "Bella Edward",
    gender: "Female",
    address: "49 Street 2/A, Prince Road , Owl Boulevard LA",
    interested: true,
    img: images.rentAd1,
    additionalDetails:
      "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimenlorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Ext Ever Since THe 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimen Book. ",
    productImages: [
      images.rentAdThumbnail1,
      images.rentAdThumbnail2,
      images.rentAdThumbnail3,
    ],
    features: [
      "Kitchen",
      "Air Condition",
      "Washing Machine",
      "Street View",
      "Bathroom",
      "Air Condition",
    ],
    interests: ["Tiktok", "Cooking", "Football"],
    status: "Active",
    interestedUsers: [
      {
        id: 1,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
      {
        id: 2,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
      {
        id: 3,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
      {
        id: 4,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
    ],
  },
  {
    id: 5,
    name: "Bella Apartment",
    date: "15/April/2023",
    renterName: "Bella Edward",
    gender: "Female",
    address: "49 Street 2/A, Prince Road , Owl Boulevard LA",
    interested: true,
    img: images.rentAd2,
    additionalDetails:
      "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimenlorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Ext Ever Since THe 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimen Book. ",
    productImages: [
      images.rentAdThumbnail1,
      images.rentAdThumbnail2,
      images.rentAdThumbnail3,
    ],
    features: [
      "Kitchen",
      "Air Condition",
      "Washing Machine",
      "Street View",
      "Bathroom",
      "Air Condition",
    ],
    interests: ["Tiktok", "Cooking", "Football"],
    status: "Active",
    interestedUsers: [
      {
        id: 1,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
      {
        id: 2,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
      {
        id: 3,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
      {
        id: 4,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
    ],
  },
  {
    id: 6,
    name: "Bella Apartment",
    date: "15/April/2023",
    renterName: "Bella Edward",
    gender: "Female",
    address: "49 Street 2/A, Prince Road , Owl Boulevard LA",
    interested: true,
    img: images.rentAd3,
    additionalDetails:
      "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimenlorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Ext Ever Since THe 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimen Book. ",
    productImages: [
      images.rentAdThumbnail1,
      images.rentAdThumbnail2,
      images.rentAdThumbnail3,
    ],
    features: [
      "Kitchen",
      "Air Condition",
      "Washing Machine",
      "Street View",
      "Bathroom",
      "Air Condition",
    ],
    interests: ["Tiktok", "Cooking", "Football"],
    status: "Active",
    interestedUsers: [
      {
        id: 1,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
      {
        id: 2,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
      {
        id: 3,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
      {
        id: 4,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
    ],
  },
  {
    id: 7,
    name: "Bella Apartment",
    date: "15/April/2023",
    renterName: "Bella Edward",
    gender: "Female",
    address: "49 Street 2/A, Prince Road , Owl Boulevard LA",
    interested: true,
    img: images.rentAd1,
    additionalDetails:
      "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimenlorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Ext Ever Since THe 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimen Book. ",
    productImages: [
      images.rentAdThumbnail1,
      images.rentAdThumbnail2,
      images.rentAdThumbnail3,
    ],
    features: [
      "Kitchen",
      "Air Condition",
      "Washing Machine",
      "Street View",
      "Bathroom",
      "Air Condition",
    ],
    interests: ["Tiktok", "Cooking", "Football"],
    status: "Active",
    interestedUsers: [
      {
        id: 1,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
      {
        id: 2,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
      {
        id: 3,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
      {
        id: 4,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
    ],
  },
  {
    id: 8,
    name: "Bella Apartment",
    date: "15/April/2023",
    renterName: "Bella Edward",
    gender: "Female",
    address: "49 Street 2/A, Prince Road , Owl Boulevard LA",
    interested: true,
    img: images.rentAd2,
    additionalDetails:
      "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimenlorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Ext Ever Since THe 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimen Book. ",
    productImages: [
      images.rentAdThumbnail1,
      images.rentAdThumbnail2,
      images.rentAdThumbnail3,
    ],
    features: [
      "Kitchen",
      "Air Condition",
      "Washing Machine",
      "Street View",
      "Bathroom",
      "Air Condition",
    ],
    interests: ["Tiktok", "Cooking", "Football"],
    status: "Active",
    interestedUsers: [
      {
        id: 1,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
      {
        id: 2,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
      {
        id: 3,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
      {
        id: 4,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
    ],
  },
  {
    id: 9,
    name: "Bella Apartment",
    date: "15/April/2023",
    renterName: "Bella Edward",
    gender: "Female",
    address: "49 Street 2/A, Prince Road , Owl Boulevard LA",
    interested: false,
    img: images.rentAd3,
    additionalDetails:
      "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimenlorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Ext Ever Since THe 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimen Book. ",
    productImages: [
      images.rentAdThumbnail1,
      images.rentAdThumbnail2,
      images.rentAdThumbnail3,
    ],
    features: [
      "Kitchen",
      "Air Condition",
      "Washing Machine",
      "Street View",
      "Bathroom",
      "Air Condition",
    ],
    interests: ["Tiktok", "Cooking", "Football"],
    status: "Active",
    interestedUsers: [
      {
        id: 1,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
      {
        id: 2,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
      {
        id: 3,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
      {
        id: 4,
        name: "Bella Edward",
        gender: "Female",
        email: "info@example.com",
        phone: "+40-123-456-790",
        bio: "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aenean Euismod Bibendum Laoreet.",
      },
    ],
  },
];

export const privacyPolicy = [
  {
    id: 1,
    policy:
      "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimenlorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Ext Ever Since THe 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimen Book. ",
    subPolicies: [
      {
        id: 1,
        subPolicy:
          "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimen Book. ",
      },
      {
        id: 2,
        subPolicy:
          "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry'sn Book. ",
      },
      {
        id: 3,
        subPolicy:
          "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry'sn Book. ",
      },
      {
        id: 4,
        subPolicy:
          "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry'sn Book. ",
      },
      {
        id: 5,
        subPolicy:
          "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry'sn Book. ",
      },
    ],
  },
  {
    id: 1,
    policy:
      "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimenlorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Ext Ever Since THe 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimen Book. ",
    subPolicies: [
      {
        id: 1,
        subPolicy:
          "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry'sn Book. ",
      },
      {
        id: 2,
        subPolicy:
          "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry'sn Book. ",
      },
    ],
  },
];

export const termsAndConditions = [
  {
    id: 1,
    term: "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimenlorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Ext Ever Since THe 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimen Book. ",
    subTerms: [
      {
        id: 1,
        subTerm:
          "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimen Book. ",
      },
      {
        id: 2,
        subTerm:
          "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry'sn Book. ",
      },
      {
        id: 3,
        subTerm:
          "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry'sn Book. ",
      },
      {
        id: 4,
        subTerm:
          "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry'sn Book. ",
      },
      {
        id: 5,
        subTerm:
          "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry'sn Book. ",
      },
    ],
  },
  {
    id: 2,
    term: "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimenlorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Ext Ever Since THe 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimen Book. ",
    subTerms: [
      {
        id: 1,
        subTerm:
          "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry'sn Book. ",
      },
      {
        id: 2,
        subTerm:
          "Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry'sn Book. ",
      },
    ],
  },
];

export const paymentLogs = [
  {
    id: 1,
    type: "Weekly",
    date: "01-01-2023",
    expiryDate: "01-07-2023",
    price: 5.99,
    status: "Active",
  },
  {
    id: 2,
    type: "Weekly",
    date: "01-01-2023",
    expiryDate: "01-07-2023",
    price: 5.99,
    status: "Inactive",
  },
  {
    id: 3,
    type: "Weekly",
    date: "01-01-2023",
    expiryDate: "01-07-2023",
    price: 5.99,
    status: "Inactive",
  },
  {
    id: 4,
    type: "Weekly",
    date: "01-01-2023",
    expiryDate: "01-07-2023",
    price: 5.99,
    status: "Inactive",
  },
];
