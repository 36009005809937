import React from 'react'

export const CustomSelect = (props) => {

  return (
    <select name={props?.name} id={props?.id} value={props?.value} onChange={props?.onChange} className={`site-select ${props.className?props.className:''}`}>
      {props.children}
    </select>
  )
}
