import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { faEyeSlash, faEye } from '@fortawesome/free-regular-svg-icons'
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { images } from '../../Assets';
import { addUser } from '../../redux/slice/authSlice.js';
import { BASE_URL } from '../../config/constants/index.js';
import { ADMIN } from '../../config/constants/admin.js';
import { 
    fPasswordValidate, 
    fPasswordValidate2, 
    fPasswordValidate3, 
    loginValidate 
} from '../../config/validations/index.js';
import { SiteModal } from '../../Components/SiteModal';
import { Checkbox } from '../../Components/Checkbox/index.js';
import SiteButton from "../../Components/Button/button.js"
import SiteInput from "../../Components/Input/input.js"
import Cookies from 'js-cookie';
import axios from 'axios';

export const AdminLogin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.userData);
    const token = useSelector((state) => state.user.userToken);

    const [load, setLoad] = useState(false);
    const [load2, setLoad2] = useState(false);
    const [load3, setLoad3] = useState(false);
    const [load4, setLoad4] = useState(false);
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [eyeIcon, seteyeIcon] = useState(faEyeSlash);
    const [eyeIcon2, seteyeIcon2] = useState(faEyeSlash);
    const [eyeIcon3, seteyeIcon3] = useState(faEyeSlash);
    const [passwordType, setPasswordType] = useState(0);
    const [passwordType2, setPasswordType2] = useState(0);
    const [passwordType3, setPasswordType3] = useState(0);
    const [rememberMe, setRememberMe] = useState(false);
    const [formData, setFormData] = useState({email: "", password: ""});
    const [formErrors, setFormErrors] = useState({});
    const [email, setEmail] = useState("");
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => {
        setShow(false)
        setShow2(true)
    };

    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => {
        setShow2(false)
        setShow3(true)
    };

    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => {
        setShow3(false)
        setShow4(true)
    };

    const toggleIcon = () => {
        if(eyeIcon == faEyeSlash){
            seteyeIcon(faEye)
            setPasswordType(1);
        }
        else{
            seteyeIcon(faEyeSlash);
            setPasswordType(0);
        }
    }

    const toggleIcon2 = () => {
        if(eyeIcon2 == faEyeSlash){
            seteyeIcon2(faEye)
            setPasswordType2(1);
        }
        else{
            seteyeIcon2(faEyeSlash);
            setPasswordType2(0);
        }
    }

    const toggleIcon3 = () => {
        if(eyeIcon3 == faEyeSlash){
            seteyeIcon3(faEye)
            setPasswordType3(1);
        }
        else{
            seteyeIcon3(faEyeSlash);
            setPasswordType3(0);
        }
    }

    useEffect(() => {
        let value1 = Cookies.get('em');
        let value2 = Cookies.get('pd');
        if (value1 && value2) {
            setFormData({
                email: value1,
                password: value2
            });
            setRememberMe(true);
        }
    }, []);

    const loginFormik = useFormik({
        enableReinitialize: true,
        initialValues: formData,
        validationSchema: loginValidate,
        onSubmit: (values) => handleFormSubmit(values)
    });

    const handleFormSubmit = async (data) => {
        setLoad(true);

        if (rememberMe) {
            Cookies.set('em', data.email, {expires: 999});
            Cookies.set('pd', data.password, {expires: 999});
        } else {
            Cookies.remove('em');
            Cookies.remove('pd');
        }
        
        let login = await axios.post(BASE_URL + ADMIN.auth.login, data)
        .then(response => {
            if(response.data?.status){
                dispatch(
                    addUser({
                        user: response.data.data.user,
                        token: response.data.data.access_token
                    })
                );

                setTimeout(() => {
                    setLoad(false);
                    navigate('/admin/dashboard');
                }, 1000);
            }
        })
        .catch(error => {
            document.getElementById("response").innerHTML = 
            `<div class='text-danger p-2 border border-danger rounded mt-3'>${error.response.data.message}</div>`;
            setLoad(false);
        });
    }

    // FORGET PASSWORD METHODS
    const passwordFormik = useFormik({
        initialValues: {email: ""},
        validationSchema: fPasswordValidate,
        onSubmit: (values) => handleForgetPassword(values)
    });

    const handleForgetPassword = async (data) => {
        setLoad2(true);

        let response = await axios.post(BASE_URL + ADMIN.auth.forget_password, data)
        .then(() => {
            setLoad2(false);
            setEmail(data.email);
            setFormErrors({});
            handleShow2();
        })
        .catch(err => {
            setFormErrors({...formErrors, email: err.response.data.message});
            setLoad2(false);
        });
    }

    const passwordFormik2 = useFormik({
        initialValues: {code: ""},
        validationSchema: fPasswordValidate2,
        onSubmit: (values) => handleVerifyCode(values)
    });

    const handleVerifyCode = async (data) => {
        setLoad3(true);

        let response = await axios.post(BASE_URL + ADMIN.auth.verify_code, {email, code: data.code})
        .then(() => {
            setLoad3(false);
            setFormErrors({});
            handleShow3();
        })
        .catch(err => {
            setFormErrors({...formErrors, code: err.response.data.message});
            setLoad3(false);
        });
    }

    const passwordFormik3 = useFormik({
        initialValues: {password: "", confirm_password: ""},
        validationSchema: fPasswordValidate3,
        onSubmit: (values) => handleResetPassword(values)
    });

    const handleResetPassword = async (data) => {
        setLoad4(true);

        let response = await axios.post(BASE_URL + ADMIN.auth.reset_password, {email, password: data.password})
        .then(() => {
            setLoad4(false);
            setFormErrors({});
            setEmail("");
            handleShow4();
        })
        .catch(err => {
            setFormErrors({...formErrors, confirm_password: err.response.data.message});
            setLoad4(false);
        })
    }

    return (
        <>
            <section className="login-bg">
                <div className='container-fluid'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 d-lg-block d-none px-0'>
                            <div className='login-left'>
                                <img src={images.loginLeft} alt="" className="img-fluid login-left-img" />
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className="login-right">
                                <div className="login-content">
                                    <img src={images.logo} alt="" className="img-fluid login-logo mb-3" />
                                    <div className='mt-4'>
                                        <h1>Login</h1>
                                        <p className="mb-0 semi-bold">Login to your Roomies & Rental account.</p>
                                    </div>
                                    <div id='response'></div>
                                    <form onSubmit={loginFormik.handleSubmit} className="mt-3">
                                        <div>
                                            <label className="site-label l-grey-text mb-1">
                                                Email Address<span className="red-text">*</span>
                                            </label>
                                            <SiteInput 
                                                type="email"
                                                id="email"
                                                placeholder="Enter Your Email" 
                                                value={loginFormik.values.email}
                                                onChange={loginFormik.handleChange}
                                                onBlur={loginFormik.handleBlur}
                                            />
                                            {loginFormik.errors.email && loginFormik.touched.email && (
                                                <small className='text-danger ms-1'>{loginFormik.errors.email}</small>
                                            )}
                                        </div>

                                        <div>
                                            <label className="site-label l-grey-text mt-4 mb-1">
                                                Password<span className="red-text">*</span>
                                            </label>
                                            <div className="position-relative">
                                                <SiteInput 
                                                    type={passwordType == 0 ? "password" : "text"}
                                                    id="password"
                                                    placeholder="Enter Password" 
                                                    value={loginFormik.values.password}
                                                    onChange={loginFormik.handleChange}
                                                    onBlur={loginFormik.handleBlur}
                                                />
                                                <FontAwesomeIcon className='right-icon' onClick={toggleIcon} icon={eyeIcon} />
                                            </div>
                                            {loginFormik.errors.password && loginFormik.touched.password && (
                                                <small className='text-danger ms-1'>{loginFormik.errors.password}</small>
                                            )}
                                        </div>
                                        
                                        <div className="d-flex align-items-center justify-content-between">
                                            <Checkbox 
                                                id="remember" 
                                                name="login" 
                                                for="remember" 
                                                labelText="Remember Me"
                                                checked={rememberMe}
                                                onChange={(e) => setRememberMe(e.target.checked)}
                                            />
                                            <a onClick={handleShow} className="cyan-text mt-3 mb-3">Forgot Password?</a>
                                        </div>
                                        <SiteButton buttonText="Login" type="submit" load={load} />
                                    </form>
                                </div>
                            </div>
                            <img src={images.loginTop} alt="" className="img-fluid login-top" />
                            <img src={images.loginBottom} alt="" className="img-fluid login-bottom" />
                        </div>
                    </div>
                </div>
            </section>

            <SiteModal
                show={show} 
                handleClose={handleClose}
                largeModal
                modalTitle="Forgot your password?"
                modalText="Enter your email address below and we’ll send you a link to reset your password"
            >
                <form onSubmit={passwordFormik.handleSubmit} className="mt-4">
                    <label className="site-label grey-text mb-1">Email Address<span className="red-text">*</span></label>
                    <SiteInput 
                        type="email"
                        id="email"
                        placeholder="Enter Your Email" 
                        className="grey-input" 
                        value={passwordFormik.values.email}
                        onChange={(e) => {
                            passwordFormik.handleChange(e);
                            setFormErrors({...formErrors, email: ""});
                        }}
                        onBlur={passwordFormik.handleBlur}
                    />
                    {passwordFormik.errors.email && passwordFormik.touched.email ? (
                        <small className='text-danger ms-1'>{passwordFormik.errors.email}</small>
                    ): (
                        <small className='text-danger ms-1'>{formErrors.email}</small>
                    )}
                    <div className="d-flex mt-3 align-items-center justify-content-between">
                        <a onClick={handleClose} className="back-link mt-3">
                            <FontAwesomeIcon className='cyan-text' icon={faArrowLeftLong} />
                            Back To Login
                        </a>
                        <SiteButton type="submit" buttonText="Continue" className="mt-3" load={load2} />
                    </div>
                </form>
            </SiteModal>

            <SiteModal
                show={show2} 
                handleClose={handleClose2}
                largeModal
                modalTitle="Verification"
                modalText="Enter the verification code sent to your email"
            >
                <form onSubmit={passwordFormik2.handleSubmit} className="mt-4">
                    <label className="site-label grey-text mb-1">Code<span className="red-text">*</span></label>
                    <SiteInput 
                        type="number"
                        id="code"
                        placeholder="Enter Verification Code" 
                        className="grey-input" 
                        value={passwordFormik2.values.code}
                        onChange={passwordFormik2.handleChange}
                        onBlur={passwordFormik2.handleBlur}
                    />
                    {passwordFormik2.errors.code && passwordFormik2.touched.code ? (
                        <small className='text-danger ms-1'>{passwordFormik2.errors.code}</small>
                    ): (
                        <small className='text-danger ms-1'>{formErrors.code}</small>
                    )}
                    <div className='d-flex justify-content-end'>
                        <button 
                            onClick={() => handleForgetPassword({ email })} 
                            className="back-link mt-2 bg-transparent border-0"
                            disabled={load2}
                        >
                            Resend Code
                        </button>
                    </div>
                    <div className="text-center">
                        <SiteButton type="submit" buttonText="Continue" className="mt-3" load={load3} />
                    </div>
                </form>
            </SiteModal>

            <SiteModal
                show={show3} 
                handleClose={handleClose3}
                largeModal
                modalTitle="Recover Password"
                modalText="Please Update your new password"
            >
                <form onSubmit={passwordFormik3.handleSubmit} className="mt-4">
                    <div>
                        <label className="site-label grey-text mb-1">New Password<span className="red-text">*</span></label>
                        <div className="position-relative">
                            <SiteInput 
                                type={passwordType2 == 0 ? "password" : "text"}
                                id="password"
                                placeholder="Enter New Password" 
                                className="grey-input" 
                                value={passwordFormik3.values.password}
                                onChange={passwordFormik3.handleChange}
                                onBlur={passwordFormik3.handleBlur}
                            />
                            <FontAwesomeIcon className='right-icon' onClick={toggleIcon2} icon={eyeIcon2} />
                        </div>
                        {passwordFormik3.errors.password && passwordFormik3.touched.password && (
                            <small className='text-danger ms-1'>{passwordFormik3.errors.password}</small>
                        )}
                    </div>
                    <div>
                        <label className="site-label mt-4 grey-text mb-1">Confirm Password<span className="red-text">*</span></label>
                        <div className="position-relative">
                            <SiteInput 
                                type={passwordType3 == 0 ? "password" : "text"}
                                id="confirm_password"
                                placeholder="Confirm Password" 
                                className="grey-input" 
                                value={passwordFormik3.values.confirm_password}
                                onChange={passwordFormik3.handleChange}
                                onBlur={passwordFormik3.handleBlur}
                            />
                            <FontAwesomeIcon className='right-icon' onClick={toggleIcon3} icon={eyeIcon3} />
                        </div>
                        {passwordFormik3.errors.confirm_password && passwordFormik3.touched.confirm_password ? (
                            <small className='text-danger ms-1'>{passwordFormik3.errors.confirm_password}</small>
                        ): (
                            <div className='text-danger ms-1'>{formErrors.confirm_password}</div>
                        )}
                    </div>
                    <div className="text-center mt-3">
                        <SiteButton type="submit" buttonText="Update" className="mt-3" load={load4} />
                    </div>
                </form>
            </SiteModal>

            <SiteModal 
                show={show4} 
                handleClose={handleClose4} 
                lottieIcon={images.checkIcon} 
                modalTitle="Sucessfully" 
                modalText="Password Recovered Successfully!" 
                normalModal 
            />
        </>
    )
}
