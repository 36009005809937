import React from 'react'

export const TestimonialCard = (props) => {
  return (
    <div className="testimonial-card">
        <div className="d-sm-flex gap-4">
            <div className="flex-shrink-0">
                <img src={props.item.img} alt="" className="img-fluid reviewer-img" />
            </div>
            <div className="flex-grow-1 mt-sm-0 mt-3">
                <p className="l-blue-text semi-bold">"{props.item.review}"</p>
                <p className="cyan-text mb-0 semi-bold">{props.item.name}</p>
            </div>
        </div>
    </div>
  )
}
