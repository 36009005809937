import React from 'react'

const SiteInput = (props) => {
  return (
    <>
      <input type={props.type} min={props?.min} max={props?.max} id={props?.id} name={props?.name} readOnly={props?.readOnly} placeholder={props.placeholder} value={props?.value} onChange={props?.onChange} onBlur={props?.onBlur} className={`site-input ${props.className?props.className:''}`} autoComplete={props?.autoComplete} />
    </>
  )
}
export default SiteInput;
