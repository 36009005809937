import React, { useEffect, useState } from "react";
import { RentFilters } from "../../../../Components/UserComponents/RentFilters";
import { allRentPlaces } from "../../../../Data";
import { RentPlacesCardListing } from "../../../../Components/UserComponents/RentPlacesCard/listing";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useSelector } from "react-redux";
import { Get } from "../../../../config/api/get";
import { RENTER } from "../../../../config/constants";
import Loader from "../../../../Components/Loader";
import TablePagination from "../../../../Components/TableFooter/pagination";
import { TableFooter } from "../../../../Components/TableFooter";

export const InterestedUsersPlaces = (props) => {
  const [loading, setLoading] = React.useState(true); 
  const [ads, setAds] = React.useState([]);

  const MySwal = withReactContent(Swal);
  const [genderFilter, setGenderFilter] = useState("");
  const [interestFilter, setInterestFilter] = useState("");
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const token = useSelector((state) => state.user.userToken);
  const getInterestedAds = async () => {
    setLoading(true);
    let url = `${RENTER.getInterentedAds}?page=${currentPage}`;
    if (search) url += `&search=${search}`;
    if (genderFilter) url += `&gender=${genderFilter}`;
    console.log(url);
    Get(url, token)
      .then((response) => {
        if (response?.status) {
          console.log(response.data);
          let total_records = response.data.meta.total;

          console.log(total_records);
          let records_per_page = response.data.meta.per_page;
          let total_pages = Math.ceil(total_records / records_per_page);
          setAds(response.data.data);
          setCurrentPage(response.data.meta.current_page);
          setTotalRecords(total_records);
          setTotalPages(total_pages);
          setLoading(false);
        }
        if (response?.response?.status === 422) {
          setLoading(false);
          MySwal.fire({
            title: "Error!",
            text: response.response.data.message,
            icon: "error",
            confirmButtonColor: "#19D8C5",
            confirmButtonText: "Cancel",
          });
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        MySwal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
          confirmButtonColor: "#19D8C5",
          confirmButtonText: "Cancel",
        });
        setLoading(false);
      });
  };
  const handleSearchFilter = (data) => {
    setSearch(data);
  };
  const handleGenderFilter = (data) => {
    setGenderFilter(data);
  };
  const handleInterestFilter = (data) => {
    setInterestFilter(data);
  };
  const handleClearFilter = (data) => {
    setSearch("");
    setGenderFilter("");
    setInterestFilter("");
  };
  const handleApplyFilter = () => {
    getInterestedAds();
  };
  useEffect(() => {
    getInterestedAds();
  }, [currentPage]);
  return (
    <section className="all-rent-places">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <RentFilters
              onSearchFilter={handleSearchFilter}
              onGenderFilter={handleGenderFilter}
              onInterestFilter={handleInterestFilter}
              onClearFilter={handleClearFilter}
              onApplyFilter={handleApplyFilter}
            />
          </div>
        </div>
        <div className="row">
          {loading ? (
            <Loader />
          ) : (
            ads.map((ele) =>
              true ? (
                <div className="col-xl-4 col-md-6 my-3" key={ele.id}>
                  <RentPlacesCardListing interestedUsers item={ele} />
                </div>
              ) : (
                ""
              )
            )
          )}
        </div>
        <TableFooter
          showingItem={ads.length}
          totalItems={totalRecords}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      </div>
    </section>
  );
};
