import React from "react";
import { UserLayout } from "../../../Components/UserComponents/UserLayout/userLayout";
import SiteButton from "../../../Components/Button/button";
import SiteInput from "../../../Components/Input/input";
import { useNavigate, useParams } from "react-router";
import { useState } from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { subscriptionPackages } from "../../../Data";
import CustomRadio from "../../../Components/CustomRadio";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { CustomSelect } from "../../../Components/Select";
import SiteTextarea from "../../../Components/Input/textarea";
import { images } from "../../../Assets";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Post } from "../../../config/api/post";
import { AUTH, SIGNUP } from "../../../config/constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { SiteModal } from "../../../Components/SiteModal";

export const RegisterUpdateInfo = ({ ele }) => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [bio, setBio] = React.useState("");
  const MySwal = withReactContent(Swal);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    setLoading(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setLoading(false);
      return false;
    }
    if (!isValid) {
      setLoading(false);
      return false;
    }
    // const phoneRegex = /^\d{11}$/;
    // if (!phoneRegex.test(phoneNumber)) {
    //   // setIsValid(true);
    //   setLoading(false);

    //   return false;
    // }
    // setIsValid(false);
    
      let data = {
        id: id,
        phone: phoneNumber,
        gender: gender,
        bio: bio,
      };
      Post(SIGNUP.completeUserSignUp, data)
        .then((response) => {
          // console.log(response.data);
          setLoading(false);
          if (response?.data?.status) {
            MySwal.fire({
              title: "Success!",
              text: response?.data?.message,
              icon: "success",
              confirmButtonColor: "#19D8C5",
              confirmButtonText: "Done",
            });
            setTimeout(() => {
              navigate("/user/login");
            }, 1000);

            console.log("response", response?.data?.message);
          } else {
            MySwal.fire({
              title: "Error!",
              text: response?.data?.message,
              icon: "error",
              confirmButtonColor: "#19D8C5",
              confirmButtonText: "Cancel",
            });
          }
          if (response?.response?.status === 422) {
            setLoading(false);
            MySwal.fire({
              title: "Error!",
              text: response.response.data.message,
              icon: "error",
              confirmButtonColor: "#19D8C5",
              confirmButtonText: "Cancel",
            });
            setTimeout(() => {
              navigate("/user/register/buy-subscription/" + id, {
                replace: true,
              });
            }, 1000);
          }
        })
        .catch((e) => {
          console.log(":::;", e);
          MySwal.fire({
            title: "Error!",
            text: "Something went wrong",
            icon: "error",
            confirmButtonColor: "#19D8C5",
            confirmButtonText: "Cancel",
          });
          setLoading(false);
        });
    
  };

  return (
    <UserLayout noFooter>
      <section className="registration">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="auth-card">
                <div className="text-center">
                  <h3 className="cyan-text bold">Sign Up</h3>
                  <p className="mb-0">Register Your Account</p>
                  <div
                    className="registration-step-form my-3"
                    id="registration-steps"
                  >
                    <span>Sign Up</span>
                    <span>Buy Subscription</span>
                    <span className="active">Create Account</span>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-xxl-9 col-lg-10">
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-md-6 my-2">
                          <div className="position-relative">
                            <Form.Group as={Col} controlId="validationCustom01">
                              <Form.Label className="ms-3 bold">
                                Contact Number
                                <span className="red-text">*</span>
                              </Form.Label>
                              <IntlTelInput
                                preferredCountries={['us']}
                                inputClassName="with-shadow grey-input form-control"
                                onPhoneNumberChange={(isValid, value, selectedCountryData, fullNumber) => {
                                  setPhoneNumber("+" + selectedCountryData.dialCode + value);
                                  setIsValid(isValid);
                                }}
                              />
                              {!isValid ? (
                                <span className="red-text">
                                  Please Enter valid contact number
                                </span>
                              ) : (
                                ""
                              )}
                              <Form.Control.Feedback type="invalid">
                                Please enter contact number.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </div>
                        <div className="col-md-6 my-2">
                          <Form.Group as={Col} controlId="validationCustom01">
                            <Form.Label className="ms-3 bold">
                              Gender<span className="red-text">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              className="site-select grey-input with-shadow"
                              as="select"
                              type="select"
                              name="payment_method"
                              onChange={(e) => setGender(e.target.value)}
                              value={gender}
                            >
                              <option value="" selected disabled>
                                Select Gender
                              </option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please enter first name.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-12 my-2">
                          {/* <label htmlFor="" className="bold ms-3">
                            Bio*
                          </label>
                          <SiteTextarea
                            placeholder="Type Here..."
                            className="grey-input with-shadow"
                            rows="5"
                          /> */}
                          <Form.Group as={Col} controlId="validationCustom02">
                            <Form.Label className="semi-bold mb-2 ms-3">
                              Bio*
                            </Form.Label>
                            <Form.Control
                              required
                              as="textarea"
                              rows={5}
                              className="site-input grey-input with-shadow"
                              placeholder="Enter Bio "
                              value={bio}
                              onChange={(e) => setBio(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter Bio.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-12 mt-4 text-center">
                          <button
                            type="submit"
                            className="site-btn"
                            disabled={loading ? true : false}
                          >
                            {loading ? "Loading..." : "Save Information"}
                          </button>
                          {/* <SiteButton buttonText="Save Information" /> */}
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </UserLayout>
  );
};
