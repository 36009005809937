import React from "react";
import { UserLayout } from "../../../Components/UserComponents/UserLayout/userLayout";
import { InnerBanner } from "../../../Components/UserComponents/InnerBanner";
import { InterestedAdPlaces } from "./InterestedAdPlaces";

export const InterestedAds = () => {
  return (
    <UserLayout>
      <InnerBanner
        backButton="false"
        bannerHeading="Interested"
        coloredHeading="Ads"
      />
      <InterestedAdPlaces />
    </UserLayout>
  );
};
