import React from "react";
import { AdminLogin } from "../Screens/Auth/login";
import { Dashboard } from "../Screens/Dashboard";
import UserListing from "../Screens/User/index";
import Notifications from "../Screens/Notifications";
import { UserDetails } from "../Screens/User/userDetails.js";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes,
  HashRouter,
  RouterProvider,
  BrowserRouter,
} from "react-router-dom";
import { ErrorPage } from "../Screens/Error";
import { AdminProfile } from "../Screens/Profile";
import { EditProfile } from "../Screens/Profile/editProfile";
import { ChangePassword } from "../Screens/Profile/changePassword";
import RenterListing from "../Screens/Renter";
import { RenterDetails } from "../Screens/Renter/renterDetails";
import InterestListing from "../Screens/Interest";
import SubscriptionListing from "../Screens/Subscription";
import { EditSubscription } from "../Screens/Subscription/editSubscription";
import { AddSubscription } from "../Screens/Subscription/addSubscription";
import CreditFeesListing from "../Screens/CreditFees";
import AdsListing from "../Screens/Ads";
import PaymentListing from "../Screens/Payment";
import { AdDetails } from "../Screens/Ads/adDetails";
import FeedbackListing from "../Screens/Feedback";
import { FeedbackDetails } from "../Screens/Feedback/feedbackDetails";
import { Home } from "../Screens/UserScreens/Home";
import { Register } from "../Screens/UserScreens/UserAuth/register";
import { RegisterUpdateInfo } from "../Screens/UserScreens/UserAuth/registerUpdateInfo";
import { RegisterBuySubscription } from "../Screens/UserScreens/UserAuth/registerBuySubscription";
import { UserLogin } from "../Screens/UserScreens/UserAuth";
import { ForgetPassword } from "../Screens/UserScreens/UserAuth/forgetPassword";
import { VerifcationCode } from "../Screens/UserScreens/UserAuth/verificationCode.js";
import { ChangeForgetPassword } from "../Screens/UserScreens/UserAuth/changeForgetPassword.js";
import { useEffect } from "react";
import { useState } from "react";
import { RentPlaces } from "../Screens/UserScreens/RentPlaces";
import { RentPlaceDetails } from "../Screens/UserScreens/RentPlaceDetails";
import { ViewAdd } from "../Screens/RenterScreens/AdListing/viewAdd";
import { InterestedAds } from "../Screens/UserScreens/InterestedAds";
import { AboutUs } from "../Screens/UserScreens/AboutUs";
import { UserProfile } from "../Screens/UserScreens/UserProfile";
import { EditUserProfile } from "../Screens/UserScreens/UserProfile/editProfile";
import { PrivacyPolicy } from "../Screens/UserScreens/PrivacyPolicy";
import { TermsAndConditions } from "../Screens/UserScreens/TermsAndConditions";
import PaymentLogs from "../Screens/UserScreens/PaymentLogs";
import { MakePayment } from "../Screens/UserScreens/MakePayment";
import { ContactAdmin } from "../Screens/UserScreens/ContactAdmin";
import { Error404 } from "../Screens/UserScreens/Error";
import { RenterRegister } from "../Screens/RenterScreens/RenterRegister";
import { RenterLogin } from "../Screens/RenterScreens/RenterAuth";
import { EditRentPlace } from "../Screens/UserScreens/RentPlaceDetails/edit-rent-place";
import { BecomeARenter } from "../Screens/UserScreens/BecomeARenter";
import { AddRentPlace } from "../Screens/UserScreens/RentPlaceDetails/add-rent-place";
import { BuyCredits } from "../Screens/RenterScreens/BuyCredits";
import { InterestedUsers } from "../Screens/RenterScreens/InterestedUsers";
import { AdListing } from "../Screens/RenterScreens/AdListing";
import { InterestedUserDetails } from "../Screens/RenterScreens/InterestedUsers/interested-user-details";
import { RevealUserDetails } from "../Screens/RenterScreens/InterestedUsers/reveal-user-details";

import AuthCheck from "../Components/AuthCheck/Authcheck.js";
import RenterAuthCheck from "../Components/AuthCheck/RenterAuthCheck.js";
import GuestRoute from "./admin/GuestRoute.js";
import ProtectedRoute from "./admin/ProtectedRoute.js";

import UserAuthCheck from "../Components/AuthCheck/UserAuthCheck.js";
import { Faqs } from "../Screens/UserScreens/Faqs/index.js";

export default function AdminRouter() {
  const [isAuth, setIsAuth] = useState(false);
  useEffect(() => {
    setIsAuth(isAuthenticated());
  }, []);

  const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    return token;
  };

  const loginPath = "/login";
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/user/register" element={<Register />} />
        <Route
          path="/user/register/updateInfo/:id"
          element={<RegisterUpdateInfo />}
        />
        <Route
          path="/user/register/buy-subscription/:id"
          element={<RegisterBuySubscription />}
        />
        <Route path="/user/login" element={<UserLogin />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/verification-code" element={<VerifcationCode />} />
        <Route
          path="/change-forget-password"
          element={<ChangeForgetPassword />}
        />
        <Route path="/rent-places" element={<RentPlaces />} />
        <Route path="/rent-place-details/:id" element={<RentPlaceDetails />} />
        <Route
          path="/view-add/:id"
          element={
            <RenterAuthCheck>
              <ViewAdd />
            </RenterAuthCheck>
          }
        />
        <Route
          path="/edit-rent-place/:id"
          element={
            <RenterAuthCheck>
              <EditRentPlace />
            </RenterAuthCheck>
          }
        />
        <Route
          path="/renter/add-rent-place"
          element={
            <RenterAuthCheck>
              <AddRentPlace />
            </RenterAuthCheck>
          }
        />
        {/* <Route path="/renter/add-rent-place" element={<AddRentPlace />} /> */}
        <Route
          path="/interested-ads"
          element={
            <UserAuthCheck>
              <InterestedAds />
            </UserAuthCheck>
          }
        />
        <Route
          path="/profile"
          element={
            <AuthCheck>
              <UserProfile />
            </AuthCheck>
          }
        />
        <Route
          path="/edit-profile"
          element={
            <AuthCheck>
              <EditUserProfile />
            </AuthCheck>
          }
        />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/payment-logs" element={<PaymentLogs />} />
        <Route path="/make-payment" element={<MakePayment />} />
        <Route path="/become-a-renter" element={<BecomeARenter />} />
        <Route path="/contact-admin" element={<ContactAdmin />} />
        <Route path="/renter/login" element={<RenterLogin />} />
        <Route path="/renter/register" element={<RenterRegister />} />
        <Route
          path="/renter/buy-credits"
          element={
            <RenterAuthCheck>
              <BuyCredits />
            </RenterAuthCheck>
          }
        />
        <Route
          path="/renter/interested-users"
          element={
            <RenterAuthCheck>
              <InterestedUsers />
            </RenterAuthCheck>
          }
        />
        <Route
          path="/renter/add-listing"
          element={
            <RenterAuthCheck>
              <AdListing />
            </RenterAuthCheck>
          }
        />
        <Route
          path="/renter/interested-user-details/:id"
          element={<InterestedUserDetails />}
        />
        <Route
          path="/renter/reveal-user-details/:id/:userId"
          element={<RevealUserDetails />}
        />
        <Route element={<GuestRoute />}>
          <Route path="/admin/" element={<AdminLogin />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/user" element={<UserListing />} />
          <Route path="/admin/user-details/:id" element={<UserDetails />} />
          <Route path="/admin/renter" element={<RenterListing />} />
          <Route path="/admin/renter-details/:id" element={<RenterDetails />} />
          <Route path="/admin/interest" element={<InterestListing />} />
          <Route path="/admin/subscription" element={<SubscriptionListing />} />
          <Route
            path="/admin/edit-subscription-package/:id"
            element={<EditSubscription />}
          />
          <Route
            path="/admin/create-subscription-package"
            element={<AddSubscription />}
          />
          <Route path="/admin/credit-fees" element={<CreditFeesListing />} />
          <Route path="/admin/ads" element={<AdsListing />} />
          <Route path="/admin/ads-details/:id" element={<AdDetails />} />
          <Route path="/admin/payment" element={<PaymentListing />} />
          <Route path="/admin/feedback" element={<FeedbackListing />} />
          <Route
            path="/admin/feedback-details/:id"
            element={<FeedbackDetails />}
          />
          <Route path="/admin/profile" element={<AdminProfile />} />
          <Route path="/admin/edit-profile" element={<EditProfile />} />
          <Route path="/admin/change-password" element={<ChangePassword />} />
          <Route path="*" element={<Error404 />} />
        </Route>
        <Route path="/admin/" element={<AdminLogin />} />
        <Route path="/admin/dashboard" element={<Dashboard />} />
        <Route path="/admin/user" element={<UserListing />} />
        <Route path="/admin/user-details/:id" element={<UserDetails />} />
        <Route path="/admin/renter" element={<RenterListing />} />
        <Route path="/admin/renter-details/:id" element={<RenterDetails />} />
        <Route path="/admin/interest" element={<InterestListing />} />
        <Route path="/admin/subscription" element={<SubscriptionListing />} />
        <Route
          path="/admin/edit-subscription-package/:id"
          element={<EditSubscription />}
        />
        <Route
          path="/admin/create-subscription-package"
          element={<AddSubscription />}
        />
        <Route path="/admin/credit-fees" element={<CreditFeesListing />} />
        <Route path="/admin/ads" element={<AdsListing />} />
        <Route path="/admin/ads-details/:id" element={<AdDetails />} />
        <Route path="/admin/payment" element={<PaymentListing />} />
        <Route path="/admin/feedback" element={<FeedbackListing />} />
        <Route
          path="/admin/feedback-details/:id"
          element={<FeedbackDetails />}
        />
        <Route path="/admin/profile" element={<AdminProfile />} />
        <Route path="/admin/edit-profile" element={<EditProfile />} />
        <Route path="/admin/change-password" element={<ChangePassword />} />
        <Route path="*" element={<Error404 />} /> 19760ac (QA issues resolved)
      </Routes>
    </BrowserRouter>
  );
}
