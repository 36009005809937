import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faToggleOff,
  faToggleOn,
} from "@fortawesome/free-solid-svg-icons";
import { images } from "../../Assets";
import { BASE_URL } from "../../config/constants";
import { ADMIN } from "../../config/constants/admin";
import { interestValidate } from "../../config/validations";
import { TableFooter } from "../../Components/TableFooter";
import { CustomTable } from "../../Components/CustomTable";
import { CustomSelect } from "../../Components/Select";
import { SiteModal } from "../../Components/SiteModal";
import { Layout } from "../../Components/Layout/layout";
import SiteButton from "../../Components/Button/button";
import SiteInput from "../../Components/Input/input";
import Loader from "../../Components/Loader";
import axios from "axios";

export const interestTitles = ["S.No", "Interests", "Status", "Action"];

const InterestListing = () => {
  const token = useSelector(state => state.user.userToken);
  const [id, setId] = useState("");
  const [load, setLoad] = useState(true);
  const [load2, setLoad2] = useState(false);
  const [interests, setInterests] = useState([]);
  const [formError, setFormError] = useState("");
  const [status, setStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);

  const loadInterest = async () => {
    setLoad(true)
    let url = `${BASE_URL + ADMIN.interest.get}?page=${currentPage}`;
    if(status) url += `&status=${status}`;

    let data = await axios.get(url, {
      headers: {Authorization: `Bearer ${token}`}
    })
    .then(response => {
      let total_records    = response.data.data.meta.total;
      let records_per_page = response.data.data.meta.per_page;
      let total_pages      = Math.ceil(total_records / records_per_page);

      setInterests(response.data.data.data)
      setCurrentPage(response.data.data.meta.current_page);
      setTotalRecords(total_records);
      setTotalPages(total_pages);
      setLoad(false);
    })
    .catch(err => {
      setLoad(false)
      console.error(err.response.data.message);
    })
  }

  useEffect(() => {
    loadInterest();
  }, [status, currentPage])

  const activateInterest = async () => {
    let data = await axios.post(BASE_URL + ADMIN.interest.update_status + `/${id}`, {}, {
      headers: {Authorization: `Bearer ${token}`}
    })
    .then(() => {
      setShow3(false);
      setShow4(true);
      loadInterest();
    })
    .catch(err => {
      console.error(err.response.data);
    })
  }

  const inactivateInterest = async () => {
    let data = await axios.post(BASE_URL + ADMIN.interest.update_status + `/${id}`, {}, {
      headers: {Authorization: `Bearer ${token}`}
    })
    .then(() => {
      setShow(false);
      setShow2(true);
      loadInterest();
    })
    .catch(err => {
      console.error(err.response.data);
    })
  }

  const {values, errors, touched, handleChange, handleBlur, handleSubmit, handleReset} = useFormik({
    initialValues: {interest_name: ""},
    validationSchema: interestValidate,
    onSubmit: (values) => handleFormSubmit(values)
  });

  const handleFormSubmit = async (data) => {
    setLoad2(true)

    let response = await axios.post(
      BASE_URL + ADMIN.interest.add,
      data,
      {headers: {Authorization: `Bearer ${token}`}}
    )
    .then(() => {
      setTimeout(() => {
        loadInterest();
        setLoad2(false);
        setShow5(false);
        handleReset();
      }, 750);
    })
    .catch(err => {
      setLoad2(false);
      setFormError(err.response.data.message);
      console.error(err.response);
    });
  }

  return (
    <>
      <Layout>
        <h3 className="blue-text mb-0">Interest Management</h3>
        <div className="row">
          <div className="col-xxl-6 col-xl-8 col-md-10">
            <div className="d-sm-flex mb-4 mt-3 align-items-center justify-content-between">
              <div className="d-flex mt-3 align-items-center">
                <label htmlFor="status" className="me-3">Filter By Status</label>
                <CustomSelect id="status" value={status} onChange={e => setStatus(e.target.value)}>
                  <option value="">All</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </CustomSelect>
              </div>
              <SiteButton className="mt-3" buttonText="Add New Interest" onClick={() => setShow5(true)} />
            </div>
            <CustomTable
              // className="interest-table"
              tableTitles={interestTitles}
            >
              {load ? (
                <tr>
                  <td colSpan="100%"><Loader sm /></td>
                </tr>
              ) : (
                interests.length > 0 ? (
                  interests.map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>
                        <span className={`table-tag ${item.status === 1 ? "green-tag" : 'red-tag'}`}>
                          <FontAwesomeIcon icon={faCircle}/>{item.status === 1 ? "Active" : "Inactive"}
                        </span>
                      </td>
                      <td>
                        {item.status === 1 ? (
                          <p className="mb-0 d-flex align-items-center blue-text medium">
                            <FontAwesomeIcon
                              onClick={() => {
                                setShow(true)
                                setId(item.id)
                              }}
                              className="green-text cursor-pointer ms-2 p-md"
                              icon={faToggleOn}
                            />
                          </p>
                        ) : (
                          <p className="mb-0 d-flex align-items-center blue-text medium">
                            <FontAwesomeIcon
                              onClick={() => {
                                setShow3(true)
                                setId(item.id)
                              }}
                              className="red-text cursor-pointer ms-2 p-md"
                              icon={faToggleOff}
                            />
                          </p>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="100%" className='text-center text-muted'>No record found!</td>
                  </tr>
                )
              )}
            </CustomTable>
            <TableFooter 
              showingItem={interests.length} 
              totalItems={totalRecords} 
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          </div>
        </div>
      </Layout>

      <SiteModal
        show={show}
        modalType="true"
        actionMethod={inactivateInterest}
        handleClose={() => setShow(false)}
        modalImg={images.questionIcon}
        modalTitle="Mark as Inactive?"
        modalText="Do you want to inactivate this interest?"
      />

      <SiteModal
        show={show2}
        handleClose={() => setShow2(false)}
        lottieIcon={images.checkIcon}
        modalTitle="Sucessfully"
        modalText="Interest Inactivated Successfully!"
        normalModal
      />

      <SiteModal
        show={show3}
        modalType="true"
        actionMethod={activateInterest}
        handleClose={() => setShow3(false)}
        modalImg={images.questionIcon}
        modalTitle="Mark as Active"
        modalText="Do you want to activate this interest?"
      />

      <SiteModal
        show={show4}
        handleClose={() => setShow4(false)}
        lottieIcon={images.checkIcon}
        modalTitle="Sucessfully"
        modalText="Interest Activated Successfully!"
        normalModal
      />

      <SiteModal
        show={show5} 
        handleClose={() => setShow5(false)}
        largeModal
        modalTitle="Add new Interest"
        // modalText="Enter your email address below and we’ll send you a link to reset your password"
      >
        <form onSubmit={handleSubmit} className="mt-4">
          <label className="site-label grey-text mb-1">Interest Name<span className="red-text">*</span></label>
          <SiteInput 
            type="text"
            id="interest_name"
            placeholder="Enter Interest Name" 
            className="grey-input" 
            value={values.interest_name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.interest_name && touched.interest_name ? (
              <small className='text-danger ms-1'>{errors.interest_name}</small>
          ) : (
            <div className="text-danger ms-1">{formError}</div>
          )}
          <div className="d-flex mt-3 align-items-center justify-content-between">
            <SiteButton type="submit" buttonText="Add" className="mt-3" load={load2} />
          </div>
        </form>
      </SiteModal>
    </>
  );
};

export default InterestListing;
