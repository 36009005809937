import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { images } from "../../../Assets";
import { useNavigate } from "react-router";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "../../../Components/Checkbox";
import { Link } from "react-router-dom";
import { SiteModal } from "../../../Components/SiteModal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { Post } from "../../../config/api/post";
import { FORGOT_PASSWORD } from "../../../config/constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  faArrowLeftLong,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import SiteButton from "../../../Components/Button/button";
import SiteInput from "../../../Components/Input/input";

export const ChangeForgetPassword = () => {
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const MySwal = withReactContent(Swal);

  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [code, setCode] = React.useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    navigate("/user/login");
  };

  const [eyeIcon, seteyeIcon] = useState(faEyeSlash);
  const [passwordType, setPasswordType] = useState(0);
  const [password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const toggleIcon = () => {
    if (eyeIcon == faEyeSlash) {
      seteyeIcon(faEye);
      setPasswordType(1);
    } else {
      seteyeIcon(faEyeSlash);
      setPasswordType(0);
    }
  };
  const [eyeIcon2, seteyeIcon2] = useState(faEyeSlash);
  const [passwordType2, setPasswordType2] = useState(0);
  const toggleIcon2 = () => {
    if (eyeIcon2 == faEyeSlash) {
      seteyeIcon2(faEye);
      setPasswordType2(1);
    } else {
      seteyeIcon2(faEyeSlash);
      setPasswordType2(0);
    }
  };
  const [eyeIcon3, seteyeIcon3] = useState(faEyeSlash);
  const [passwordType3, setPasswordType3] = useState(0);
  const toggleIcon3 = () => {
    if (eyeIcon3 == faEyeSlash) {
      seteyeIcon3(faEye);
      setPasswordType3(1);
    } else {
      seteyeIcon3(faEyeSlash);
      setPasswordType3(0);
    }
  };
  const [step, setStep] = useState(1);
  React.useEffect(() => {
    if (user && token) {
      navigate("/", { replace: true });
    }
  }, [user, token]);
  React.useEffect(() => {
    setEmail(localStorage.getItem("forget_password_email"));
    setCode(localStorage.getItem("forget_password_code"));
  }, []);
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    setValidated(true);
    setLoading(true);

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setLoading(false);
    } else {
      let data = {
        email: email,
        code: code,
        password: password,
      };
      if (password !== confirmPassword) {
        MySwal.fire({
          title: "Error!",
          text: "New password and confirm password does not match",
          icon: "error",
          confirmButtonColor: "#19D8C5",
          confirmButtonText: "Cancel",
        });
        return false;
      }

      Post(FORGOT_PASSWORD.resetPassword, data)
        .then((response) => {
          // console.log(response.data);
          setLoading(false);
          if (response?.data?.status) {
            localStorage.removeItem("forget_password_code");
            localStorage.removeItem("forget_password_email");
            navigate("/user/login");
            console.log("response", response?.data?.message);
          } else {
            MySwal.fire({
              title: "Error!",
              text: response?.data?.message,
              icon: "error",
              confirmButtonColor: "#19D8C5",
              confirmButtonText: "Cancel",
            });
          }
          if (response?.response?.status === 422) {
            setLoading(false);

            MySwal.fire({
              title: "Error!",
              text: response.response.data.message,
              icon: "error",
              confirmButtonColor: "#19D8C5",
              confirmButtonText: "Cancel",
            });
          }
        })
        .catch((e) => {
          console.log(":::;", e);
          MySwal.fire({
            title: "Error!",
            text: "Something went wrong",
            icon: "error",
            confirmButtonColor: "#19D8C5",
            confirmButtonText: "Cancel",
          });
          setLoading(false);
        });
    }
  };
  const renderSteps = () => {
    return (
      <>
        <div className="mt-4 text-center">
          <h3 className="cyan-text bold">Forgot Password</h3>
          <p className="grey-text">Set New Password for your Account</p>
        </div>
        <Form noValidate validated={validated} onSubmit={handleClick}>
          <Form.Group controlId="validationCustom02">
            <Form.Label className="bold ms-3 mb-1">
              New Password<span className="red-text">*</span>
            </Form.Label>
          </Form.Group>
          <div className="position-relative">
            <Form.Control
              required
              className="grey-input password-input with-shadow"
              placeholder="Enter new Password"
              onChange={(e) => setPassword(e.target.value)}
              type={passwordType2 === 0 ? "password" : "text"}
            />
            <Form.Control.Feedback type="invalid">
              Please enter new password.
            </Form.Control.Feedback>
            <FontAwesomeIcon
              className="right-icon cyan-text"
              onClick={toggleIcon2}
              icon={eyeIcon2}
            />
          </div>
          <Form.Group controlId="validationCustom03">
            <Form.Label className="bold ms-3 mb-1">
              Confirm Password<span className="red-text">*</span>
            </Form.Label>
          </Form.Group>
          <div className="position-relative">
            <Form.Control
              required
              className="grey-input password-input with-shadow"
              placeholder="Confirm Password"
              onChange={(e) => setconfirmPassword(e.target.value)}
              type={passwordType3 === 0 ? "password" : "text"}
            />
            <Form.Control.Feedback type="invalid">
              Please enter confirm password.
            </Form.Control.Feedback>
            <FontAwesomeIcon
              className="right-icon cyan-text"
              onClick={toggleIcon3}
              icon={eyeIcon3}
            />
          </div>
          <div className="mt-3 text-center">
            <button
              type="submit"
              className="site-btn mt-3"
              disabled={loading ? true : false}
            >
              {loading ? "Loading..." : "Continue"}
            </button>
          </div>
        </Form>
      </>
    );
  };
  return (
    <>
      <section className="user-login">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 login-left d-lg-block d-none"></div>
            <div className="col-lg-6 px-lg-0">
              <div className="login-right">
                <div className="text-center">
                  <img src={images.loginLogo} alt="" className="img-fluid" />
                </div>
                {renderSteps()}
                <div className="text-center">
                  <Link
                    to="/user/login"
                    className="d-inline-flex mt-4 d-grey-text semi-bold align-items-center gap-1 justify-content-center"
                  >
                    <FontAwesomeIcon
                      icon={faArrowLeftLong}
                      className="cyan-text"
                    />
                    Back To Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SiteModal
        show={show}
        handleClose={handleClose}
        modalImg={images.userCheckIcon}
        modalTitle="Sucessfully"
        modalText="Your Password Has Been Reset. Please Login To Continue."
        normalModal
      />
    </>
  );
};
