import React from 'react'
import { BasicTableFilters } from './BasicTableFilters'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faEllipsisV, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Dropdown } from 'react-bootstrap'


export const CustomTable = (props) => {
  return (
    <>
      
      <div className={`table-responsive mt-3${props.className? " " +props.className:""}`}>
        <table className="table">
          <thead>
            <tr>
              {
                props.tableTitles.map((theadings, index) => (
                  <th key={index}>{theadings}</th>
                ))
              }
            </tr>
          </thead>
          <tbody>
            {props.children}
              {/* {
                props.tableItems.map((tItems, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    {tItems.firstName?<td>{tItems.firstName}</td>:""}
                    {tItems.lastName?<td>{tItems.lastName}</td>:""}
                    {tItems.name?<td>{tItems.name}</td>:""}
                    {tItems.description?<td>{tItems.description}</td>:""}
                    {tItems.email?<td>{tItems.email}</td>:""}
                    {tItems.deckName?<td>{tItems.deckName}</td>:""}
                    {tItems.paymentMethod?<td>{tItems.paymentMethod}</td>:""}
                    {tItems.date?<td>{tItems.date}</td>:""}
                    {tItems.amount?<td>{tItems.amount}</td>:""}
                    {tItems.question1?<td>{tItems.question1}</td>:""}
                    {tItems.question2?<td>{tItems.question2}</td>:""}
                    {tItems.question3?<td>{tItems.question3}</td>:""}
                    {tItems.category?<td>{tItems.category}</td>:""}
                    {tItems.subCategory?<td>{tItems.subCategory}</td>:""}
                    {tItems.deck?<td>{tItems.deck}</td>:""}
                    {tItems.status?tItems.status=="Active"?<td><span className='green-tag'>{tItems.status}</span></td>:<td><span className='red-tag'>{tItems.status}</span></td>:""}
                    {tItems.action?
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle className='transparent-btn' id="dropdown-basic">
                            <FontAwesomeIcon icon={faEllipsisV} />
                          </Dropdown.Toggle>
                      
                          <Dropdown.Menu>
                          {props.dropData.map(e=>(
                            <Link to={`${e.to}/${tItems.id}`}>{e.icon?<FontAwesomeIcon className='me-2' icon={e.icon} />:""} {e.label}</Link>
                          ))}
                          {tItems.status=="Active"?<Link onClick={props.modalActiveFunction}><FontAwesomeIcon className='me-2' icon={faTimes} /> Mark Inactive</Link>:tItems.status=="Inactive"?<Link onClick={props.modalInactiveFunction}><FontAwesomeIcon className='me-2' icon={faCheck} /> Mark Active</Link>:""}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    :""}
                  </tr>
                ))
              } */}
          </tbody>
        </table>
      </div>
    </>
  )
}
