import React from "react";

const SiteButton = (props) => {
  return (
    <>
      <button
        type={props.type ? props.type : "button"}
        onClick={props.onClick}
        className={`site-btn ${props.className ? props.className : ""}`}
        disabled={props.load ? true : false}
      >
        {props.load ? (
          <>
            <div
              className="spinner-border loader-btn spinner-border-sm"
              role="status"
            ></div>
            &nbsp;
           </>
        ) : ""}
        {props.buttonText}
      </button>
    </>
  );
};
export default SiteButton;
