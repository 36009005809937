import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown, Offcanvas } from "react-bootstrap";
import { BASE_URL } from "../../config/constants";
import { ADMIN } from "../../config/constants/admin";
import { images } from "../../Assets";
import { NotificationCard } from "./NotificationCard";
import { NotiDropdownCard } from "./NotiDropdownCard";
import { CustomSelect } from "../../Components/Select";
import TablePagination from "../../Components/TableFooter/pagination";
import Loader from "../../Components/Loader";
import axios from "axios";

const Notifications = (props) => {
  const token = useSelector((state) => state.user.userToken);
  const [show, setShow] = useState(false);
  const [load, setLoad] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [type, setType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getNotification = async () => {
    setLoad(true);
    let url = BASE_URL + ADMIN.notification.get + `?page=${currentPage}`;
    if (type) url += `&type=${type}`;

    let data = await axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        let total_records = response.data.data.data.meta.total;
        let records_per_page = response.data.data.data.meta.per_page;
        let total_pages = Math.ceil(total_records / records_per_page);

        response.data.data.data.data.map(
          (item) => (item.read_at = item.read_at ? true : false)
        );

        setNotifications(response?.data?.data?.data?.data);
        setCurrentPage(response.data.data.data.meta.current_page);
        setTotalPages(total_pages);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
        console.error(err.response.data);
      });
  };

  useEffect(() => {
    getNotification();
  }, [type, currentPage]);

  useEffect(() => {
    setType("");
  }, [show]);

  const handleRead = async (id) => {
    let data = await axios
      .get(BASE_URL + ADMIN.notification.mark_read + `/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => {
        getNotification();
      })
      .catch((err) => console.error(err.response.data.message));
  };

  return (
    <>
      <Dropdown className="noti-dropdown me-3">
        <Dropdown.Toggle className="transparent-btn" id="dropdown-basic-2">
          <img
            src={images.notiIcon}
            alt=""
            className="img-fluid noti-bell-icon"
          />
          {props.noTag ? (
            ""
          ) : (
            <>
              <br /> <span className="white-text">Notification</span>
            </>
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <p className="mb-0 px-3 blue-text medium">Notifications</p>
          <div className="notifications-wrapper">
            {load ? (
              <div className="mt-5">
                <Loader sm />
              </div>
            ) : notifications.length > 0 ? (
              notifications
                .slice(0, 4)
                .map((item) => (
                  <NotiDropdownCard item={item} handleRead={handleRead} />
                ))
            ) : (
              <p className="text-center text-muted mt-3">
                No notification found!
              </p>
            )}
          </div>
          <div className="text-end">
            <Link className="cyan-text p-sm mx-3" onClick={handleShow}>
              View All
            </Link>
          </div>
        </Dropdown.Menu>
      </Dropdown>

      <Offcanvas className="noti-offcanvas" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Notifications</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-0 justify-content-start">
          <div className="w-180">
            <label htmlFor="" className="semi-bold">
              Filter
            </label>
            <CustomSelect
              className="grey-input"
              onChange={(e) => setType(e.target.value)}
            >
              <option value="" selected>
                Select
              </option>
              <option value="read">Read</option>
              <option value="unread">Unread</option>
            </CustomSelect>
          </div>
          <div className="notifications-preview">
            {load ? (
              <div className="mt-5">
                <Loader />
              </div>
            ) : notifications.length > 0 ? (
              notifications.map((item) => (
                <NotificationCard item={item} handleRead={handleRead} />
              ))
            ) : (
              <p className="text-center text-muted mt-5">
                No notification found!
              </p>
            )}
          </div>
          <div className="text-end">
            <TablePagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Notifications;
