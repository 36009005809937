import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = (props) => {
    const user = useSelector((state) => state.user.userData);
    const token = useSelector((state) => state.user.userToken);

    if (user && token) {
        if (user?.role?.role_name === "admin") {
            return <Outlet />;

        } else {
            return <Navigate to="/" />;
        }

    } else {
        return <Navigate to="/admin" />;
    }
}

export default ProtectedRoute