import React from "react";
import { UserLayout } from "../../../Components/UserComponents/UserLayout/userLayout";
import { InnerBanner } from "../../../Components/UserComponents/InnerBanner";
import { InterestedUsersPlaces } from "./InterestedAdPlaces";

export const AdListing = () => {
  return (
    <UserLayout>
      <InnerBanner
        backButton="false"
        bannerHeading="Ads "
        coloredHeading="Listing"
      />
      <InterestedUsersPlaces />
    </UserLayout>
  );
};
