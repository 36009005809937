import { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faToggleOn,
  faToggleOff,
} from "@fortawesome/free-solid-svg-icons";
import { images } from "../../Assets";
import { subscriptionValidate } from "../../config/validations";
import { BASE_URL } from "../../config/constants";
import { ADMIN } from "../../config/constants/admin";
import { Layout } from "../../Components/Layout/layout";
import { SiteModal } from "../../Components/SiteModal";
import SiteButton from "../../Components/Button/button";
import SiteInput from "../../Components/Input/input";
import axios from "axios";

export const AddSubscription = () => {
  const navigate = useNavigate();
  const token = useSelector(state => state.user.userToken);

  const [load, setLoad] = useState(false);
  const [show, setShow] = useState(false);

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {name: "", price: "", validity: ""},
      validationSchema: subscriptionValidate,
      onSubmit: (values) => handleFormSubmit(values)
    });

  const handleFormSubmit = async (data) => {
    setLoad(true);

    let response = await axios.post(
      BASE_URL + ADMIN.subscription.add,
      data,
      {headers: {Authorization: `Bearer ${token}`}}
    )
    .then(res => {
      setLoad(false)
      setShow(true)
    })
    .catch(err => {
      console.error(err.response);
      setLoad(false)
    })
  }

  const actionMethod = () => {
    setShow(false);
    navigate("/admin/subscription");
  }

  return (
    <Layout>
      <div className="row">
        <div className="col-12">
          <h3 className="blue-text mb-0">
            <Link to="/admin/subscription" className="blue-text">
              <FontAwesomeIcon className="white-text" icon={faAngleLeft} />
            </Link>{" "}
            Create New Subscription Package
          </h3>
        </div>
        <div className="col-xxl-8 col-xl-10 col-12">
          <form onSubmit={handleSubmit}>
            <div className="row mt-4">
              <div className="col-md-6 my-3">
                <label htmlFor="name" className="mb-0 medium">Package Name<span className="red-text">*</span></label>
                <SiteInput
                  type="text"
                  id="name"
                  placeholder="Enter Package Name"
                  className="site-input"
                  autoComplete="off"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.name && touched.name && (
                  <small className="text-danger ms-1">{errors.name}</small>
                )}
              </div>
              <div className="col-md-6 my-3">
                <label htmlFor="price" className="mb-0 medium">Package Price<span className="red-text">*</span></label>
                <SiteInput
                  type="number"
                  id="price"
                  placeholder="Enter Package Price"
                  className="site-input"
                  value={values.price}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.price && touched.price && (
                  <small className="text-danger ms-1">{errors.price}</small>
                )}
              </div>
              <div className="col-md-6 my-3">
                <label htmlFor="validity" className="mb-0 medium">Valid Upto-Days<span className="red-text">*</span></label>
                <SiteInput
                  type="number"
                  id="validity"
                  placeholder="Valid Uptil When"
                  className="site-input"
                  value={values.validity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.validity && touched.validity && (
                  <small className="text-danger ms-1">{errors.validity}</small>
                )}
              </div>
              <div className="col-12 mt-4">
                <SiteButton type="submit" buttonText="Create" load={load} />
              </div>
            </div>
          </form>
        </div>
      </div>

      <SiteModal
        show={show}
        handleClose={actionMethod}
        lottieIcon={images.checkIcon}
        modalTitle="Sucessfully"
        modalText="Package Created Successfully!"
        normalModal
      />
    </Layout>
  );
};
