import React from "react";
import { Link } from "react-router-dom";
import { images } from "../../../Assets";

export const SiteFooter = () => {
  return (
    <footer className="pt-md-5 pt-4">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 text-center my-3">
            <Link to="/">
              <img src={images.logo} alt="" className="img-fluid" />
            </Link>
            <ul className="footer-links d-flex justify-content-center gap-md-4 gap-3 flex-wrap align-items-center">
              <li className="mt-3">
                <Link to="/" className="semi-bold">
                  Home
                </Link>
              </li>
              <li className="mt-3">
                <Link to="/about-us" className="semi-bold">
                  About Us
                </Link>
              </li>
              <li className="mt-3">
                <Link to="/interested-ads" className="semi-bold">
                  Interested Ads
                </Link>
              </li>
              <li className="mt-3">
                <Link to="/rent-places" className="semi-bold">
                  Rent Places
                </Link>
              </li>
              <li className="mt-3">
                <Link to="/contact-admin" className="semi-bold">
                  Contact Admin
                </Link>
              </li>
            </ul>
            <ul className="footer-links d-flex justify-content-center gap-2 flex-wrap align-items-center">
              <li className="mt-3">
                <a
                  className="semi-bold"
                  href="https://www.facebook.com/roomiesandrentals/"
                  target="__blank"
                >
                  <img src={images.fbIcon} alt="" className="img-fluid" />
                </a>
              </li>
              <li className="mt-3">
                <a
                  className="semi-bold"
                  href="https://www.instagram.com/roomiesandrentals/"
                  target="__blank"
                >
                  <img src={images.instaIcon} alt="" className="img-fluid" />
                </a>
              </li>
              <li className="mt-3">
                <a
                  className="semi-bold"
                  href="https://www.tiktok.com/@roomiesandrentals"
                  target="__blank"
                >
                  <img
                    src={images.tiktokIcon}
                    alt=""
                    className="img-fluid"
                    width={45}
                  />
                </a>
              </li>
              {/* <li className="mt-3">
                <Link className="semi-bold">
                  <img src={images.linkedinIcon} alt="" className="img-fluid" />
                </Link>
              </li> */}
              {/* <li className="mt-3">
                <Link className="semi-bold">
                  <img src={images.youtubeIcon} alt="" className="img-fluid" />
                </Link>
              </li> */}
              {/* <li className="mt-3">
                <Link className="semi-bold">
                  <img src={images.twitterIcon} alt="" className="img-fluid" />
                </Link>
              </li> */}
              {/* <li className="mt-3">
                <Link className="semi-bold">
                  <img src={images.pintrestIcon} alt="" className="img-fluid" />
                </Link>
              </li> */}
            </ul>
          </div>
          <div className="col-12 text-center mt-2">
            <div className="footer-bottom pt-3 pb-4">
              <ul className="footer-links d-flex justify-content-center gap-md-4 gap-3 flex-wrap align-items-center">
                <li className="mt-3">
                  <Link to="/privacy-policy" className="semi-bold">
                    Privacy Policy
                  </Link>
                </li>
                <li className="mt-3">
                  <Link to="/terms-and-conditions" className="semi-bold">
                    Terms & Conditions
                  </Link>
                </li>
                <li className="mt-3">
                  <Link to="/" className="semi-bold">
                    User Agreement
                  </Link>
                </li>
                <li className="mt-3">
                  <Link to="/faqs" className="semi-bold">
                    FAQ's
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom-wrapper text-center">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="mb-0 orange-text">
                Copyright ©2024 Meta App Designs. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
