import { faFilter, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import SiteInput from "../../Input/input";
import SiteButton from "../../Button/button";
import { Dropdown } from "react-bootstrap";
import { CustomSelect } from "../../Select";
import { Get } from "../../../config/api/get";
import { RENTER } from "../../../config/constants";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const RentFilters = ({
  onSearchFilter,
  onGenderFilter,
  onInterestFilter,
  onClearFilter,
  onApplyFilter,
}) => {
  const token = useSelector((state) => state.user.userToken);
  const MySwal = withReactContent(Swal);
  const [genderFilter, setGenderFilter] = useState("");
  const [search, setSearch] = useState("");

  const [interestFilter, setInterestFilter] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = useState([
    {
      id: 1,
      interest_name: "Tiktok",
      status: 1,
      created_at: "2023-08-01T13:04:19.000000Z",
      updated_at: "2023-08-01T13:04:19.000000Z",
    },
  ]);
  useEffect(() => {
    getInterests();
  }, []);

  const handleSearch = (event) => {
    const data = event.target.value;
    setSearch(data);
    onSearchFilter(data);
  };
  const handleInterestsFilter = (event) => {
    const data = event.target.value;
    setInterestFilter(data);
    onInterestFilter(data);
  };
  const getInterests = () => {
    Get(RENTER.getInterents, token)
      .then((response) => {
        if (response?.status) {
          setOptions(response?.data);
          setLoading(false);
        }
        if (response?.response?.status === 422) {
          setLoading(false);
          MySwal.fire({
            title: "Error!",
            text: response.response.data.message,
            icon: "error",
            confirmButtonColor: "#19D8C5",
            confirmButtonText: "Cancel",
          });
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        MySwal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
          confirmButtonColor: "#19D8C5",
          confirmButtonText: "Cancel",
        });
        setLoading(false);
      });
  };
  const handleGenderFilter = (event) => {
    const data = event.target.value;
    setGenderFilter(data);
    onGenderFilter(data);
  };
  const handleClearFilter = (event) => {
    setGenderFilter("");
    setSearch("");
    setInterestFilter("");
    onClearFilter(event);
  };
  const handleApplyFilter = (event) => {
    onApplyFilter(event);
  };
  return (
    <div className="rent-filters">
      <div className="flex-grow-1">
        <div className="search-barr">
          <FontAwesomeIcon icon={faSearch} className="left-icon cyan-text" />
          <SiteInput
            value={search}
            onChange={handleSearch}
            placeholder="Search"
            className="pe-4"
          />
        </div>
      </div>
      <div className="flex-shrink-0">
        <Dropdown className="rent-filters-dropdown" align="end">
          <Dropdown.Toggle className="rent-filter-btn" id="dropdown-basic">
            <FontAwesomeIcon icon={faFilter} />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <div className="text-center">
              <p className="p-md mb-0">Filter</p>
            </div>
            <hr className="blue-line" />
            <p className="bold mb-0">Filter By Gender:</p>
            <div className="my-2">
              <label htmlFor="" className="bold ms-3">
                Preffered Gender
              </label>
              <CustomSelect
                onChange={handleGenderFilter}
                className="grey-input with-shadow"
                value={genderFilter}
              >
                <option value="" disabled>
                  Select Gender
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </CustomSelect>
            </div>
            {/* <p className="bold mb-0">Filter By Area/City:</p>
            <div className="row">
              <div className="col-sm-6 my-2">
                <label htmlFor="" className="bold ms-3">
                  Area
                </label>
                <CustomSelect className="grey-input with-shadow">
                  <option value="">Abc Area</option>
                </CustomSelect>
              </div>
              <div className="col-sm-6 my-2">
                <label htmlFor="" className="bold ms-3">
                  City
                </label>
                <CustomSelect className="grey-input with-shadow">
                  <option value="">US</option>
                </CustomSelect>
              </div>
            </div> */}
            <p className="bold mb-0">Filter By Interests:</p>
            <div className="my-2">
              <label htmlFor="" className="bold ms-3">
                Interests:
              </label>
              <CustomSelect
                onChange={handleInterestsFilter}
                className="grey-input with-shadow"
                value={interestFilter}
              >
                <option value="" selected disabled>
                  Select Interests
                </option>
                {options.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.interest_name}
                  </option>
                ))}
              </CustomSelect>
            </div>
            <div className="mt-4 mb-2 d-flex align-items-center gap-2 flex-wrap justify-content-center text-center">
              <SiteButton
                className="mxw-auto px-3 py-2"
                onClick={handleClearFilter}
                buttonText="Clear Filters"
              />
              <SiteButton
                className="mxw-auto px-3 py-2"
                onClick={handleApplyFilter}
                buttonText="Apply Filters"
              />
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
