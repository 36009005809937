import React from "react";
import { useState } from "react";
import SiteInput from "../../../../Components/Input/input";
import SiteButton from "../../../../Components/Button/button";
import { images } from "../../../../Assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Slider from "@mui/material/Slider";

export const RenterBanner = () => {
  const [total, setTotal] = useState(0); // Initial value
  const [price, setPrice] = React.useState(288);
  const [value, setValue] = React.useState(7);

  function valueLabelFormat(value) {
    console.log(value);
    return value;
  }
  function calculateValue(value) {
    setTotal(price * value);
    return value;
  }
  const handleChange = (event, newValue) => {
    if (typeof newValue === "number") {
      setValue(newValue);
    }
  };
  return (
    <section className="banner">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="renter-banner">
              <div className="row align-items-center">
                <div className="col-lg-4 my-3">
                  <p className="p-md light mb-0">Roomies&Rentals</p>
                  <h3 className="mb-0">You Could Earn</h3>
                  <h2 className="cyan-text">${total}</h2>
                  <p className="light mb-0">
                    {value} Nights At An Estimated ${price} A Night
                  </p>
                  {/* <input
                    max="30"
                    value={rangeValue}
                    onChange={handleRangeChange}
                    style={{ "--value": `${filledProgress}%` }}
                    className="custom-range-input mt-4"
                  /> */}
                  {/* <Slider
                    defaultValue={rangeValue}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                  /> */}
                  <Slider
                    value={value}
                    min={1}
                    step={1}
                    max={30}
                    scale={calculateValue}
                    getAriaValueText={valueLabelFormat}
                    valueLabelFormat={valueLabelFormat}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    aria-labelledby="non-linear-slider"
                  />

                  <div className="search-barr mt-4">
                    <FontAwesomeIcon
                      icon={faSearch}
                      className="left-icon cyan-text"
                    />
                    <SiteInput placeholder="Search" />
                    <SiteButton
                      buttonText="Search"
                      className="web-btn"
                      id="button-addon2"
                    />
                  </div>
                </div>
                <div className="col-lg-8 text-end pe-lg-4 my-3">
                  <iframe
                    title="ifarmeTitle"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193596.00927745472!2d-74.14448729588005!3d40.69737092121888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2s!4v1700823655631!5m2!1sen!2s"
                    width="600"
                    height="450"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                  {/* <img src={images.mapImg} alt="" className="img-fluid w-100 ps-xl-4" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
