import React from 'react'
import { UserLayout } from '../../../Components/UserComponents/UserLayout/userLayout'
import { RenterBanner } from './RenterBanner'
import { WithSetup } from './WithSetup'
import { TopBottomProtection } from './TopBottomProtection'
import { FriendlyApartments } from './FriendlyApartments'
import { FAQS } from './Faqs'


export const BecomeARenter = () => {
    
    return (
        <UserLayout>
            <RenterBanner />
            <WithSetup />
            <TopBottomProtection />
            <FriendlyApartments />
            <FAQS />
        </UserLayout>
    )
}
