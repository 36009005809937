import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Table } from "react-bootstrap";
import SiteButton from "../../../../Components/Button/button";

export const TopBottomProtection = () => {
  const protectionItems = [
    {
      id: 1,
      heading: "Guest Identity ",
      description:
        "We believe in the complete verification of both parties getting into a rental agreement through legal ways. Every essential personal identity document must be posted on the portal to support authenticity.",
      colorHeading: "Verification",
      roomieRentals: true,
      competitors: true,
    },
    {
      id: 2,
      heading: "Reservation ",
      colorHeading: "Screening",
      description:
        "Roomies and Rentals suggest evaluating people by meeting them through proper channels and finding potential candidates for renting your dear place. ",
      roomieRentals: true,
      competitors: false,
    },
    {
      id: 3,
      heading: "$3M Damage ",
      colorHeading: "Protection",
      description:
        " (unable to provide content for this section. Kindly clarify what the client wants us to write.)",
      roomieRentals: true,
      competitors: false,
    },
    {
      id: 4,
      heading: "24-Hour ",
      colorHeading: "Safety Line",
      description:
        "Download our mobile application to get the quickest safety and security services 24/7 during your stay at our suggested place. It will be activated 24 hours before check-in and terminated after 24 hours of check-out.",
      roomieRentals: true,
      competitors: false,
    },
    {
      id: 5,
      heading: "$1M Liability ",
      colorHeading: "Insurance",
      description: "Vague (kindly provide clearance)",
      roomieRentals: true,
      competitors: false,
    },
  ];
  return (
    <section className="top-bottom-protection">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Table className="top-bottom-table" responsive>
              <thead>
                <tr>
                  <th>
                    <h3 className="mb-0">
                      Roomies & Rentals It With{" "}
                      <span className="cyan-text bold">
                        {" "}
                        Top-Tobottom Protection
                      </span>
                    </h3>
                  </th>
                  <th>Roomies & Rentals </th>
                  <th>Competitors</th>
                </tr>
              </thead>
              <tbody>
                {protectionItems.map((item) => (
                  <tr>
                    <td>
                      <h5>
                        {item.heading}{" "}
                        <span className="bold cyan-text">
                          {item.colorHeading}
                        </span>
                      </h5>
                      {item.description ? (
                        <p className="l-blue-text mb-0 description-text">
                          {item.description}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {item.roomieRentals ? (
                        <span className="table-icon check-icon">
                          <FontAwesomeIcon icon={faCheck} />
                        </span>
                      ) : (
                        <span className="table-icon cross-icon">
                          <FontAwesomeIcon icon={faTimes} />
                        </span>
                      )}
                    </td>
                    <td>
                      {item.competitors ? (
                        <span className="table-icon check-icon">
                          <FontAwesomeIcon icon={faCheck} />
                        </span>
                      ) : (
                        <span className="table-icon cross-icon">
                          <FontAwesomeIcon icon={faTimes} />
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="text-center mt-4">
              <SiteButton buttonText="Learn More" className="web-btn" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
