import React from "react";
import { UserLayout } from "../../../Components/UserComponents/UserLayout/userLayout";
import { InnerBanner } from "../../../Components/UserComponents/InnerBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export const PrivacyPolicy = () => {
  return (
    <>
      <UserLayout>
        <InnerBanner bannerHeading="Privacy" coloredHeading="Policy" />
        <section className="my-profile">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div>
                  <p className="semi-bold mt-4">
                    At Roomies and Rentals, we are committed to safeguarding
                    your privacy. This privacy policy outlines how we collect,
                    use, share, and protect your personal information when you
                    use our rental services. Information Collection: We collect
                    personal information such as name, email address, phone
                    number, and billing details when creating an account,
                    booking, or communicating with us. Additionally, we gather
                    non-personal information like device information, browser
                    type, and usage patterns through cookies and similar
                    technologies. Use of Information: Your information is
                    utilized to provide and improve our rental services, process
                    payments, communicate with you about bookings and
                    promotions, personalize your experience, and ensure
                    compliance with legal obligations. Information Sharing: We
                    may share your information with third-party service
                    providers, such as payment processors or logistics partners,
                    essential for our operations. We do not sell or rent your
                    personal information to third parties for marketing
                    purposes. Data Security: We employ industry-standard
                    security measures to protect your information against
                    unauthorized access, alteration, disclosure, or destruction.
                    Your Choices: You can review, update, or delete your account
                    information anytime. You may also manage cookie preferences
                    through your browser settings. Children’s Privacy: Our
                    services are not directed towards individuals under 18 years
                    old. We do not knowingly collect personal information from
                    minors. Updates to Policy: We reserve the right to update
                    this privacy policy. Any changes will be communicated
                    through our website or via email. You agree to collect and
                    use your information as outlined in this policy by using our
                    services. Please get in touch with us at (Contact
                    number/email) for any inquiries regarding this privacy
                    policy. This brief privacy policy gives users an overview of
                    how their information is handled on the rental website,
                    addressing critical aspects of data collection, usage,
                    security, and user rights.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </UserLayout>
    </>
  );
};
