import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons';
import { Layout } from '../../Components/Layout/layout'
import { SiteModal } from '../../Components/SiteModal';
import { images } from '../../Assets';
import { BASE_URL } from '../../config/constants';
import { ADMIN } from '../../config/constants/admin';
import Loader from '../../Components/Loader';
import axios from 'axios';

export const UserDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const token = useSelector(state => state.user.userToken);
  const [userDetail, setUserDetail] = useState({});
  const [load, setLoad] = useState(true);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  
  const loadUserDetail = async () => {
    setLoad(true);

    let data = await axios.get(BASE_URL + ADMIN.user.get + `/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(response => {
      setLoad(false);
      setUserDetail(response.data.data);
    })
    .catch(err => {
      setLoad(false);
      navigate("/admin/user");
      console.error(err.response.data.message);
    });
  }

  useEffect(() => {
    loadUserDetail();
  }, []);

  const activateUser = async () => {
    let data = await axios.post(BASE_URL + ADMIN.user.update_status + `/${id}`, {}, {
      headers: {Authorization: `Bearer ${token}`}
    })
    .then(() => {
      setShow3(false);
      setShow4(true);
      loadUserDetail();
    })
    .catch(err => {
      console.error(err.response.data);
    })
  }

  const inactivateUser = async () => {
    let data = await axios.post(BASE_URL + ADMIN.user.update_status + `/${id}`, {}, {
      headers: {Authorization: `Bearer ${token}`}
    })
    .then(() => {
      setShow(false);
      setShow2(true);
      loadUserDetail();
    })
    .catch(err => {
      console.error(err.response.data);
    })
  }

  return (
    <Layout>
        <div className="row">
          <div className="col-12">
            <div className="d-sm-flex align-items-center justify-content-between">
              <h3 className='blue-text mb-0'>
                <Link to="/admin/user" className='blue-text'>
                  <FontAwesomeIcon className='white-text' icon={faAngleLeft} />
                </Link> User Details
              </h3>
              {!load && (userDetail.status == 1 ? (
                <p className='mb-0 d-flex align-items-center blue-text medium'>
                  Active
                  <FontAwesomeIcon 
                    onClick={() => setShow(true)} 
                    className='green-text cursor-pointer ms-2 p-md' 
                    icon={faToggleOn} 
                  />
                </p>
              ) : (
                <p className='mb-0 d-flex align-items-center blue-text medium'>
                  Inactive 
                  <FontAwesomeIcon 
                    onClick={() => setShow3(true)} 
                    className='red-text cursor-pointer ms-2 p-md' 
                    icon={faToggleOff} 
                  />
                </p>
              ))}
              </div>
          </div>
          {load ? (
            <Loader />
          ) : (
            <div className="col-xxl-6 col-xl-8 col-lg-10">
              <img src={images.userProfilePic} alt="" className="img-fluid user-details-img mt-4 mb-3" />
              <div className="row">
                <div className="col-md-6 mt-3">
                  <p className="mb-0 medium">Name:</p>
                  <p className="mb-0 m-grey-text">{userDetail.name}</p>
                </div>
                <div className="col-md-6 mt-3">
                  <p className="mb-0 medium">Email:</p>
                  <p className="mb-0 m-grey-text">{userDetail.email}</p>
                </div>
                <div className="col-md-6 mt-3">
                  <p className="mb-0 medium">Registered On:</p>
                  <p className="mb-0 m-grey-text">{userDetail.date}</p>
                </div>
                <div className="col-md-6 mt-3">
                  <p className="mb-0 medium">Gender:</p>
                  <p className="mb-0 m-grey-text">{userDetail.gender ?? "-"}</p>
                </div>
              </div>
            </div>
          )}
        </div>

      <SiteModal
        show={show} 
        modalType="true" 
        actionMethod={inactivateUser} 
        handleClose={() => setShow(false)} 
        modalImg={images.questionIcon} 
        modalTitle="Mark as Inactive" 
        modalText="Do you want to inactivate this user?" 
      />

      <SiteModal
        show={show2} 
        handleClose={() => setShow2(false)} 
        lottieIcon={images.checkIcon} 
        modalTitle="Sucessfully" 
        modalText="User Inactivated Successfully!" 
        normalModal 
      />

      <SiteModal
        show={show3} 
        modalType="true" 
        actionMethod={activateUser} 
        handleClose={() => setShow3(false)} 
        modalImg={images.questionIcon} 
        modalTitle="Mark as Active" 
        modalText="Do you want to activate this user?" 
      />

      <SiteModal
        show={show4} 
        handleClose={() => setShow4(false)} 
        lottieIcon={images.checkIcon} 
        modalTitle="Sucessfully" 
        modalText="User Activated Successfully!" 
        normalModal 
      />
    </Layout>
    
  )
}
