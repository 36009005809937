import React from "react";
import SiteButton from "../../../../Components/Button/button";
import SiteInput from "../../../../Components/Input/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { images } from "../../../../Assets";

export const Banner = () => {
  return (
    <section className="banner">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 my-3">
            <h1 className="regular">
              Explore Affordable{" "}
              <span className="semi-bold cyan-text">
                and Spacious Rentals Spaces
              </span>
              Rooms
            </h1>
            <p className="pe-xxl-5">
              Roomies and Rentals is the platform that helps you connect with
              property owners willing to rent their spaces at reasonable rates
              in the city’s most suitable locations.
            </p>
            {/* <div className="search-barr mt-4">
              <FontAwesomeIcon
                icon={faSearch}
                className="left-icon cyan-text"
              />
              <SiteInput placeholder="Search" />
              <SiteButton
                buttonText="Search"
                className="web-btn"
                id="button-addon2"
              />
            </div> */}
          </div>
          <div className="col-lg-5 ms-auto my-3">
            <div className="banner-img">
              <img src={images.bannerImg} alt="" className="img-fluid w-100" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
