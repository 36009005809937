import React from "react";
import { UserLayout } from "../../../Components/UserComponents/UserLayout/userLayout";
import { InnerBanner } from "../../../Components/UserComponents/InnerBanner";
import { AllRentPlaces } from "./AllRentPlaces";

export const RentPlaces = () => {
  return (
    <UserLayout>
      <InnerBanner
        backButton="false"
        bannerHeading="All Rent"
        coloredHeading="Places"
      />
      <AllRentPlaces />
    </UserLayout>
  );
};
