import React from 'react'
import { UserLayout } from '../../../Components/UserComponents/UserLayout/userLayout'
import { Banner } from './Banner'
import { RecentAds } from './RecentAds'
import { AboutRoomies } from './AboutRoomies'
import { HomeVideo } from './HomeVideo'
import { FeaturedAds } from './FeaturedAds'
import { Testimonials } from './Testimonials'
import { ContactUs } from './ContactUs'

export const Home = () => {
  return (
    <UserLayout>
      <Banner />
      <RecentAds />
      <AboutRoomies />
      <HomeVideo />
      <FeaturedAds />
      <Testimonials />
      <ContactUs />
    </UserLayout>
  )
}
