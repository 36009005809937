import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faToggleOn, faToggleOff, faCircle } from "@fortawesome/free-solid-svg-icons";
import { Layout } from "../../Components/Layout/layout";
import { SiteModal } from "../../Components/SiteModal";
import { CustomTable } from "../../Components/CustomTable";
import { TableFooter } from "../../Components/TableFooter";
import { PropertyCard } from "./PropertyCard";
import { images } from "../../Assets";
import { BASE_URL } from '../../config/constants';
import { ADMIN } from '../../config/constants/admin';
import Loader from '../../Components/Loader';
import axios from 'axios';

export const propertyTitles = [
  "S.No",
  "Appartment Name",
  "Address",
  "City",
  "Updated Date",
  "Status",
  "Action",
]

export const RenterDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const token = useSelector(state => state.user.userToken);
  const [renterDetail, setRenterDetail] = useState({});
  const [propertyDetail, setPropertyDetail] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [load, setLoad] = useState(true);
  const [load2, setLoad2] = useState(true);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);

  const loadRenterDetail = async () => {
    setLoad(true);

    let data = await axios.get(BASE_URL + ADMIN.renter.get + `/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(response => {
      setLoad(false);
      setRenterDetail(response.data.data);
    })
    .catch(err => {
      setLoad(false);
      navigate("/admin/renter");
      console.error(err.response.data.message);
    });
  }

  useEffect(() => {
    loadRenterDetail();
  }, []);

  const activateRenter = async () => {
    let data = await axios.post(BASE_URL + ADMIN.renter.update_status + `/${id}`, {}, {
      headers: {Authorization: `Bearer ${token}`}
    })
    .then(() => {
      setShow3(false);
      setShow4(true);
      loadRenterDetail();
    })
    .catch(err => {
      console.error(err.response.data);
    })
  }

  const inactivateRenter = async () => {
    let data = await axios.post(BASE_URL + ADMIN.renter.update_status + `/${id}`, {}, {
      headers: {Authorization: `Bearer ${token}`}
    })
    .then(() => {
      setShow(false);
      setShow2(true);
      loadRenterDetail();
    })
    .catch(err => {
      console.error(err.response.data);
    })
  }

  const loadPropertyDetail = async () => {
    setLoad2(true);

    let data = await axios.get(BASE_URL + ADMIN.renter.get + `/${id}/ads?page=${currentPage}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(response => {
      let total_records    = response.data.data.meta.total;
      let records_per_page = response.data.data.meta.per_page;
      let total_pages      = Math.ceil(total_records / records_per_page);
      
      setPropertyDetail(response.data.data.data);
      setCurrentPage(response.data.data.meta.current_page);
      setTotalRecords(total_records);
      setTotalPages(total_pages);
      setLoad2(false);
    })
    .catch(err => {
      setLoad2(false);
      console.error(err.response.data.message);
    });
  }

  useEffect(() => {
    loadPropertyDetail()
  }, [currentPage]);

  return (
    <Layout>
      <div className="row">
        <div className="col-12">
          <div className="d-sm-flex align-items-center justify-content-between">
            <h3 className="blue-text mb-0">
              <Link to="/admin/renter" className="blue-text">
                <FontAwesomeIcon className="white-text" icon={faAngleLeft} />
              </Link>{" "}
              Renter Details
            </h3>
            {!load && (renterDetail.status == 1 ? (
              <p className='mb-0 d-flex align-items-center blue-text medium'>
                Active
                <FontAwesomeIcon 
                  onClick={() => setShow(true)} 
                  className='green-text cursor-pointer ms-2 p-md' 
                  icon={faToggleOn} 
                />
              </p>
            ) : (
              <p className='mb-0 d-flex align-items-center blue-text medium'>
                Inactive 
                <FontAwesomeIcon 
                  onClick={() => setShow3(true)} 
                  className='red-text cursor-pointer ms-2 p-md' 
                  icon={faToggleOff} 
                />
              </p>
            ))}
          </div>
        </div>

        {load ? (
          <Loader />
        ) : (
          <div className="col-xxl-6 col-xl-8 col-lg-10">
            <img src={images.userProfilePic} alt="" className="img-fluid user-details-img mt-4 mb-3" />
            <div className="row">
              <div className="col-md-6 mt-3">
                <p className="mb-0 medium">Name:</p>
                <p className="mb-0 m-grey-text">{renterDetail.name}</p>
              </div>
              <div className="col-md-6 mt-3">
                <p className="mb-0 medium">Email:</p>
                <p className="mb-0 m-grey-text">{renterDetail.email}</p>
              </div>
              <div className="col-md-6 mt-3">
                <p className="mb-0 medium">Registered On:</p>
                <p className="mb-0 m-grey-text">{renterDetail.date}</p>
              </div>
              <div className="col-md-6 mt-3">
                <p className="mb-0 medium">Gender:</p>
                <p className="mb-0 m-grey-text">{renterDetail.gender ?? "-"}</p>
              </div>
            </div>
          </div>
        )}

        <div className="col-12 mt-4">
          <h3 className="blue-text mb-0">Properties Listed</h3>
          <div className="row">
            {/* {currentEntry.properties.map((item) => (
              <div className="col-xxl-3 col-xl-4 col-sm-6 my-3">
                <PropertyCard
                  img={item.img}
                  address={item.address}
                  preferredGender={item.preferredGender}
                />
              </div>
            ))} */}
            <CustomTable tableTitles={propertyTitles}>
              {load2 ? (
                <tr>
                  <td colSpan="100%"><Loader sm /></td>
                </tr>
              ) : (
                propertyDetail.length > 0 ? (
                  propertyDetail.map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.renter_name}</td>
                      <td>{item.address}</td>
                      <td>{item.city}</td>
                      <td>{item.date}</td>
                      <td>
                        <span className={`table-tag ${item.status == 1 ? "green-tag" : 'red-tag'}`}>
                          <FontAwesomeIcon icon={faCircle}/>{item.status == 1 ? "Active" : "Inactive"}
                        </span>
                      </td>
                      <td>
                        <Link to={`/admin/ads-details/${item.id}`}>View</Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="100%" className='text-center text-muted'>No record found!</td>
                  </tr>
                )
              )}
            </CustomTable>
            <TableFooter
              showingItem={propertyDetail.length} 
              totalItems={totalRecords} 
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div> 

      <SiteModal
        show={show} 
        modalType="true" 
        actionMethod={inactivateRenter} 
        handleClose={() => setShow(false)} 
        modalImg={images.questionIcon} 
        modalTitle="Mark as Inactive" 
        modalText="Do you want to inactivate this renter?" 
      />

      <SiteModal
        show={show2} 
        handleClose={() => setShow2(false)} 
        lottieIcon={images.checkIcon} 
        modalTitle="Sucessfully" 
        modalText="Renter Inactivated Successfully!" 
        normalModal 
      />

      <SiteModal
        show={show3} 
        modalType="true" 
        actionMethod={activateRenter} 
        handleClose={() => setShow3(false)} 
        modalImg={images.questionIcon} 
        modalTitle="Mark as Active" 
        modalText="Do you want to activate this renter?" 
      />

      <SiteModal
        show={show4} 
        handleClose={() => setShow4(false)} 
        lottieIcon={images.checkIcon} 
        modalTitle="Sucessfully" 
        modalText="Renter Activated Successfully!" 
        normalModal 
      />
    </Layout>
  );
};
