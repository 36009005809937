import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AuthCheck = ({ children }) => {
  const token = useSelector((state) => state.user.userToken);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);

  return <>{children}</>;
};
export default AuthCheck;
