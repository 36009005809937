import * as Yup from "yup";

export const loginValidate = Yup.object({
  email: Yup.string().email().required("Required").label("Email Address"),
  password: Yup.string().required("Required").label("Password"),
});

export const fPasswordValidate = Yup.object({
  email: Yup.string().email().required("Required").label("Email Address"),
});

export const fPasswordValidate2 = Yup.object({
  code: Yup.string()
    .matches(/^[0-9]+$/, "Invalid code.")
    .required("Required")
    .label("Verification code"),
});

export const fPasswordValidate3 = Yup.object({
  password: Yup.string()
    .min(8)
    .max(50)
    .required("Required")
    .label("New password"),
  confirm_password: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password"), null], "Passwords must match.")
    .label("Confirm password"),
});

export const profileValidate = Yup.object({
  first_name: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed.")
    .required("Required")
    .label("Name"),
  phone: Yup.string()
    .min(5)
    .max(20)
    // .matches(/^[0-9]+$/, "Invalid phone number.")
    .required("Required")
    .label("Phone number"),
  date_of_birth: Yup.date()
    .max(new Date(), "Invalid date of birth.")
    .required("Required")
    .label("Date of birth"),
});

export const passwordValidate = Yup.object({
  old_password: Yup.string().required("Required").label("Old password"),
  new_password: Yup.string()
    .min(8)
    .max(50)
    .required("Required")
    .label("New password"),
  confirm_password: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("new_password"), null], "Passwords does not match.")
    .label("Confirm password"),
});

export const interestValidate = Yup.object({
  interest_name: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed.")
    .required("Required")
    .label("Interest name"),
});

export const subscriptionValidate = Yup.object({
  name: Yup.string()
    .min(3)
    .max(30)
    .matches(/^[a-zA-Z0-9\s]+$/, "Invalid name")
    .required("Required")
    .label("Package name"),
  price: Yup.number().min(1).required("Required").label("Price"),
  validity: Yup.number().min(1).required("Required").label("Validity"),
});

export const priceValidate = Yup.object({
  price: Yup.number().min(1).max(100).required("Required").label("Price")
});
