import React from 'react'
import Slider from 'react-slick';
import { reviews } from '../../../../Data';
import { TestimonialCard } from './TestimonialCard';

export const Testimonials = () => {
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]    
      };
    return (
        <section className="recent-ads testimonials">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h2 className='regular'>Our <span className="cyan-text semi-bold"> Testimonials</span></h2>
                        {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting book. </p> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Slider {...settings}>
                            {reviews.map(ele=>(
                                <div className='p-4' key={ele.id}>
                                    <TestimonialCard item={ele} />
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    )
}
