import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CChart } from '@coreui/react-chartjs';
import { images } from '../../Assets/'
import { BASE_URL } from '../../config/constants/index.js';
import { ADMIN } from '../../config/constants/admin.js';
import { CustomSelect } from '../../Components/Select';
import { Layout } from '../../Components/Layout/layout.js'
import StatCard from '../../Components/DashboardCards/statCard.js'
import axios from 'axios';

export const Dashboard = () => {
  const token = useSelector(state => state.user.userToken);
  const [stats, setStats] = useState({});
  const [data, setData] = useState([]);
  const [max, setMax] = useState(0);
  const [totalYears, setTotalYears] = useState([]);
  const [year, setYear] = useState("");


  const loadStats = () => {
    let url = BASE_URL + ADMIN.dashboad.get;
    if(year) url += `?year=${year}`;

    let data = axios.get(url, {
      headers: {Authorization: `Bearer ${token}`}
    })
    .then(response => {
      setStats(response.data.data.stats);
      setData(response.data.data.graph);
      setMax(Math.max(...response.data.data.graph));
      setTotalYears(response.data.data.years);
    })
    .catch(err => {
      console.error(err.response);
    })
  }

  useEffect(() => {
    loadStats()
  }, [year])


  return (
    <>
      <Layout>
          <div className="row">
            <div className="col-12">
              <h3>Dashboard</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-11">
              <div className="row">
                <div className="mt-3 col-xl-4 col-md-6">
                  <StatCard imgSrc={images.statIcon1} stats={stats.users} statText="Active Users" />
                </div>
                <div className="mt-3 col-xl-4 col-md-6">
                  <StatCard imgSrc={images.statIcon2} stats={stats.renters} statText="Total Renters" />
                </div>
                <div className="mt-3 col-xl-4 col-md-6">
                  <StatCard imgSrc={images.statIcon3} stats={stats.ads} statText="Active Rented Properties" />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4 pb-5">
            <div className="col-xxl-11">
              <div className="site-card mt-3 py-3 position-relative">
                <div className="text-end">
                  <CustomSelect className="w-140 filter-select" onChange={e => setYear(e.target.value)}>
                    {totalYears.map((item, index) => (
                      <option value={item} key={index}>{item}</option>
                    ))}
                  </CustomSelect>
                </div>
                <h4 className="graph-text">User On The Platform</h4>
                <div className="graph-wrapper">
                  <CChart
                    type="line" 
                    height = "90"
                    options={{
                      scales: {
                        y: {
                            suggestedMin: 0,
                            suggestedMax: (max + 2)
                        }
                      }
                    }}
                    data={{
                      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                      tension: "0.5",
                      datasets: [
                        {
                          label: "Active Users",
                          backgroundColor: "rgba(151, 187, 205, 0.2)",
                          borderColor: "#19D8C5",
                          pointBackgroundColor: "#19D8C5",
                          borderWidth: 3,
                          pointBorderColor: "#19D8C5",
                          // data: [5, 20, 10, 35, 30, 5, 20, 10, 35, 28, 0, 26],
                          data: data,
                          tension: 0.5
                        },
                      ],
                    }}
                  />
                </div>
                <div className="text-center mt-3">
                  <p className='mb-0'>Properties On Rent</p>
                </div>
              </div>
            </div>
          </div>
      </Layout>
    </>
  )
}
