import React from "react";
import { UserLayout } from "../../../Components/UserComponents/UserLayout/userLayout";
import { InnerBanner } from "../../../Components/UserComponents/InnerBanner";
import { termsAndConditions } from "../../../Data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export const TermsAndConditions = () => {
  return (
    <>
      <UserLayout>
        <InnerBanner bannerHeading="Terms &amp;" coloredHeading="Conditions" />
        <section className="my-profile">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div>
                  <p className="semi-bold mt-4">
                    Prioritize reviewing essential rules and guidelines before
                    proceeding with any formal agreements through Roomies and
                    Rentals
                  </p>
                  <p>
                    Conduct crucial legal procedures and ensure verification and
                    authentication from landlords and renters before property
                    possession.
                  </p>
                  <p>
                    Roomies and Rentals shall not be held accountable for any
                    inconvenience arising from fraudulent acts by either party.
                  </p>
                  <p>
                    If a landlord or renter backs out, refunds will be provided
                    accordingly: renters will receive their amounts, and vice
                    versa.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </UserLayout>
    </>
  );
};
