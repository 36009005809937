import React, { useEffect } from "react";
import { UserLayout } from "../../../Components/UserComponents/UserLayout/userLayout";
import SiteButton from "../../../Components/Button/button";
import SiteInput from "../../../Components/Input/input";
import { useNavigate, useParams } from "react-router";
import { useState } from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { subscriptionPackages } from "../../../Data";
import CustomRadio from "../../../Components/CustomRadio";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { CustomSelect } from "../../../Components/Select";
import SiteTextarea from "../../../Components/Input/textarea";
import { images } from "../../../Assets";
import { SiteModal } from "../../../Components/SiteModal";
import { Get } from "../../../config/api/get";

import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { SUBSCRIPTION } from "../../../config/constants";
import { Post } from "../../../config/api/post";

export const RegisterBuySubscription = ({ ele }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const MySwal = withReactContent(Swal);
  const [loading, setLoading] = React.useState(false);
  const [selectedPlan, setSelectedPlan] = React.useState(1);
  const [plans, setPlans] = React.useState([]);
  useEffect(() => {
    getPlans();
  }, [selectedPlan]);
  const getPlans = () => {
    Get(SUBSCRIPTION.subscriptionPlans)
      .then((response) => {
        if (response?.status) {
          setLoading(false);
          setPlans(response.data);
        }
        if (response?.response?.status === 422) {
          setLoading(false);

          MySwal.fire({
            title: "Error!",
            text: response.response.data.message,
            icon: "error",
            confirmButtonColor: "#19D8C5",
            confirmButtonText: "Cancel",
          });
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        MySwal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
          confirmButtonColor: "#19D8C5",
          confirmButtonText: "Cancel",
        });
        setLoading(false);
      });
  };
  const handleSubscriptionChange = (e) => {
    setSelectedPlan(e);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    {
      let data = {
        plan_id: selectedPlan,
        user_id: id,
      };
      Post(SUBSCRIPTION.buySubscription, data)
        .then((response) => {
          // console.log(response.data);
          setLoading(false);

          if (response?.data?.status) {
            window.open(
              response?.data.data.url,
              "_blank",
              "height=600,width=400"
            );
            setTimeout(() => {
              navigate("/user/register/updateInfo/" + id, { replace: true });
            }, 1000);

            console.log("response", response?.data?.message);
          } else {
            MySwal.fire({
              title: "Error!",
              text: response?.data?.message,
              icon: "error",
              confirmButtonColor: "#19D8C5",
              confirmButtonText: "Cancel",
            });
          }
          if (response?.response?.status === 422) {
            setLoading(false);

            MySwal.fire({
              title: "Error!",
              text: response.response.data.message,
              icon: "error",
              confirmButtonColor: "#19D8C5",
              confirmButtonText: "Cancel",
            });
          }
        })
        .catch((e) => {
          console.log(":::;", e);
          MySwal.fire({
            title: "Error!",
            text: "Something went wrong",
            icon: "error",
            confirmButtonColor: "#19D8C5",
            confirmButtonText: "Cancel",
          });
          setLoading(false);
        });
    }
  };

  return (
    <UserLayout noFooter>
      <section className="registration">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="auth-card">
                <div className="text-center">
                  <h3 className="cyan-text bold">Sign Up</h3>
                  <p className="mb-0">Register Your Account</p>
                  <div
                    className="registration-step-form my-3"
                    id="registration-steps"
                  >
                    <span>Sign Up</span>
                    <span className="active">Buy Subscription</span>
                    <span>Create Account</span>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-xxl-9 col-lg-10">
                    <div className="row">
                      <div className="col-12 my-3">
                        <h3 className="mb-0 bold">Select A Package</h3>
                      </div>
                      {plans.map((ele) => (
                        <div className="col-lg-4 col-md-6 my-3" key={ele.id}>
                          <CustomRadio
                            name="subscription"
                            value={ele.id}
                            checked={selectedPlan === ele.id}
                            onChange={() => handleSubscriptionChange(ele.id)}
                          >
                            <div
                              className={`subscription-card ${
                                selectedPlan === ele.id ? "selected-box" : ""
                              }`}
                            >
                              <div className="d-flex mt-4 mb-3 align-items-end">
                                <h3 className="mb-0 bold">${ele.price}</h3>
                                <p className="mb-0">{ele.name}</p>
                              </div>
                              <p>Valid Upto {ele.validity} Days</p>
                            </div>
                          </CustomRadio>
                        </div>
                      ))}
                    </div>
                    <div className="row">
                      <div className="col-md-6 mx-auto my-3">
                        {/* <SiteButton
                          buttonText="Buy Subscription"
                          className="w-100"
                        /> */}
                        <button
                          type="button"
                          onClick={handleSubmit}
                          className="site-btn w-100"
                          disabled={loading ? true : false}
                        >
                          {loading ? "Loading..." : "Buy Subscription"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </UserLayout>
  );
};
