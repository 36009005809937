import React from 'react'
import Pagination from 'react-bootstrap/Pagination';


const TablePagination = (props) => {
    let active = props?.currentPage;
    let items = [];
    for (let number = 1; number <= props.totalPages; number++) {
        items.push(
            <Pagination.Item key={number} active={number === active} onClick={() => props.setCurrentPage(number)}>
                {number}
            </Pagination.Item>,
        );
    }

    const handleChangePage = (active) => {
        props.setCurrentPage(active);
      }

    return (
        <Pagination className='mt-3 mb-0'>
            {props.currentPage > 1 && (
                <Pagination.Prev onClick={() => handleChangePage(props.currentPage - 1)} />
            )}
            
            {items}

            {props.currentPage < props.totalPages && (
                <Pagination.Next onClick={() => handleChangePage(props.currentPage + 1)} />
            )}
        </Pagination>
    )
}
export default TablePagination;