import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../config/constants';
import { ADMIN } from '../../config/constants/admin';
import { BasicTableFilters } from '../../Components/CustomTable/BasicTableFilters';
import { CustomTable } from '../../Components/CustomTable';
import { CustomSelect } from '../../Components/Select';
import { TableFooter } from '../../Components/TableFooter';
import { Layout } from '../../Components/Layout/layout'
import Loader from '../../Components/Loader';
import axios from 'axios';

export const paymentTitles = [
  "S.No",
  "Name",
  "Date",
  "Type",
  "Amount",
]

const PaymentListing = () => {
  const token = useSelector(state => state.user.userToken);
  const [load, setLoad] = useState(true);
  const [paymentLogs, setPaymentLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({records: 5});
  const [status, setStatus] = useState("");

  const loadPaymentLogs = async () => {
    setLoad(true);
    let url = `${BASE_URL + ADMIN.payment_logs.get}?page=${currentPage}`;
    if(filters.records) url += `&records=${filters.records}`; 
    if(filters.from)    url += `&from=${filters.from}`; 
    if(filters.to)      url += `&to=${filters.to}`; 
    if(filters.search)  url += `&search=${filters.search}`; 
    if(filters.status)  url += `&type=${filters.status}`; 

    let data = await axios.get(url, {
      headers: {Authorization: `Bearer ${token}`}
    })
    .then(response => {
      let total_records    = response.data.data.meta.total;
      let records_per_page = response.data.data.meta.per_page;
      let total_pages      = Math.ceil(total_records / records_per_page);
      
      setPaymentLogs(response.data.data.data);
      setCurrentPage(response.data.data.meta.current_page);
      setTotalRecords(total_records);
      setTotalPages(total_pages);
      setLoad(false);
    })
    .catch(err => {
      console.error(err.response);
      setLoad(false)
    })
  }

  useEffect(() => {
    loadPaymentLogs()
  }, [filters, currentPage])

  return (
    <Layout>
      <h3 className="blue-text mb-0">Payment Logs</h3>
      <BasicTableFilters status={status} filters={filters} setFilters={setFilters} setLoad={setLoad}>
        <label htmlFor="status" className="mt-4">Filter By Type</label>
        <CustomSelect id="status" value={status} onChange={e => setStatus(e.target.value)}>
          <option value="" selected>All</option>
          <option value="credits">Credits</option>
          <option value="subscription">Subscription</option>
        </CustomSelect>
      </BasicTableFilters>
      <CustomTable tableTitles={paymentTitles}>
        {load ? (
          <tr>
            <td colSpan="100%"><Loader sm /></td>
          </tr>
        ) : (
          paymentLogs.length > 0 ? (
            paymentLogs.map((item, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>{item.date}</td>
                <td className='text-capitalize'>{item.type}</td>
                <td>${item.amount.toFixed(2)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="100%" className='text-center text-muted'>No record found!</td>
            </tr>
          )
        )}
      </CustomTable>
      <TableFooter 
        showingItem={paymentLogs.length} 
        totalItems={totalRecords} 
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </Layout>

  )
}

export default PaymentListing;
