import React from 'react';

const CustomRadio = ({ name, value, checked, onChange, children }) => {
  return (
    <label className="custom-radio">
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className="radio-input"
      />
      <div className={`radio-label ${checked ? 'checked' : ''}`}>{children}</div>
    </label>
  );
};

export default CustomRadio;