import React, { useEffect } from "react";
import { InnerBanner } from "../../../Components/UserComponents/InnerBanner";
import { UserLayout } from "../../../Components/UserComponents/UserLayout/userLayout";
import { useState } from "react";
import SiteButton from "../../../Components/Button/button";
import { useNavigate } from "react-router";
import { RENTER } from "../../../config/constants";
import { Get } from "../../../config/api/get";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useSelector } from "react-redux";
import { async } from "q";
import { Post } from "../../../config/api/post";

export const BuyCredits = () => {
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);
  const MySwal = withReactContent(Swal);

  const [creditsData, setCreditsData] = useState({
    totalCredits: 0,
    noOfCredits: 0,
    eachCredit: 0,
    totalAmount: 0,
  });
  const [quantity, setQuantity] = useState(1);

  const handleIncrement = () => {
    if (quantity >= 1) {
      setQuantity(quantity + 1);
    }
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    getCredits();
  }, []);

  const buyCredit = async () => {
    console.log(quantity);
    let path = RENTER.buyCredit;
    const data = {
      credit_quantity: quantity,
    };
    Post(path, data, token)
      .then((response) => {
        if (response?.data?.status) {
          setLoading(false);
          window.open(
            response?.data.data.url,
            "_blank",
            "height=600,width=400"
          );
          setTimeout(() => {
            navigate("/renter/interested-users", { replace: true });
          }, 1000);

          // console.log(ad.renter_name);
        } else {
          console.log(response);
          setLoading(false);
          MySwal.fire({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            confirmButtonColor: "#19D8C5",
            confirmButtonText: "Cancel",
          });
          setTimeout(() => {
            navigate("/", { replace: true });
          }, 1000);
        }
        if (response?.response?.status === 422) {
          setLoading(false);
          MySwal.fire({
            title: "Error!",
            text: response.response.data.message,
            icon: "error",
            confirmButtonColor: "#19D8C5",
            confirmButtonText: "Cancel",
          });
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        MySwal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
          confirmButtonColor: "#19D8C5",
          confirmButtonText: "Cancel",
        });
        setLoading(false);
      });
  };
  const getCredits = async () => {
    let path = RENTER.getCredits;
    // console.log(path);
    Get(path, token)
      .then((response) => {
        console.log(response);
        if (response?.status) {
          setLoading(false);
          setCreditsData({
            totalCredits: response.data.credit,
            noOfCredits: response.data.credit,
            eachCredit: response.data.credit_per_price,
            totalAmount: response.data.credit * response.data.credit_per_price,
          });
          // console.log(ad.renter_name);
        }
        if (response?.response?.status === 422) {
          setLoading(false);
          MySwal.fire({
            title: "Error!",
            text: response.response.data.message,
            icon: "error",
            confirmButtonColor: "#19D8C5",
            confirmButtonText: "Cancel",
          });
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        MySwal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
          confirmButtonColor: "#19D8C5",
          confirmButtonText: "Cancel",
        });
        setLoading(false);
      });
  };
  return (
    <UserLayout>
      <InnerBanner
        backButton="false"
        bannerHeading="Buy "
        coloredHeading="Credits"
      />
      <section className="buy-credits">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="p-md l-blue-text semi-bold">
                Each Cedit Can Be Used To Reveal A Single User's Information Who
                Is Interested In Renting Place
              </p>
            </div>
            <div className="col-xxl-11">
              <div className="row">
                <div className="col-12 my-3">
                  <div className="total-credit-card">
                    <h2 className="cyan-text">{creditsData.totalCredits}</h2>
                    <h4 className="semi-bold mt-2 mb-0">Total Credits</h4>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 my-3">
                  <div className="credits-card">
                    <h3 className="semi-bold">{creditsData.noOfCredits}</h3>
                    <p className="p-md l-blue-text mb-0">Number Of Credits</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 my-3">
                  <div className="credits-card">
                    <h3 className="semi-bold">${creditsData.eachCredit}</h3>
                    <p className="p-md l-blue-text mb-0">Each Credit </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 my-3">
                  <div className="credits-card">
                    <h3 className="semi-bold">${creditsData.totalAmount}</h3>
                    <p className="p-md l-blue-text mb-0">Total Amount</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-3">
              <p className="p-md bold">Quantity</p>
              <div className="quantity-wrapper mb-2">
                <button onClick={handleDecrement} disabled={quantity === 1}>
                  -
                </button>
                <input type="text" value={quantity} readOnly />
                <button
                  onClick={handleIncrement}
                  className="increase-quantity-btn"
                >
                  +
                </button>
              </div>
              <SiteButton
                className="mt-4 web-btn"
                onClick={buyCredit}
                buttonText="Buy Credits"
              />
            </div>
          </div>
        </div>
      </section>
    </UserLayout>
  );
};
