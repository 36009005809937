import React from "react";
import { useState } from "react";

import SiteButton from "../../../../Components/Button/button";
import SiteInput from "../../../../Components/Input/input";
import SiteTextarea from "../../../../Components/Input/textarea";
import Lottie from "lottie-react";
import { images } from "../../../../Assets";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Post } from "../../../../config/api/post";
import { CONTACT_US } from "../../../../config/constants";

export const ContactUs = () => {
  const MySwal = withReactContent(Swal);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [validated, setValidated] = useState(false);
  const handleClick = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    setLoading(true);

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setLoading(false);
    } else {
      let data = {
        name: name,
        email: email,
        phone: contactNumber,
        subject: subject,
        message: message,
      };
      Post(CONTACT_US.admin, data)
        .then((response) => {
          // console.log(response.data);
          setLoading(false);
          if (response?.data?.status) {
            MySwal.fire({
              title: "Success!",
              text: response?.data?.message,
              icon: "success",
              confirmButtonColor: "#19D8C5",
              confirmButtonText: "Done",
            });
            setName("");
            setEmail("");
            setContactNumber("");
            setSubject("");
            setMessage("");

            console.log("response", response?.data?.data);
          } else {
            MySwal.fire({
              title: "Error!",
              text: response?.data?.message,
              icon: "error",
              confirmButtonColor: "#19D8C5",
              confirmButtonText: "Cancel",
            });
          }
          if (response?.response?.status === 422) {
            setLoading(false);

            MySwal.fire({
              title: "Error!",
              text: response.response.data.message,
              icon: "error",
              confirmButtonColor: "#19D8C5",
              confirmButtonText: "Cancel",
            });
          }
        })
        .catch((e) => {
          console.log(":::;", e);
          MySwal.fire({
            title: "Error!",
            text: "Something went wrong",
            icon: "error",
            confirmButtonColor: "#19D8C5",
            confirmButtonText: "Cancel",
          });
          setLoading(false);
        });
    }
  };
  return (
    <section className="contact-us py-sm-5 py-4">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12">
            <h2 className="regular mb-0">
              Contact <span className="cyan-text semi-bold"> Us</span>
            </h2>
          </div>
          {/* <div className="col-xxl-8 col-lg-7">
                        <div className="row mt-3">
                            <div className="col-lg-6 my-3">
                                <SiteInput className="web-input" placeholder="First Name" />
                            </div>
                            <div className="col-lg-6 my-3">
                                <SiteInput className="web-input" placeholder="Last Name" />
                            </div>
                            <div className="col-lg-6 my-3">
                                <SiteInput className="web-input" type="email" placeholder="Your Email Address" />
                            </div>
                            <div className="col-lg-6 my-3">
                                <SiteInput className="web-input" type="number" placeholder="Your phone number" />
                            </div>
                            <div className="col-12 my-3">
                                <SiteTextarea rows="5" className="web-input" placeholder="Your Message!" />
                            </div>
                            <div className="col-12 mt-4 mb-3">
                                <SiteButton buttonText="Send Now" className="web-btn" />
                            </div>
                        </div>
                    </div> */}
          <Form
            className="mt-4 px-sm-3"
            noValidate
            validated={validated}
            onSubmit={handleClick}
          >
            <div className="col-xxl-8 col-lg-7">
              <div className="row">
                <div className="col-md-6 my-3">
                  <Form.Group as={Col} controlId="validationCustom01">
                    <Form.Label className="semi-bold mb-2 ms-3">
                      Name*
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      className="site-input"
                      placeholder="Enter Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-md-6 my-3">
                  <Form.Group as={Col} controlId="validationCustom02">
                    <Form.Label className="semi-bold mb-2 ms-3">
                      Email*
                    </Form.Label>
                    <Form.Control
                      required
                      type="email"
                      className="site-input"
                      placeholder="Enter Eamil"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter email.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-md-6 my-3">
                  <Form.Group as={Col} controlId="validationCustom03">
                    <Form.Label className="semi-bold mb-2 ms-3">
                      Contact Number*
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      className="site-input"
                      placeholder="Enter Contact Number"
                      value={contactNumber}
                      onChange={(e) => setContactNumber(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter contact number.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-md-6 my-3">
                  {/* <label htmlFor="" className="semi-bold mb-2 ms-3">
                        Subject*
                      </label>
                      <SiteInput placeholder="Enter Subject" type="text" /> */}
                  <Form.Group as={Col} controlId="validationCustom02">
                    <Form.Label className="semi-bold mb-2 ms-3">
                      Subject*
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      className="site-input"
                      placeholder="Enter Subject"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter subject.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-12 my-3">
                  {/* <label htmlFor="" className="semi-bold mb-2 ms-3">
                        Message*
                      </label>
                      <SiteTextarea placeholder="Type Here..." rows="5" /> */}
                  <Form.Group as={Col} controlId="validationCustom02">
                    <Form.Label className="semi-bold mb-2 ms-3">
                      Message*
                    </Form.Label>
                    <Form.Control
                      required
                      as="textarea"
                      rows={5}
                      className="site-input"
                      placeholder="Enter Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter message.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
            </div>
            <div className="col-12 text-center mt-3 mb-2">
              <button
                type="submit"
                className="site-btn mt-2 web-btn"
                disabled={loading ? true : false}
              >
                {loading ? "Loading..." : "Send"}
              </button>
            </div>
          </Form>
          <div className="col-xxl-4 col-lg-5">
            <div className="d-flex my-3 gap-3 message-icon position-relative">
              <div className="flex-shrink-0">
                <Lottie
                  className="lottie-icon"
                  animationData={images.messageIcon}
                  loop={true}
                />
              </div>
              <div className="flex-grow-1">
                <a href="mailto:support@roomies&rentals.com">
                  support@roomies&rentals.com
                </a>
              </div>
            </div>
            <div className="d-flex my-3 align-items-center gap-3">
              <div className="flex-shrink-0">
                <Lottie
                  className="lottie-icon"
                  animationData={images.chatIcon}
                  loop={true}
                />
              </div>
              <div className="flex-grow-1">
                <a href="mailto:support@roomies&rentals.com">
                  Join us on Telegram
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
