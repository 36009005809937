import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import SiteButton from '../../../Components/Button/button'
import { useNavigate } from 'react-router'

export const SubscriptionCard = (props) => {
    const navigate = useNavigate();
    return (
        <div className='subscription-card'>
            {props.status === 1 ? (
                <button 
                    className="subscription-status-tag subscription-active-tag"
                    onClick={() => {
                        props.setId(props.id);
                        props.setShow(true)
                    }}
                >
                    Active<FontAwesomeIcon icon={faCircle} />
                </button>
            ) : (
                <button 
                    className="subscription-status-tag subscription-inactive-tag"
                    onClick={() => {
                        props.setId(props.id);
                        props.setShow3(true)
                    }}
                >
                    <FontAwesomeIcon icon={faCircle} />Inactive
                </button>
            )}
            <div className="d-flex mt-4 mb-3 align-items-end">
                <h2 className='mb-0'>${props.price}</h2>
                <p className="p-md mb-0">/{props.name}</p>
            </div>
            <p className="p-md">Valid Upto {props.validity} Days</p>
            <SiteButton 
                className="w-100 mt-3" 
                buttonText="Edit Package" 
                onClick={() => navigate(`/admin/edit-subscription-package/${props.id}`)} 
            />
        </div>
    )
}
