import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom"

const GuestRoute = () => {
    let token = useSelector(state => state.user.userToken);
    let auth = { token: Boolean(token) };

    if(auth.token){
        return <Navigate to="/admin/dashboard" />
    }else{
        return <Outlet />;
    }
}

export default GuestRoute