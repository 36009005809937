import React from "react";
import { Accordion } from "react-bootstrap";

export const FAQS = () => {
  const faqItems = [
    {
      id: 1,
      question: "Is My Place Right For Roomies & Rentals?",
      key: "0",
      answer:
        "Assess if your place is suitable for accommodating roommates or rentals based on available space and amenities.",
    },
    {
      id: 2,
      question: "Do I Have To Host All The Time?",
      key: "1",
      answer:
        "Hosting doesn’t require round-the-clock presence; establish clear boundaries and availability.",
    },
    {
      id: 3,
      question: "How Much Should I Interact With Guests?",
      key: "2",
      answer:
        "Strive for a balance in interacting with guests—be friendly and available for assistance, yet respect their privacy.",
    },
    {
      id: 4,
      question: "Any Tips On Being A Great Roomies & Rentals?",
      key: "3",
      answer:
        "To excel in hosting, create a welcoming environment, maintain cleanliness, and ensure effective communication.",
    },
    {
      id: 5,
      question: "What Are Roomies & Rentals Fees?",
      key: "4",
      answer:
        "Fees for roomies and rentals can vary, contingent upon the services, utilities, or amenities you provide.",
    },
  ];
  return (
    <section className="faqs">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3>
              Your Questions <span className="cyan-text bold"> Answered</span>
            </h3>
            <Accordion defaultActiveKey="0" className="mt-4 faq-accordion">
              {faqItems.map((ele) => (
                <Accordion.Item eventKey={ele.key}>
                  <Accordion.Header>{ele.question}</Accordion.Header>
                  <Accordion.Body>{ele.answer}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};
