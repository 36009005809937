import React from 'react'

export const Checkbox = (props) => {
  return (
    <p className="mb-0">
      <input type="checkbox" onChange={props.onChange} checked={props.checked} id={props.id} name={props.name} />
      <label htmlFor={props.for} className={`mb-0${props.className?' ' + props.className:''}`}>{props.labelText}</label>
    </p>
  )
}
