import { Link } from 'react-router-dom'
import { images } from '../../../Assets'

export const NotiDropdownCard = (props) => {
    return (
        <>
            <hr className="grey-line mb-0 mt-2" />
            <Link className={`px-3 mt-3 noti-block ${props.item?.read_at ? '' : 'unread-noti'}`} key={props.item.id}>
                <div className="d-flex">
                    <div className="flex-shrink-0">
                        <img src={images.notiBell} alt="" className="img-fluid noti-icon" />
                    </div>
                    <div className="flex-grow-1 ms-2">
                        <p className="p-xs mb-0">{props.item.data.content} </p>
                        <p className="p-xs semi-bold mb-0">{props.item.time}</p>
                    </div>
                </div>
                <div className="text-end">
                    {!props.item?.read_at && (
                        <button 
                            onClick={() => props.handleRead(props.item?.id)} 
                            className="transparent-btn blue-text underline p-sm bold"
                        >
                            Mark as read
                        </button>
                    )}
                </div>
            </Link>
        </>
    )
}
