import React from 'react'
import { UserLayout } from '../../../Components/UserComponents/UserLayout/userLayout';
import { paymentLogs } from '../../../Data';
import { InnerBanner } from '../../../Components/UserComponents/InnerBanner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faFilter } from '@fortawesome/free-solid-svg-icons';
import { CustomTable } from '../../../Components/CustomTable';
import { CustomSelect } from '../../../Components/Select';
import { Dropdown } from 'react-bootstrap';
import SiteButton from '../../../Components/Button/button';
import { useNavigate } from 'react-router';
import { Checkbox } from '../../../Components/Checkbox';
import SiteInput from '../../../Components/Input/input';


const PaymentLogs = () => {
  const navigate = useNavigate();
  const paymentTitles = [
    "Subscription Type",
    "Subscription Date",
    "Expiry Date",
    "Price",
    "Status",
  ]
  return (
    <UserLayout>
      <InnerBanner bannerHeading="Payment" coloredHeading="Logs" />
      <section className="my-profile payment-logs">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="rent-filters d-sm-flex d-block">
                <div className="flex-grow-1">
                  <div className="d-flex flex-wrap gap-2 align-items-center justify-content-end">
                    <SiteButton buttonText="RENEW PACKAGE" onClick={() => navigate("/make-payment")} className="web-btn mt-3" />
                    <SiteButton buttonText="Subscriptions" className="red-btn mt-3" />
                  </div>
                </div>
                <div className="flex-shrink-0">
                  <Dropdown className='rent-filters-dropdown filters-dropdown mt-3' align="end">
                    <Dropdown.Toggle className="rent-filter-btn" id="dropdown-basic">
                      <FontAwesomeIcon icon={faFilter} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div className="text-center">
                        <p className="p-md mb-0">Filter</p>
                      </div>
                      <hr className='blue-line' />
                      <p className="bold mb-0">Filter By Date:</p>
                      <div className='my-2'>
                        <label htmlFor="" className="bold ms-3">From</label>
                        <SiteInput type="date" className="grey-input with-shadow" />
                      </div>
                      <div className='my-2'>
                        <label htmlFor="" className="bold ms-3">To</label>
                        <SiteInput type="date" className="grey-input with-shadow" />
                      </div>
                      <div className="d-flex flex-wrap align-items-center justify-content-center gap-3">
                        <SiteButton className="mt-3" buttonText="Apply" />
                        <SiteButton className="border-btn mt-3" buttonText="Reset" />
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <CustomTable className="user-table" tableTitles={paymentTitles}>
                {paymentLogs.map(item => (
                  <tr>
                    <td>{item.type}</td>
                    <td>{item.date}</td>
                    <td>{item.expiryDate}</td>
                    <td>${item.price}</td>
                    <td><span className={`table-tag ${item.status == "Active" ? "green-tag" : 'red-tag'}`}><FontAwesomeIcon icon={faCircle} />{item.status}</span></td>
                  </tr>
                ))}
              </CustomTable>
              <div className="mt-1">
                <Checkbox id="renew" name="renewSubscription" for="renew" labelText="Auto - Renew Subscription" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </UserLayout>

  )
}

export default PaymentLogs;
