import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { images } from "../../Assets";
import { Navbar, Container, Nav, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import Notifications from "../../Screens/Notifications";

export const DashboardHeader = (props) => {
  const [data, setData] = useState({});
  const user = useSelector((state) => state.user.userData);

  useEffect(() => {
    setData(user);
  }, [])

  return (
    <>
      <Navbar className="bg-me1" expand="md">
        <Container fluid>
          <Navbar.Brand className="order-2 order-lg-1">
            <Link to="/admin/dashboard">
              <img src={images.logo} alt="" className="img-fluid site-logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="order-4 order-lg-3"
          >
            <FontAwesomeIcon
              className="bell-icon white-text"
              icon={faEllipsisV}
            />
          </Navbar.Toggle>
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="order-3 order-md-3 order-lg-4"
          >
            <Nav className="ms-auto">
              <Notifications />
              <Link className="text-center no-underline" to="/admin/profile">
                <img
                  src={images.userProfilePic}
                  alt=""
                  className="img-fluid profile-pic"
                />
                <br /> <span className="white-text">{data.fullname}</span>
              </Link>
            </Nav>
          </Navbar.Collapse>
          <button className="transparent-btn ms-md-3 order-lg-2 order-md-4 order-1">
            <FontAwesomeIcon
              className="bell-icon white-text"
              onClick={props.sidebarToggle}
              icon={faBars}
            />
          </button>
        </Container>
      </Navbar>
    </>
  );
};
