// ADMIN API END-POINTS

export const ADMIN = {
    auth: {
        login: "/auth/login/admin",
        logout: "/logout",
        forget_password: "/admin/forgot-password",
        verify_code: "/admin/verify-code",
        reset_password: "/admin/reset-password",
    },
    dashboad: {
        get: "/admin/dashboard"
    },
    profile: {
        edit: "/edit-profile",
        change_password: "/change-password",
        change_avatar: "",
    },
    user: {
        get: "/admin/user",
        update_status: "/admin/user/update-status"
    },
    renter: {
        get: "/admin/renter",
        update_status: "/admin/user/update-status"
    },
    interest: {
        get: "/admin/interest",
        add: "/admin/interest/add",
        update_status: "/admin/interest/update-status"
    },
    subscription: {
        get: "/admin/subscription",
        add: "/admin/subscription/add",
        update: "/admin/subscription/update",
        delete: "/admin/subscription/delete",
        update_status: "/admin/subscription/update-status"
    },
    credits: {
        get: "/admin/credit",
        add: "/admin/credit/add"
    },
    ads: {
        get: "/admin/ads",
        update_status: "/admin/ads/update-status"
    },
    payment_logs: {
        get: "/admin/payment-logs"
    },
    feedback: {
        get: "/admin/feedback"
    },
    notification: {
        get: "/notification",
        mark_read: "/mark-as-read"
    }
}
