import React from "react";
import { UserLayout } from "../../../Components/UserComponents/UserLayout/userLayout";
import { InnerBanner } from "../../../Components/UserComponents/InnerBanner";
import { useNavigate, useParams } from "react-router";
import { allRentPlaces } from "../../../Data";

import SiteButton from "../../../Components/Button/button";
import { SiteModal } from "../../../Components/SiteModal";
import { images } from "../../../Assets";
import { useState } from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

import { RENTER } from "../../../config/constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useSelector } from "react-redux";
import { Get } from "../../../config/api/get";

export const InterestedUserDetails = () => {
  const { id } = useParams();
  const MySwal = withReactContent(Swal);
  const token = useSelector((state) => state.user.userToken);
  const [ad, setAd] = useState({
    id: "",
    renter_id: "4",
    renter_name: "test",
    preferred_roommate: "Female",
    address: "Address",
    area: "Address",
    city: "Address",
    additional_details: "Additional",
    status: 1,
    date_posted: "21/11/2023",
    images: [
      {
        "id,": 24,
        ads_id: 37,
        image_name: null,
        image:
          "http://localhost/roomie-and-rental/storage/ads/ads_20231121121734000000.jpg",
      },
    ],
    imterested_users: [
      {
        name: "John Doe",
        email: "abdulbasit@metafroliclabs.com",
        phone: null,
        gender: null,
      },
    ],
    interests: [
      {
        "id,": 1,
        ads_id: 37,
        interest_name: "Tiktok",
      },
      {
        "id,": 2,
        ads_id: 37,
        interest_name: "Cooking",
      },
      {
        "id,": 3,
        ads_id: 37,
        interest_name: "Football",
      },
    ],
  });
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = React.useState(false);

  const handleClose = () => setShow(false);
  const navigate = useNavigate();
  useEffect(() => {
    getInterestedAds();
  }, []);
  const getInterestedAds = async () => {
    let path = RENTER.getInterentedAdDetails + id;
    // console.log(path);
    Get(path, token)
      .then((response) => {
        console.log(response);
        if (response?.status) {
          setAd(response?.data);
          setLoading(false);
          // console.log(ad.renter_name);
        }
        if (response?.response?.status === 422) {
          setLoading(false);
          MySwal.fire({
            title: "Error!",
            text: response.response.data.message,
            icon: "error",
            confirmButtonColor: "#19D8C5",
            confirmButtonText: "Cancel",
          });
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        MySwal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
          confirmButtonColor: "#19D8C5",
          confirmButtonText: "Cancel",
        });
        setLoading(false);
      });
  };
  return (
    <>
      <UserLayout>
        <InnerBanner bannerHeading="Rented " coloredHeading="Place Details" />
        <section className="rent-place-details py-sm-5 py-4">
          <div className="container">
            <div className="row">
              <div className="col-12 my-3">
                <div className="user-apartment-img apartment-details-img">
                  <img
                    src={ad?.images[0]?.image}
                    alt=""
                    className="img-fluid w-100"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-10 col-12">
                <div className="mb-3">
                  <div className="d-flex align-items-end gap-3 flex-wrap">
                    <h3 className="semi-bold mt-3 mb-0">{ad.renter_name}</h3>
                    <p className="mt-3 mb-2 semi-bold cyan-text">
                      {ad.date_posted}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-2 col-sm-4 my-2">
                    <label htmlFor="" className="semi-bold l-blue-text">
                      Renter Name
                    </label>
                    <p className="semi-bold mb-0">{ad.renter_name}</p>
                  </div>
                  <div className="col-xl-2 col-sm-4 my-2">
                    <label htmlFor="" className="semi-bold l-blue-text">
                      Prefferd Roommate
                    </label>
                    <p className="semi-bold mb-0">{ad.preferred_roommate}</p>
                  </div>
                  <div className="col-xl-2 col-sm-4 my-2">
                    <label htmlFor="" className="semi-bold l-blue-text">
                      Interested Users
                    </label>
                    <p className="semi-bold mb-0">
                      {ad.imterested_users.length}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 my-3">
                  <h2 className="regular">
                    Interested <span className="semi-bold"> Users</span>
                  </h2>
                </div>
                {ad.imterested_users
                  ? ad.imterested_users.map((ele) => (
                      <div className="col-lg-4 my-3">
                        <div className="interested-user-card">
                          <h5 className="semi-bold cyan-text">{ele.name}</h5>
                          {/* <p className="l-blue-text">{ele.gender}</p>
                      <p className="l-blue-text">{ele.phone}</p> */}
                          <SiteButton
                            buttonText="View Details"
                            onClick={() =>
                              navigate(
                                `/renter/reveal-user-details/${id}/${ele.id}`
                              )
                            }
                          />
                        </div>
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          </div>
        </section>
      </UserLayout>
      <SiteModal
        show={show}
        handleClose={handleClose}
        modalImg={images.userCheckIcon}
        modalText="Added To Interested Ads, The Renter Will Contact You Shortly"
        normalModal
      />
    </>
  );
};
