import React, { useState, useEffect } from "react";
import { Col, Container, Dropdown, Nav, Navbar, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { images } from "../../../Assets/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { SiteModal } from "../../SiteModal";
import Notifications from "../../../Screens/Notifications";
import { useSelector, useDispatch } from "react-redux";
import { removeUser } from "../../../redux/slice/authSlice";

export const notifications = [
  {
    id: 1,
    heading: "Lorem Ipsum is simply dummy text",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    time: "30 minutes Ago",
  },
  {
    id: 2,
    heading: "Lorem Ipsum is simply dummy text",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    time: "30 minutes Ago",
  },
  {
    id: 3,
    heading: "Lorem Ipsum is simply dummy text",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    time: "30 minutes Ago",
  },
  {
    id: 4,
    heading: "Lorem Ipsum is simply dummy text",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    time: "30 minutes Ago",
  },
  {
    id: 5,
    heading: "Lorem Ipsum is simply dummy text",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    time: "30 minutes Ago",
  },
];

export const SiteHeader = (props) => {
  const user = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let currentLinks;
  const [isAuth, setIsAuth] = useState(false);
  useEffect(() => {
    setIsAuth(isAuthenticated());
  }, []);

  const isAuthenticated = () => {
    const token = user?.role?.role_name;
    return token;
  };

  const logOut = () => {
    dispatch(removeUser());
    setIsAuth(false);
    navigate("/", { replace: true });
  };

  const [show, setShow] = useState(false);
  const handleAction = () => {
    setShow(false);
    logOut();
  };
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleAction2 = () => {
    setShow2(false);
    setShow(true);
  };
  const handleShow2 = () => setShow2(true);

  const navLinks = [
    {
      role: "user",
      links: [
        {
          id: 1,
          name: "Home",
          path: "/",
        },
        {
          id: 2,
          name: "About Us",
          path: "/about-us",
        },
        {
          id: 3,
          name: "Interested Ads",
          path: "/interested-ads",
        },
        {
          id: 4,
          name: "Rent Places",
          path: "/rent-places",
        },
        {
          id: 11,
          name: "Become A Renter",
          path: "/become-a-renter",
        },
        {
          id: 5,
          name: "Contact Admin",
          path: "/contact-admin",
        },
      ],
    },
    {
      role: "renter",
      links: [
        {
          id: 6,
          name: "Home",
          path: "/",
        },
        {
          id: 7,
          name: "About Us",
          path: "/about-us",
        },
        // {
        //     id: 8,
        //     name: 'Interested Ads',
        //     path: '/interested-ads'
        // },
        {
          id: 9,
          name: "Interested Users",
          path: "/renter/interested-users",
        },
        {
          id: 10,
          name: "Listing",
          path: "/renter/add-listing",
        },
        {
          id: 11,
          name: "Contact Admin",
          path: "/contact-admin",
        },
      ],
    },
  ];

  currentLinks = navLinks.find((e) => e.role == isAuthenticated());
  return (
    <>
      <header>
        <Container>
          <Row className="justify-content-center">
            <Col xs="12" className="position-relative">
              <Navbar expand="xl">
                <Navbar.Brand>
                  <Link to="/">
                    <img src={images.logo} className="img-fluid site-logo" />
                  </Link>
                </Navbar.Brand>
                <Navbar.Toggle
                  className="transparent-btn"
                  aria-controls="basic-navbar-nav"
                >
                  <FontAwesomeIcon icon={faBars} />
                </Navbar.Toggle>
                <Navbar.Collapse>
                  {!isAuthenticated() ? (
                    <>
                      <Nav className="ms-auto gap-xl-5 gap-4 align-items-xl-center align-items-start">
                        <Link to="/">Home</Link>
                        <Link to="/about-us">About Us</Link>
                        {/* <Link to="/interested-ads">Interested Ads</Link> */}
                        <Link to="/rent-places">Rent Places</Link>
                        <Link to="/become-a-renter">Become A Renter</Link>
                        <Link to="/contact-admin">Contact Admin</Link>
                        <Dropdown className="signup-drop">
                          <Dropdown.Toggle
                            className="site-btn"
                            id="dropdown-basic"
                          >
                            Signup / Login
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Link to="/user/register">
                              <div className="flex-shrink-0">
                                <span className="signup-drop-circle"></span>
                              </div>
                              <div className="flex-grow-1">
                                <span>Signup As User</span>
                              </div>
                            </Link>
                            <Link to="/renter/register">
                              <div className="flex-shrink-0">
                                <span className="signup-drop-circle"></span>
                              </div>
                              <div className="flex-grow-1">
                                <span>Signup As Renter</span>
                              </div>
                            </Link>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Nav>
                    </>
                  ) : isAuthenticated() ? (
                    <>
                      <Nav className="ms-auto align-items-xl-center gap-xl-5 gap-4 align-items-start">
                        {currentLinks.links.map((link) => (
                          <Link key={link.id} to={link.path}>
                            {link.name}
                          </Link>
                        ))}
                        {isAuthenticated() == "user" ? (
                          <Dropdown
                            drop={"start"}
                            className="header-dropdown signup-drop"
                          >
                            <Dropdown.Toggle id="dropdown-basic">
                              <img
                                src={images.userProfilePic}
                                className="profile-pic img-fluid"
                                alt=""
                              />
                              <span className="mx-2 semi-bold">
                                {user?.first_name + " " + user?.last_name}
                              </span>
                              <FontAwesomeIcon icon={faChevronDown} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Link to="/profile">Profile</Link>
                              <Link onClick={handleShow2}>Logout</Link>
                            </Dropdown.Menu>
                          </Dropdown>
                        ) : isAuthenticated() == "renter" ? (
                          <div className="d-flex align-items-center">
                            <Notifications noTag />
                            <Dropdown
                              drop={"start"}
                              className="header-dropdown signup-drop"
                            >
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={images.userProfilePic}
                                  className="profile-pic img-fluid"
                                  alt=""
                                />
                                <span className="mx-2 semi-bold">
                                  {user?.first_name + " " + user?.last_name}
                                </span>
                                <FontAwesomeIcon icon={faChevronDown} />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Link
                                  to="/profile"
                                  className="d-flex align-items-center gap-2"
                                >
                                  <div className="flex-shrink-0">
                                    <span className="signup-drop-circle"></span>
                                  </div>
                                  <div className="flex-grow-1">
                                    <span>Profile</span>
                                  </div>
                                </Link>
                                <Link
                                  to="/renter/buy-credits"
                                  className="d-flex align-items-center gap-2"
                                >
                                  <div className="flex-shrink-0">
                                    <span className="signup-drop-circle"></span>
                                  </div>
                                  <div className="flex-grow-1">
                                    <span>Buy Credits</span>
                                  </div>
                                </Link>
                                <Link
                                  to="/renter/add-rent-place"
                                  className="d-flex align-items-center gap-2"
                                >
                                  <div className="flex-shrink-0">
                                    <span className="signup-drop-circle"></span>
                                  </div>
                                  <div className="flex-grow-1">
                                    <span>Add New Place</span>
                                  </div>
                                </Link>
                                <Link
                                  onClick={handleShow2}
                                  className="d-flex align-items-center gap-2"
                                >
                                  <div className="flex-shrink-0">
                                    <span className="signup-drop-circle"></span>
                                  </div>
                                  <div className="flex-grow-1">
                                    <span>Logout</span>
                                  </div>
                                </Link>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        ) : (
                          ""
                        )}
                      </Nav>
                    </>
                  ) : (
                    ""
                  )}
                </Navbar.Collapse>
              </Navbar>
            </Col>
          </Row>
        </Container>
        <img
          src={images.bannerbg1}
          alt=""
          className="img-fluid banner-top-left"
        />
      </header>
      <SiteModal
        modalType
        show={show2}
        handleClose={handleClose2}
        modalImg={images.questionImg}
        modalTitle="Logout"
        modalText="Do You Want To Logout?"
        actionMethod={handleAction2}
      />
      <SiteModal
        show={show}
        handleClose={handleAction}
        modalImg={images.userCheckIcon}
        modalTitle="Logout"
        modalText="You Have Logout Successfully!"
        handleAction={handleAction}
        normalModal
      />
    </>
  );
};
