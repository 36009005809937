import "./App.css";
import AdminRouter from "./Routers";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

function App() {
  return <AdminRouter />;
}

export default App;
