export const SITE_NAME = "Roomi Rental";

//Local server Apis
export const BASE_URL = "https://api.roomiesandrental.com/api";
// export const BASE_URL = "https://custom-dev.onlinetestingserver.com/roomie-and-rental/api";
// export const BASE_URL = "http://localhost/roomie-and-rental/api";
// export const UPLOAD_URL = "http://localhost/roomie-and-rental/api/";

//Live client server Apis

export const AUTH = {
  signInUser: "/auth/login/user",
  signInRenter: "/auth/login/renter",
  logout: "/logout",
};

export const SIGNUP = {
  signUpUser: "/signup/user",
  signUpRenter: "/signup/renter",
  completeUserSignUp: "/signup/user/complete",
};

export const SUBSCRIPTION = {
  subscriptionPlans: "/subscription-plans",
  buySubscription: "/buy-subscription",
};

export const CONTACT_US = {
  admin: "/contact-admin",
};

export const RENTER = {
  addAd: "/add-ads",
  updateAd: "/update-ads",
  getInterents: "/get-interests",
  getInterentedAds: "/get-interested-ads",
  getInterentedUserAds: "/get-interested-user-ads",
  getInterentedAdDetails: "/ads-detail/",
  revealInterentedUser: "/reveal-interested-user",
  getCredits: "/get-credit",
  buyCredit: "/buy-credits",
};

export const ADS = {
  getAllAdds: "/search",
  getAllAdsWithoutToken: "/search-all",
  getAllUserAds: "/search-user-ads",
  getRecentAds: "/user/all-recent-ads",
  addToInterested: "/user/add-to-interested",
  getAdDetail: "/user/ads-detail/",
  AdDetail: "/ads-detail/",
};

export const FORGOT_PASSWORD = {
  forgotPassword: "/forgot-password",
  verifyCode: "/verify-code",
  resetPassword: "/reset-password",
  changePassword: "/change-password",
};

export const DASHBOARD = {
  get: "/users/dashboardData",
};

export const USERS = {
  get: "/users/admin",
  getOne: "/users/getById/",
  toggleStatus: "/users/toggleActiveInActive",
};

export const PROFILE = {
  edit: "/edit-profile",
  getOne: "/auth/admin/",
};

export const SERVICE_PROVIDERS = {
  get: "/users/admin/serviceProvider",
  getOne: "/users/getSpById/",
  toggleStatus: "/users/toggleActiveInActive",
};

export const BOOKING_AND_PAYMENT = {
  get: "/booking/getAllBookings",
  getOne: "/booking/bookingDetails/",
  toggleStatus: "/booking/toggleActiveInActive",
};

export const CATEGORIES = {
  get: "/category/GetAllCategoriesNew",
  getOne: "/category/admin/",
  toggleStatus: "/category/toggleActiveInActive",
  edit: "/category/edit/",
  create: "/category/create",
};

export const SERVICES = {
  get: "/service/logs",
  getOne: "/service/getServiceById/",
  toggleStatus: "/service/toggleActiveInActive",
  edit: "/service/edit/",
  create: "/service/create",
  getAllCategories: "/category/getAllCategories",
  serviceType: "/serviceType",
};

export const FEEDBACK = {
  get: "/contact",
  getOne: "/contact/feedbackById/",
};

export const PAYMENT = {
  get: "/payment",
  getOne: "/payment/",
};

export const NOTIFICATION = {
  get: "/notification/getAllAlertsAndNotifications",
  getOne: "/notification/notificationDetail/",
  toggleStatus: "/service/toggleActiveInActive",
  create: "/notification/createAlertOrAnnoucement",
};

export const ARTICLE_CATEGORIES = {
  get: "/articleCategory/getAllArticleCategories",
  getOne: "/articleCategory/getArticleCategoryById/",
  toggleStatus: "/articleCategory/toggleActiveInActive",
  edit: "/articleCategory/updateArticleCategory/",
  create: "/articleCategory/addArticleCategory",
};

export const ARTICLES = {
  get: "/article/getAllArticles",
  getOne: "/article/getArticleById/",
  toggleStatus: "/article/toggleActiveInActive",
  edit: "/article/updateArticle/",
  create: "/article/addArticle/",
  getAllCategories: "/articleCategory/getAllCategoriesLogs/",
};
