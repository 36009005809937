import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faToggleOn,
  faToggleOff,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { images } from "../../Assets";
import { BASE_URL } from "../../config/constants/index.js";
import { ADMIN } from "../../config/constants/admin.js";
import { Layout } from "../../Components/Layout/layout";
import { SiteModal } from "../../Components/SiteModal";
import Loader from "../../Components/Loader/index.js";
import axios from "axios";

export const AdDetails = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const { id } = useParams();
  const [detail, setDetail] = useState({});
  const [adImages, setAdImages] = useState([]);
  const [load, setLoad] = useState(true);
  const [load2, setLoad2] = useState(false);
  const [load3, setLoad3] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);

  const loadAdsDetail = async () => {
    setLoad(true);

    let data = await axios
      .get(BASE_URL + ADMIN.ads.get + `/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response.data.data);
        setDetail(response?.data?.data);
        setAdImages(response?.data?.data?.images);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
        console.error(err.response);
      });
  };

  useEffect(() => {
    loadAdsDetail();
  }, []);

  const activateAds = async () => {
    setLoad3(true);
    let data = await axios
      .post(
        BASE_URL + ADMIN.ads.update_status + `/${id}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        loadAdsDetail();
        setLoad3(false);
        setShow3(false);
        setShow4(true);
      })
      .catch((err) => {
        console.error(err.response.data);
        setLoad3(false);
      });
  };

  const inactivateAds = async () => {
    setLoad2(true);
    let data = await axios
      .post(
        BASE_URL + ADMIN.ads.update_status + `/${id}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        loadAdsDetail();
        setLoad2(false);
        setShow(false);
        setShow2(true);
      })
      .catch((err) => {
        console.error(err.response.data);
        setLoad2(false);
      });
  };

  return (
    <Layout>
      <div className="row">
        <div className="col-12">
          <div className="d-sm-flex align-items-center justify-content-between">
            <h3 className="blue-text mb-0">
              <Link to="/admin/ads" className="blue-text">
                <FontAwesomeIcon className="white-text" icon={faAngleLeft} />
              </Link>{" "}
              Ad Details
            </h3>
            {!load &&
              (detail.status == 1 ? (
                <p className="mb-0 d-flex align-items-center blue-text medium">
                  Active
                  <FontAwesomeIcon
                    onClick={() => setShow(true)}
                    className="green-text cursor-pointer ms-2 p-md"
                    icon={faToggleOn}
                  />
                </p>
              ) : (
                <p className="mb-0 d-flex align-items-center blue-text medium">
                  Inactive
                  <FontAwesomeIcon
                    onClick={() => setShow3(true)}
                    className="red-text cursor-pointer ms-2 p-md"
                    icon={faToggleOff}
                  />
                </p>
              ))}
          </div>
        </div>
        {load ? (
          <Loader />
        ) : (
          <div className="col-xxl-8 col-xl-10 col-12">
            <div className="row">
              {adImages.length > 0 ? (
                <>
                  <div className="col-md-8 my-3">
                    <img
                      src={adImages[0].image}
                      alt=""
                      className="img-fluid ad-bg-img"
                      onClick={() => setShow5(true)}
                    />
                  </div>
                  <div className="col-md-4 my-3">
                    {adImages.map((item, index) =>
                      index === 0 ? (
                        ""
                      ) : index === 1 || index === 2 ? (
                        <img className="img-fluid ad-sm-img" src={item.image} onClick={() => setShow5(true)} />
                      ) : index === 3 ? (
                        <>
                          {adImages.length < 5 ? (
                            <img
                              className="img-fluid ad-sm-img"
                              src={item.image}
                              onClick={() => setShow5(true)}
                            />
                          ) : (
                            <div className="ad-sm-images" onClick={() => setShow5(true)}>
                              <img
                                className="img-fluid ad-sm-img"
                                src={item.image}
                              />
                              <div className="overlay">
                                {adImages.length - 3}+ Photos
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        ""
                      )
                    )}
                  </div>
                </>
              ) : (
                <h4 className="text-muted text-center my-5 text-uppercase">
                  No images to show!
                </h4>
              )}
            </div>

            <div className="row">
              <div className="col-12">
                <div className="d-sm-flex gap-4 align-items-center">
                  <h3 className="mb-0 bold">{detail.appartment_name}</h3>
                  <p className="cyan-text mb-0 mt-sm-0 mt-3">{detail.date}</p>
                </div>
                <p className="mb-0 mt-3">
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    className="cyan-text"
                  />{" "}
                  {`${detail.address}, ${detail.area}, ${detail.city}`}
                </p>
                <div className="d-sm-flex align-items-center mt-3">
                  <div className="flex-shrink-0 w-180">
                    <p className="p-md mb-0 cyan-text">Renter Name:</p>
                  </div>
                  <div className="flex-grow-1">
                    <p className="p-md mb-0">{detail.renter_name}</p>
                  </div>
                </div>
                <div className="d-sm-flex align-items-center mt-3">
                  <div className="flex-shrink-0 w-180">
                    <p className="p-md mb-0 cyan-text">Preferred Roommate:</p>
                  </div>
                  <div className="flex-grow-1">
                    <p className="p-md mb-0 text-capitalize">
                      {detail.preferred_roommate}
                    </p>
                  </div>
                </div>
                <Tabs
                  className="ad-tabs"
                  defaultActiveKey={1}
                  id="uncontrolled-tab-example"
                >
                  <Tab eventKey={1} title="Description">
                    <p className="mt-3 p-md">{detail.additional_details}</p>
                  </Tab>
                  <Tab eventKey={2} title="Interests">
                    <p className="mt-3 p-md">
                      <ul>
                        {detail?.interests?.map((item) => (
                          <li>{item.interest_name}</li>
                        ))}
                      </ul>
                    </p>
                  </Tab>
                  <Tab eventKey={3} title="Features">
                    <p className="mt-3 p-md text-capitalize">
                      <ul>
                        {detail?.apartment_features?.map((item) => (
                          <li>{item.feature}</li>
                        ))}
                      </ul>
                    </p>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        )}
      </div>

      <SiteModal
        show={show}
        modalType="true"
        actionMethod={inactivateAds}
        handleClose={() => setShow(false)}
        btnLoad={load2}
        modalImg={images.questionIcon}
        modalTitle="Mark as Inactive"
        modalText="Do you want to inactivate this ad?"
      />

      <SiteModal
        show={show2}
        handleClose={() => setShow2(false)}
        lottieIcon={images.checkIcon}
        modalTitle="Sucessfully"
        modalText="Ad Inactivated Successfully!"
        normalModal
      />

      <SiteModal
        show={show3}
        modalType="true"
        actionMethod={activateAds}
        btnLoad={load3}
        handleClose={() => setShow3(false)}
        modalImg={images.questionIcon}
        modalTitle="Mark as Active"
        modalText="Do you want to activate this ad?"
      />

      <SiteModal
        show={show4}
        handleClose={() => setShow4(false)}
        lottieIcon={images.checkIcon}
        modalTitle="Sucessfully"
        modalText="Ad Activated Successfully!"
        normalModal
      />

      <SiteModal 
        show={show5} 
        handleClose={() => setShow5(false)} 
        largeModal
      >
        <div id="carouselExampleFade" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            {adImages.map((item, index) => (
              <div className={`carousel-item ${index === 0  && "active"}`} data-interval="false">
                <img src={item.image} className="d-block w-100 h-100" alt="..." />
              </div>
            ))}
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </SiteModal>
    </Layout>
  );
};
