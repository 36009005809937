import React from 'react'

const StatCard = (props) => {
  return (
    <div className='stat-card'>
      <span className="stat-tag">Current Month</span>
      <div className="d-flex align-items-center">
          <div className="flex-shrink-0">
            <div className="stat-card-img">
              <img src={props.imgSrc} alt="" className="img-fluid stat-img" />
            </div>
          </div>
          <div className="flex-grow-1 ms-xxl-4 ms-3">
              <p className="mb-0 stat-text">{props.statText}</p>
              <h3 className="blue-text mb-0">{props.stats}</h3>
          </div>
      </div>
    </div>
  )
}

export default StatCard
