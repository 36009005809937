// ImageUploader.js
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

const ImageUploader = ({ onUpload }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      // Do something with the uploaded files
      onUpload(acceptedFiles);
    },
    [onUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      className={`dropzone ${isDragActive ? "active" : ""}`}
    >
      <input {...getInputProps()} accept="image/*" />
      <p>Upload Images</p>
    </div>
  );
};

export default ImageUploader;
