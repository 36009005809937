import React from 'react'
import TablePagination from './pagination'

export const TableFooter = (props) => {
  return (
    <div className="d-md-flex align-items-center justify-content-between">
        <p className="mb-0 mt-3">Showing {props.showingItem} Out Of {props.totalItems} Entries</p>
        <TablePagination
          currentPage={props?.currentPage}
          setCurrentPage={props?.setCurrentPage}
          totalPages={props?.totalPages}
        />
    </div>
  )
}
