import React from "react";
import SiteButton from "../../Button/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartHolo } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";

export const RentPlacesCard = (props) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userData);
  const isAuthenticated = () => {
    const token = user?.role?.role_name;
    return token;
  };
  const updateInterested = (e) => {
    props.onAddInterested(e);
  };
  return (
    <div className="rent-places-card">
      <div className="rent-place-card-img">
        <img src={props?.item?.images[0]?.image} alt="" className="img-fluid" />
      </div>
      <div className="rent-place-card-inner p-4">
        <div className="d-flex gap-3 align-items-center flex-wrap">
          <div className="flex-shrink-0">
            <p className="p-md mb-0 semi-bold">{props?.item?.appartment_name}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 my-2">
            <label htmlFor="" className="l-blue-text semi-bold">
              Renter Name
            </label>
            <p className="mb-0 semi-bold">{props?.item?.renter_name}</p>
          </div>
          
          <div className="col-sm-6 text-end my-2">
            <p className="semi-bold mb-0 cyan-text">
              {props?.item?.date_posted}
            </p>
          </div>
          <div className="col-sm-6 my-2">
            <label htmlFor="" className="l-blue-text semi-bold">
              Prefferd Roommate
            </label>
            <p className="mb-0 semi-bold">{props?.item?.preferred_roommate}</p>
          </div>
          <div className="col-12 my-2">
            <label htmlFor="" className="l-blue-text semi-bold">
              Address{" "}
            </label>
            {/* <p className="mb-0 semi-bold">saa</p> */}
            <p className="mb-0 semi-bold">{props?.item?.address}, {props?.item?.area}, {props?.item?.city}</p>
          </div>
          {isAuthenticated() === "renter" ? (
            <div className="col-12 my-2">
              <label htmlFor="" className="l-blue-text semi-bold">
                Interested User{" "}
              </label>
              <p className="mb-0 semi-bold">{props?.item?.interestedUser}</p>
            </div>
          ) : (
            ""
          )}
        </div>
        {isAuthenticated() === "renter" ? (
          <div className="d-flex flex-wrap align-items-center gap-2">
            <SiteButton
              className="mt-3 px-3"
              buttonText="View Interested Users"
              onClick={() =>
                navigate(`/renter/interested-user-details/${props?.item?.id}`)
              }
            />
          </div>
        ) : (
          <SiteButton
            className="mt-3 px-3"
            buttonText="View Details"
            onClick={() => navigate(`/rent-place-details/${props?.item?.id}`)}
          />
        )}
      </div>
      {isAuthenticated() === "user" ? (
        <button
          className="wishlist-icon cyan-text"
          onClick={(e) => updateInterested(props.item.id)}
        >
          <FontAwesomeIcon
            icon={props.item.intersted_user === 1 ? faHeart : faHeartHolo}
          />
        </button>
      ) : (
        ""
      )}
    </div>
  );
};
