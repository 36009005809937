import React, { useState } from "react";
import { UserLayout } from "../../../Components/UserComponents/UserLayout/userLayout";
import { InnerBanner } from "../../../Components/UserComponents/InnerBanner";
import SiteButton from "../../../Components/Button/button";
import { SiteModal } from "../../../Components/SiteModal";
import SiteInput from "../../../Components/Input/input";
import { images } from "../../../Assets";
import SiteTextarea from "../../../Components/Input/textarea";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { Post } from "../../../config/api/post";
import { CONTACT_US } from "../../../config/constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

export const ContactAdmin = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userData);
  const isAuthenticated = () => {
    const token = user?.role?.role_name;
    if (token) {
      return token;
    } else {
      return "guest";
    }
  };
  console.log(user);
  const MySwal = withReactContent(Swal);
  const [show, setShow] = useState(false);
  const [name, setName] = useState(user.fullname);
  const [email, setEmail] = useState(user.email);
  const [contactNumber, setContactNumber] = useState(user.phone);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [validated, setValidated] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClick = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    setLoading(true);

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setLoading(false);
    } else {
      let data = {
        name: name,
        email: email,
        phone: contactNumber,
        subject: subject,
        message: message,
        type: isAuthenticated(),
      };
      Post(CONTACT_US.admin, data)
        .then((response) => {
          // console.log(response.data);
          setLoading(false);
          if (response?.data?.status) {
            MySwal.fire({
              title: "Success!",
              text: response?.data?.message,
              icon: "success",
              confirmButtonColor: "#19D8C5",
              confirmButtonText: "Done",
            });
            setName("");
            setEmail("");
            setContactNumber("");
            setSubject("");
            setMessage("");
            navigate("/", { replace: true });
            console.log("response", response?.data?.data);
          } else {
            MySwal.fire({
              title: "Error!",
              text: response?.data?.message,
              icon: "error",
              confirmButtonColor: "#19D8C5",
              confirmButtonText: "Cancel",
            });
          }
          if (response?.response?.status === 422) {
            setLoading(false);

            MySwal.fire({
              title: "Error!",
              text: response.response.data.message,
              icon: "error",
              confirmButtonColor: "#19D8C5",
              confirmButtonText: "Cancel",
            });
          }
        })
        .catch((e) => {
          console.log(":::;", e);
          MySwal.fire({
            title: "Error!",
            text: "Something went wrong",
            icon: "error",
            confirmButtonColor: "#19D8C5",
            confirmButtonText: "Cancel",
          });
          setLoading(false);
        });
    }
  };
  return (
    <>
      <UserLayout>
        <InnerBanner
          backButton="false"
          bannerHeading="Contact"
          coloredHeading="Admin"
        />
        <section className="my-profile">
          <div className="container">
            <div className="row justify-content-center">
              <Form
                className="mt-4 px-sm-3"
                noValidate
                validated={validated}
                onSubmit={handleClick}
              >
                <div className="col-xl-9 col-lg-10">
                  <div className="row">
                    <div className="col-md-6 my-3">
                      <Form.Group as={Col} controlId="validationCustom01">
                        <Form.Label className="semi-bold mb-2 ms-3">
                          Name*
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          className="site-input"
                          placeholder="Enter Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter name.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-md-6 my-3">
                      <Form.Group as={Col} controlId="validationCustom02">
                        <Form.Label className="semi-bold mb-2 ms-3">
                          Email*
                        </Form.Label>
                        <Form.Control
                          required
                          type="email"
                          className="site-input"
                          placeholder="Enter Eamil"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter email.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-md-6 my-3">
                      <Form.Group as={Col} controlId="validationCustom03">
                        <Form.Label className="semi-bold mb-2 ms-3">
                          Contact Number*
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          className="site-input"
                          placeholder="Enter Contact Number"
                          value={contactNumber}
                          onChange={(e) => setContactNumber(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter contact number.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-md-6 my-3">
                      {/* <label htmlFor="" className="semi-bold mb-2 ms-3">
                        Subject*
                      </label>
                      <SiteInput placeholder="Enter Subject" type="text" /> */}
                      <Form.Group as={Col} controlId="validationCustom02">
                        <Form.Label className="semi-bold mb-2 ms-3">
                          Subject*
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          className="site-input"
                          placeholder="Enter Subject"
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter subject.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-12 my-3">
                      {/* <label htmlFor="" className="semi-bold mb-2 ms-3">
                        Message*
                      </label>
                      <SiteTextarea placeholder="Type Here..." rows="5" /> */}
                      <Form.Group as={Col} controlId="validationCustom02">
                        <Form.Label className="semi-bold mb-2 ms-3">
                          Message*
                        </Form.Label>
                        <Form.Control
                          required
                          as="textarea"
                          rows={5}
                          className="site-input"
                          placeholder="Enter Message"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter message.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <div className="col-12 text-center mt-3 mb-2">
                  <button
                    type="submit"
                    className="site-btn mt-2 web-btn"
                    disabled={loading ? true : false}
                  >
                    {loading ? "Loading..." : "Send"}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </section>
      </UserLayout>

      <SiteModal
        show={show}
        handleClose={handleClose}
        modalImg={images.userCheckIcon}
        modalText="Your Message Has Been Submitted."
        normalModal
      />
    </>
  );
};
