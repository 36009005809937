import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const RenterAuthCheck = ({ children }) => {
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const navigate = useNavigate();

  useEffect(() => {
    if (token === null) {
      navigate("/");
    }
    if (token && user) {
      if (user?.role?.role_name !== "renter") {
        navigate("/");
      }
    }
  }, []);

  return <>{children}</>;
};
export default RenterAuthCheck;
