import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

export const RentAdCard = (props) => {
  console.log(props.item);
  return (
    <div className="rent-ad-card">
      <img
        src={props?.item?.images[0]?.image}
        alt=""
        className="img-fluid recent-ad-img"
      />
      <div className="rent-card-overlay">
        <p className="p-md mb-0 semi-bold">{props.item.renter_name}</p>
        {/* <Link
          className="semi-bold cyan-text"
          to={`/rent-place-details/${props.item.id}`}
        >
          View Details
        </Link> */}
      </div>
      {/* <button className="wishlist-icon"><FontAwesomeIcon icon={faHeart} className='cyan-text'/></button> */}
    </div>
  );
};
