import {React, useState, useEffect} from 'react';
import { DashboardHeader } from './header.js';
import { SideBar } from './sideBar.js';

export const Layout = (props) => {
    const [sideBarClass, setsideBarClass] = useState("");
    const [bodyClass, setbodyClass] = useState("");
    useEffect(() => {
        if(window.innerWidth <= 991){
            setsideBarClass("collapsed");
            setbodyClass("expanded");
        }
        else{
            setsideBarClass("");
            setbodyClass("");
        }
        function handleResize(){
            if(window.innerWidth <= 991){
                setsideBarClass("collapsed");
                setbodyClass("expanded");
            }
            else{
                setsideBarClass("");
                setbodyClass("");
            }
        }
        window.addEventListener('resize', handleResize)
    },[]);
    function sidebarToggle(){
        if(sideBarClass==""){
            setsideBarClass("collapsed");
            setbodyClass("expanded");
        }
        else{
            setsideBarClass("");
            setbodyClass("");
        }
    };
    return (
        <>
            <DashboardHeader sidebarToggle={sidebarToggle} />
            <SideBar sideClass={sideBarClass}/>
            <div className={`main-body ${bodyClass}`}>
                <div className="dash-card">
                    <div className="container-fluid">
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    )
}
