import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { images } from "../../Assets";
import { BASE_URL } from "../../config/constants";
import { ADMIN } from "../../config/constants/admin";
import { priceValidate } from "../../config/validations";
import { BasicTableFilters } from "../../Components/CustomTable/BasicTableFilters";
import { Layout } from "../../Components/Layout/layout";
import { CustomTable } from "../../Components/CustomTable";
import { TableFooter } from "../../Components/TableFooter";
import { SiteModal } from "../../Components/SiteModal";
import SiteButton from "../../Components/Button/button";
import SiteInput from "../../Components/Input/input";
import Loader from "../../Components/Loader";
import axios from "axios";

export const creditTitles = ["S.No", "Effective Date", "Amount"];

const CreditFeesListing = () => {
  const token = useSelector(state => state.user.userToken);
  const [load, setLoad] = useState(true);
  const [load2, setLoad2] = useState(false);
  const [credits, setCredits] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({records: 5});
  const [show, setShow] = useState(false);

  const loadCredits = async () => {
    setLoad(true)
    let url = `${BASE_URL + ADMIN.credits.get}?page=${currentPage}`;
    if(filters.records) url += `&records=${filters.records}`; 
    if(filters.from)    url += `&from=${filters.from}`; 
    if(filters.to)      url += `&to=${filters.to}`; 

    let data = await axios.get(url, {
      headers: {Authorization: `Bearer ${token}`}
    })
    .then(response => {
      let total_records    = response.data.data.meta.total;
      let records_per_page = response.data.data.meta.per_page;
      let total_pages      = Math.ceil(total_records / records_per_page);
      
      setCredits(response.data.data.data);
      setCurrentPage(response.data.data.meta.current_page);
      setTotalRecords(total_records);
      setTotalPages(total_pages);
      setLoad(false);
    })
    .catch(err => {
      setLoad(false)
      console.error(err.response);
    })
  }

  useEffect(() => {
    loadCredits()
  }, [filters, currentPage])

  const formik = useFormik({
    initialValues: {price: ""},
    validationSchema: priceValidate,
    onSubmit: (values) => handlePrice(values)
  });

  const handlePrice = async (data) => {
    setLoad2(true)

    let response = await axios.post(
      BASE_URL + ADMIN.credits.add,
      data,
      {headers: {Authorization: `Bearer ${token}`}}
    )
    .then(() => {
      setLoad2(false);
      setShow(true);
      loadCredits();
      formik.handleReset();
    })
    .catch(err => {
      setLoad2(false)
      console.error(err.response);
    })
  }

  return (
    <Layout>
      <div className="row">
        <div className="col-xxl-6 col-xl-8 col-md-10">
          <h3 className="blue-text mb-0">Credit fees Management & Logs</h3>
          <p className="p-md mt-4 mb-2 cyan-text">Fee For Per Credit</p>
          <form onSubmit={formik.handleSubmit} className="mb-3">
            {/* <label className="mb-3">Type to change fees</label> */}
            <div className="d-flex align-items-center">
              <span className="me-2">$</span>
              <SiteInput 
                type="number" 
                id="price"
                className="site-input py-1 w-80"
                placeholder="02"
                value={formik.values.price}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.price && formik.touched.price && (
                <small className="text-danger ms-2">{formik.errors.price}</small>
              )}
            </div>
            <SiteButton
              className="mt-4"
              type="submit"
              buttonText="Save"
              load={load2}
            />
          </form>
          <BasicTableFilters noSearch filters={filters} setFilters={setFilters} setLoad={setLoad} />
          <CustomTable className="small-table" tableTitles={creditTitles}>
            {load ? (
              <tr>
                <td colSpan="100%"><Loader sm /></td>
              </tr>
            ) : (
              credits.length > 0 ? (
                credits.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.date}</td>
                    <td>${item.price.toFixed(2)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="100%" className='text-center text-muted'>No record found!</td>
                </tr>
              )
            )}
          </CustomTable>
          <TableFooter 
            showingItem={credits.length} 
            totalItems={totalRecords} 
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
        </div>
      </div>

      <SiteModal
        show={show}
        handleClose={() => setShow(false)}
        lottieIcon={images.checkIcon}
        modalTitle="Sucessfully"
        modalText="Credit Fees has been changed Successfully!"
        normalModal
      />
    </Layout>
  );
};

export default CreditFeesListing;
