import React from 'react'
import { images } from '../../../../Assets'

export const HomeVideo = () => {
    return (
        <section className="home-video">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="home-video-inner">
                            <video controls poster={images.videoThumbnail} className="w-100">
                                <source src={images.video} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
