import React, { useState } from 'react'
import { UserLayout } from '../../../Components/UserComponents/UserLayout/userLayout'
import { InnerBanner } from '../../../Components/UserComponents/InnerBanner'
import SiteButton from '../../../Components/Button/button'
import { useNavigate } from 'react-router'
import { SiteModal } from '../../../Components/SiteModal'
import SiteInput from '../../../Components/Input/input'
import { images } from '../../../Assets'

export const MakePayment = () => {
  const navigate = useNavigate();
  
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  return (
    <>
      <UserLayout>
        <InnerBanner bannerHeading="Make" coloredHeading="Payment" />
        <section className="my-profile">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-10">
                <div className="row">
                  <div className="col-md-6 my-3">
                    <label htmlFor="" className="semi-bold mb-2 ms-3">Card Title*</label>
                    <SiteInput placeholder="Enter Card Title" />
                  </div>
                  <div className="col-md-6 my-3">
                    <label htmlFor="" className="semi-bold mb-2 ms-3">Card Number*</label>
                    <SiteInput placeholder="Enter Card Number" type="number" />
                  </div>
                  <div className="col-md-6 my-3">
                    <label htmlFor="" className="semi-bold mb-2 ms-3">Expiry Date*</label>
                    <SiteInput type="month" placeholder="Enter Expiry Date" />
                  </div>
                  <div className="col-md-6 my-3">
                    <label htmlFor="" className="semi-bold mb-2 ms-3">CVV Number*</label>
                    <SiteInput placeholder="Enter CVV Number" type="number" />
                  </div>
                </div>
              </div>
              <div className="col-12 text-center mt-3 mb-2">
                <SiteButton className="mt-2 web-btn" onClick={handleShow} buttonText="Make Payment" />
              </div>
            </div>
          </div>
        </section>
      </UserLayout>

      <SiteModal show={show} handleClose={handleClose} modalImg={images.userCheckIcon} modalText="Your Payment Has Been Done Successfully" normalModal />
      <SiteModal show={show2} handleClose={handleClose2} modalImg={images.questionImg} modalText="Your Payment Is Failed" normalModal />
    </>
  )
}
