import React from 'react'
import { UserLayout } from '../../../Components/UserComponents/UserLayout/userLayout'
import { InnerBanner } from '../../../Components/UserComponents/InnerBanner'
import { images } from '../../../Assets'
import SiteButton from '../../../Components/Button/button'
import { useNavigate } from 'react-router'

export const Error404 = () => {
  const navigate = useNavigate();
  return (
    <>
      <UserLayout>
        <InnerBanner bannerHeading="Error" coloredHeading="404" />
        <section className="my-profile">
          <div className="container">
            <div className="col-12 text-center">
              <img src={images.error404} alt="" className="img-fluid" />
              <h2 className='mt-4 mb-3'>Page Not Found</h2>
              <SiteButton onClick={()=>navigate('/')} buttonText="Go To Home" />
            </div>
          </div>
        </section>
      </UserLayout>
    </>
  )
}
