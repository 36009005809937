import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { images } from "../../../Assets";
import SiteInput from "../../../Components/Input/input";
import { useNavigate } from "react-router";
import { useState } from "react";
import {
  faArrowLeftLong,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "../../../Components/Checkbox";
import { Link } from "react-router-dom";
import SiteButton from "../../../Components/Button/button";
import { SiteModal } from "../../../Components/SiteModal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { Post } from "../../../config/api/post";
import { FORGOT_PASSWORD } from "../../../config/constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const ForgetPassword = () => {
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const MySwal = withReactContent(Swal);

  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    navigate("/user/login");
  };
  React.useEffect(() => {
    if (user && token) {
      navigate("/", { replace: true });
    }
  }, [user, token]);

  const navigate = useNavigate();

  const [step, setStep] = useState(1);

  const handleClick = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    setValidated(true);
    setLoading(true);

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setLoading(false);
    } else {
      let data = {
        email: email,
      };
      Post(FORGOT_PASSWORD.forgotPassword, data)
        .then((response) => {
          setLoading(false);
          if (response?.data?.status) {
            localStorage.setItem("forget_password_email", email);
            navigate("/verification-code");
            console.log("response", response.data.data);
          } else {
            MySwal.fire({
              title: "Error!",
              text: response?.data?.message,
              icon: "error",
              confirmButtonColor: "#19D8C5",
              confirmButtonText: "Cancel",
            });
          }
          if (response?.response?.status === 422) {
            setLoading(false);

            MySwal.fire({
              title: "Error!",
              text: response.response.data.message,
              icon: "error",
              confirmButtonColor: "#19D8C5",
              confirmButtonText: "Cancel",
            });
          }
        })
        .catch((e) => {
          console.log(":::;", e);
          MySwal.fire({
            title: "Error!",
            text: "Something went wrong",
            icon: "error",
            confirmButtonColor: "#19D8C5",
            confirmButtonText: "Cancel",
          });
          setLoading(false);
        });
    }
  };

  const renderSteps = () => {
    return (
      <>
        <div className="mt-4 text-center">
          <h3 className="cyan-text bold">Forgot Password</h3>
          <p className="grey-text">
            Enter your email address to receive a verification code
          </p>
        </div>
        <Form
          className="mt-4"
          noValidate
          validated={validated}
          onSubmit={handleClick}
        >
          <Form.Group as={Col} controlId="validationCustom01">
            <Form.Label className="ms-3 bold">Email Address*</Form.Label>
            <Form.Control
              required
              type="email"
              className="grey-input with-shadow"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please enter email.
            </Form.Control.Feedback>
          </Form.Group>
          <div className="mt-3 text-center">
            <button
              type="submit"
              className="site-btn mt-3"
              disabled={loading ? true : false}
            >
              {loading ? "Loading..." : "Continue"}
            </button>
          </div>
        </Form>
      </>
    );
  };
  return (
    <>
      <section className="user-login">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 login-left d-lg-block d-none"></div>
            <div className="col-lg-6 px-lg-0">
              <div className="login-right">
                <div className="text-center">
                  <img src={images.loginLogo} alt="" className="img-fluid" />
                </div>
                {renderSteps()}
                <div className="text-center">
                  <Link
                    to="/user/login"
                    className="d-inline-flex mt-4 d-grey-text semi-bold align-items-center gap-1 justify-content-center"
                  >
                    <FontAwesomeIcon
                      icon={faArrowLeftLong}
                      className="cyan-text"
                    />
                    Back To Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SiteModal
        show={show}
        handleClose={handleClose}
        modalImg={images.userCheckIcon}
        modalTitle="Sucessfully"
        modalText="Your Password Has Been Reset. Please Login To Continue."
        normalModal
      />
    </>
  );
};
