import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { SubscriptionCard } from "./SubscriptionCard";
import { images } from "../../Assets";
import { BASE_URL } from "../../config/constants";
import { ADMIN } from "../../config/constants/admin";
import { Layout } from "../../Components/Layout/layout";
import { SiteModal } from "../../Components/SiteModal";
import SiteButton from "../../Components/Button/button";
import Loader from "../../Components/Loader";
import axios from "axios";

const SubscriptionListing = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const [subscriptions, setSubscriptions] = useState([]);
  const [id, setId] = useState("");
  const [load, setLoad] = useState(true);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);

  const loadSubscriptionPlan = async () => {
    setLoad(true);

    let data = await axios
      .get(BASE_URL + ADMIN.subscription.get, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setLoad(false);
        setSubscriptions(response.data.data);
      })
      .catch((err) => {
        setLoad(false);
        console.error(err.response);
      });
  };

  useEffect(() => {
    loadSubscriptionPlan();
  }, []);

  const activateSubscription = async () => {
    let data = await axios.post(BASE_URL + ADMIN.subscription.update_status + `/${id}`, {}, {
      headers: {Authorization: `Bearer ${token}`}
    })
    .then(() => {
      setShow3(false);
      setShow4(true);
      loadSubscriptionPlan();
    })
    .catch(err => {
      console.error(err.response.data);
    })
  }

  const inactivateSubscription = async () => {
    let data = await axios.post(BASE_URL + ADMIN.subscription.update_status + `/${id}`, {}, {
      headers: {Authorization: `Bearer ${token}`}
    })
    .then(() => {
      setShow(false);
      setShow2(true);
      loadSubscriptionPlan();
    })
    .catch(err => {
      console.error(err.response.data);
    })
  }

  return (
    <>
      <Layout>
        <h3 className="blue-text mb-0">Subscription Management</h3>
        <div className="row">
          <div className="col-xxl-11">
            <div className="d-sm-flex mb-3 align-items-center justify-content-between">
              <div></div>
              {!load && (
              <SiteButton
                className="mt-3"
                buttonText="Add New Package"
                onClick={() => navigate("/admin/create-subscription-package")}
              />
              )}
            </div>
            <div className="row">
              {load ? (
                <Loader />
              ) : (
                subscriptions.length > 0 ? (
                  subscriptions.map((item) => (
                    <div className="col-xl-4 col-sm-6 my-3">
                      <SubscriptionCard
                        id={item.id}
                        name={item.name}
                        price={item.price}
                        validity={item.validity}
                        status={item.status}
                        setShow={setShow}
                        setShow3={setShow3}
                        setId={setId}
                      />
                    </div>
                  ))
                ) : (
                  <h3 className="text-muted text-center mt-3">No subscription found.</h3>
                )
              )}
            </div>
          </div>
        </div>
      </Layout>

      <SiteModal
        show={show}
        modalType="true"
        actionMethod={inactivateSubscription}
        handleClose={() => setShow(false)}
        modalImg={images.questionIcon}
        modalTitle="Mark as Inactive?"
        modalText="Do you want to inactivate this subscription plan?"
      />

      <SiteModal
        show={show2}
        handleClose={() => setShow2(false)}
        lottieIcon={images.checkIcon}
        modalTitle="Sucessfully"
        modalText="Subscription Plan Inactivated Successfully!"
        normalModal
      />

      <SiteModal
        show={show3}
        modalType="true"
        actionMethod={activateSubscription}
        handleClose={() => setShow3(false)}
        modalImg={images.questionIcon}
        modalTitle="Mark as Active"
        modalText="Do you want to activate this subscription plan?"
      />

      <SiteModal
        show={show4}
        handleClose={() => setShow4(false)}
        lottieIcon={images.checkIcon}
        modalTitle="Sucessfully"
        modalText="Subscription Plan Activated Successfully!"
        normalModal
      />
    </>
  );
};

export default SubscriptionListing;
