import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Layout } from '../../Components/Layout/layout'
import { BASE_URL } from '../../config/constants/index.js';
import { ADMIN } from '../../config/constants/admin.js';
import Loader from '../../Components/Loader/index.js';
import axios from 'axios';

export const FeedbackDetails = () => {
  const { id } = useParams();
  const token = useSelector(state => state.user.userToken);
  const [load, setLoad] = useState(true);
  const [feedback, setFeedback] = useState({});

  const loadFeedbackDetail = async () => {
    setLoad(true)

    let data = await axios.get(
      BASE_URL + ADMIN.feedback.get + `/${id}`,
      {
        headers: {Authorization: `Bearer ${token}`}
      }
    )
    .then(response => {
      setLoad(false)
      setFeedback(response.data.data);
    })
    .catch(err => {
      setLoad(false)
      console.error(err.response);
    });
  }

  useEffect(() => {
    loadFeedbackDetail()
  }, [])

  return (
    <Layout>
      <div className="row">
        <div className="col-12">
          <h3 className='blue-text mb-0'>
            <Link to="/admin/feedback" className='blue-text'>
              <FontAwesomeIcon className='white-text' icon={faAngleLeft} />
            </Link> Feedback Details</h3>
        </div>
        {load ? (
          <Loader />
        ) : (
          <div className="mt-3 col-xxl-8 col-lg-10">
            <div className="row">
              <div className="col-12 my-3">
                <p className="mb-0 medium">Name:</p>
                <p className="mb-0 m-grey-text">{feedback.name}</p>
              </div>
              <div className="col-12 my-3">
                <p className="mb-0 medium">Contact:</p>
                <p className="mb-0 m-grey-text">{feedback.phone}</p>
              </div>
              <div className="col-12 my-3">
                <p className="mb-0 medium">Email:</p>
                <p className="mb-0 m-grey-text">{feedback.email}</p>
              </div>
              <div className="col-12 my-3">
                <p className="mb-0 medium">Subject:</p>
                <p className="mb-0 m-grey-text">{feedback.subject}</p>
              </div>
              <div className="col-12 my-3">
                <p className="mb-0 medium">Type:</p>
                <p className="mb-0 m-grey-text text-capitalize">{feedback.type}</p>
              </div>
              <div className="col-12 my-3">
                <p className="mb-0 medium">Message:</p>
                <p className="mb-0 m-grey-text">{feedback.message}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}
