import './index.css'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { images } from '../../Assets'
import { Layout } from '../../Components/Layout/layout'
import SiteButton from '../../Components/Button/button'

export const AdminProfile = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState({});
  const user = useSelector((state) => state.user.userData);

  useEffect(() => {
    setProfile(user);
  }, []);

  return (
    <Layout>
        <div className="row">
            <div className="col-12">
                <h3 className="blue-text">My Profile</h3>
                <div className="admin-img mt-3">
                    <img src={images.userProfilePic} alt="" className="img-fluid" />
                </div>
                <div className="row">
                    <div className="col-xxl-4 col-xl-6 col-md-8 col-sm-10 col-12">
                        <div className="row mt-3">
                            <div className="col-sm-6 my-3">
                                <p className="mb-0 p-md">Full Name</p>
                                <p className="vl-grey-text mb-0">{profile.fullname}</p>
                            </div>
                            <div className="col-sm-6 my-3">
                                <p className="mb-0 p-md">Email</p>
                                <p className="vl-grey-text mb-0">{profile.email}</p>
                            </div>
                            <div className="col-sm-6 my-3">
                                <p className="mb-0 p-md">Contact</p>
                                <p className="vl-grey-text mb-0">{profile.phone}</p>
                            </div>
                            <div className="col-sm-6 my-3">
                                <p className="mb-0 p-md">Date Of Birth</p>
                                <p className="vl-grey-text mb-0">{profile.date_of_birth}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap align-items-center gap-3">
                    <SiteButton 
                        buttonText="Edit Profile" 
                        className="mt-4" 
                        onClick={() => navigate('/admin/edit-profile')} 
                    />
                    <SiteButton 
                        buttonText="Change Password" 
                        className="mt-4 border-btn" 
                        onClick={() => navigate('/admin/change-password')} 
                    />
                </div>
            </div>
        </div>
    </Layout>
  )
}
