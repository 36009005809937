import './index.css'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { images } from '../../Assets'
import { passwordValidate } from '../../config/validations'
import { ADMIN } from '../../config/constants/admin'
import { BASE_URL } from '../../config/constants'
import { Layout } from '../../Components/Layout/layout'
import { SiteModal } from '../../Components/SiteModal'
import SiteButton from '../../Components/Button/button'
import SiteInput from '../../Components/Input/input'
import axios from 'axios'

export const ChangePassword = () => {
    const navigate = useNavigate();
    const token = useSelector(state => state.user.userToken);
    const [show, setShow] = useState(false);
    const [load, setLoad] = useState(false);
    const [eyeIcon, setEyeIcon] = useState(faEyeSlash);
    const [eyeIcon2, setEyeIcon2] = useState(faEyeSlash);
    const [eyeIcon3, setEyeIcon3] = useState(faEyeSlash);
    const [passwordType, setPasswordType] = useState(0);
    const [passwordType2, setPasswordType2] = useState(0);
    const [passwordType3, setPasswordType3] = useState(0);
    const [formData, setFormData] = useState({
        old_password: "",
        new_password: "",
        confirm_password: ""
    });

    const toggleIcon = () => {
        if(eyeIcon == faEyeSlash){
            setEyeIcon(faEye)
            setPasswordType(1);
        }
        else{
            setEyeIcon(faEyeSlash);
            setPasswordType(0);
        }
    }
    const toggleIcon2 = () => {
        if(eyeIcon2 == faEyeSlash){
            setEyeIcon2(faEye)
            setPasswordType2(1);
        }
        else{
            setEyeIcon2(faEyeSlash);
            setPasswordType2(0);
        }
    }
    const toggleIcon3 = () => {
        if(eyeIcon3 == faEyeSlash){
            setEyeIcon3(faEye)
            setPasswordType3(1);
        }
        else{
            setEyeIcon3(faEyeSlash);
            setPasswordType3(0);
        }
    }

    const {values, errors, touched, handleChange, handleBlur, handleSubmit} = useFormik({
        initialValues: formData,
        validationSchema: passwordValidate,
        onSubmit: (values) => handleFormSubmit(values)
    });

    const handleFormSubmit = async (data) => {
        setLoad(true);

        let response = await axios.post(
            BASE_URL + ADMIN.profile.change_password,
            data,
            {headers: {Authorization: `Bearer ${token}`}}
        )
        .then(() => {
            setLoad(false);
            setShow(true);
        })
        .catch(err => {
            document.getElementById('error').innerHTML = err.response.data.message;
            setLoad(false);
        })
    }

    const modalAction = () => {
        setShow(false);
        navigate("/admin/profile");
    }
    
    return (
        <>
            <Layout>
                <div className="row">
                    <div className="col-12">
                        <h3 className='white-text'><Link to="/admin/profile" className='white-text'><FontAwesomeIcon icon={faAngleLeft} /></Link> Change Password</h3>
                        <form onSubmit={handleSubmit}>
                            <div className="row mt-3">
                                <div className="col-xxl-4 col-xl-6 col-md-8 my-3">
                                    <label className="mb-1">Current Password</label>
                                    <div className="position-relative">
                                        <SiteInput 
                                            type={passwordType == 0 ? "password" : "text"}
                                            id="old_password"
                                            placeholder="Enter Current Password" 
                                            value={values.old_password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <FontAwesomeIcon className='right-icon' onClick={toggleIcon} icon={eyeIcon} />
                                    </div>
                                    {errors.old_password && touched.old_password ? (
                                        <small className='text-danger ms-1'>{errors.old_password}</small>
                                    ) : (
                                        <div className='text-danger ms-1' id='error'></div>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xxl-4 col-xl-6 col-md-8 my-3">
                                    <label className="mb-1">New Password</label>
                                    <div className="position-relative">
                                        <SiteInput 
                                            type={passwordType2 == 0 ? "password" : "text"} 
                                            id="new_password"
                                            placeholder="Enter New Password"
                                            value={values.new_password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <FontAwesomeIcon className='right-icon' onClick={toggleIcon2} icon={eyeIcon2} />
                                    </div>
                                    {errors.new_password && touched.new_password && (
                                        <small className='text-danger ms-1'>{errors.new_password}</small>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xxl-4 col-xl-6 col-md-8 my-3">
                                    <label className="mb-1">Confirm Password</label>
                                    <div className="position-relative">
                                        <SiteInput 
                                            type={passwordType3 == 0 ? "password" : "text"} 
                                            id="confirm_password"
                                            placeholder="Enter Confirm Password"
                                            value={values.confirm_password}
                                            onChange={handleChange}
                                            onBlur={handleBlur} 
                                        />
                                        <FontAwesomeIcon className='right-icon' onClick={toggleIcon3} icon={eyeIcon3} />
                                    </div>
                                    {errors.confirm_password && touched.confirm_password && (
                                        <small className='text-danger ms-1'>{errors.confirm_password}</small>
                                    )}
                                </div>
                            </div>
                            <SiteButton type="submit" buttonText="Update" className="mt-4" load={load} />
                        </form>
                    </div>
                </div>
            </Layout>

            <SiteModal
                show={show} 
                handleClose={modalAction}
                lottieIcon={images.checkIcon}
                modalTitle="Successfully"
                modalText="Password Updated Successfully!"
                normalModal
            />
        </>
    )
}
