import React from "react";
import { UserLayout } from "../../../Components/UserComponents/UserLayout/userLayout";
import { InnerBanner } from "../../../Components/UserComponents/InnerBanner";
import { useNavigate, useParams } from "react-router";
import { allRentPlaces } from "../../../Data";

import SiteButton from "../../../Components/Button/button";
import { SiteModal } from "../../../Components/SiteModal";
import { images } from "../../../Assets";
import { useState } from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

import { ADS, RENTER } from "../../../config/constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useSelector } from "react-redux";
import { Get } from "../../../config/api/get";
import Loader from "../../../Components/Loader";
import SyncedSlider2 from "../../UserScreens/RentPlaceDetails/PlaceDetailSlider/SyncedSlider2";

export const ViewAdd = () => {
  const { id } = useParams();
  const MySwal = withReactContent(Swal);
  const token = useSelector((state) => state.user.userToken);
  const [ad, setAd] = useState({
    id: "",
    renter_id: "4",
    renter_name: "test",
    preferred_roommate: "Female",
    address: "Address",
    area: "Address",
    city: "Address",
    additional_details: "Additional",
    status: 1,
    date_posted: "21/11/2023",
    images: [
      {
        "id,": 24,
        ads_id: 37,
        image_name: null,
        image:
          "http://localhost/roomie-and-rental/storage/ads/ads_202311211217340000000.jpg",
      },
      {
        "id,": 25,
        ads_id: 37,
        image_name: null,
        image:
          "http://localhost/roomie-and-rental/storage/ads/ads_202311211217340000000.jpg",
      },
    ],
    interested_users: [
      {
        name: "John Doe",
        email: "abdulbasit@metafroliclabs.com",
        phone: null,
        gender: null,
      },
    ],
    apartment_features: [
      {
        "id,": 55,
        ads_id: 37,
        feature: "Kitchen",
      },
      {
        "id,": 56,
        ads_id: 37,
        feature: "Air Condition",
      },
      {
        "id,": 57,
        ads_id: 37,
        feature: "Washing Machine",
      },
      {
        "id,": 58,
        ads_id: 37,
        feature: "Street View",
      },
      {
        "id,": 59,
        ads_id: 37,
        feature: "Bathroom",
      },
      {
        "id,": 60,
        ads_id: 37,
        feature: "Air Condition",
      },
    ],
    interests: [
      {
        "id,": 1,
        ads_id: 37,
        interest_name: "Tiktok",
      },
      {
        "id,": 2,
        ads_id: 37,
        interest_name: "Cooking",
      },
      {
        "id,": 3,
        ads_id: 37,
        interest_name: "Football",
      },
    ],
  });
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = React.useState(false);
  const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    return token;
  };
  const navigate = useNavigate();
  const getInterestedAds = async () => {
    setLoading(true);
    let path = ADS.AdDetail + id;
    console.log(path);
    Get(path, token)
      .then((response) => {
        console.log(response);
        if (response?.status) {
          setAd(response?.data);
          setLoading(false);
          // console.log(ad.renter_name);
        }
        if (response?.response?.status === 422) {
          setLoading(false);
          MySwal.fire({
            title: "Error!",
            text: response.response.data.message,
            icon: "error",
            confirmButtonColor: "#19D8C5",
            confirmButtonText: "Cancel",
          });
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        MySwal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
          confirmButtonColor: "#19D8C5",
          confirmButtonText: "Cancel",
        });
        setLoading(false);
      });
  };
  useEffect(() => {
    getInterestedAds();
  }, []);
  return (
    <>
      <UserLayout>
        <InnerBanner bannerHeading="Ad" coloredHeading=" Details" />

        {loading ? (
          <Loader />
        ) : (
          <section className="rent-place-details py-sm-5 py-4">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 position-relative my-3">
                  <SyncedSlider2 mainImages={ad.images} />
                </div>
                <div className="col-lg-6 my-3 ps-lg-5">
                  <div className="d-flex align-items-center flex-wrap-reverse gap-3">
                    <div className="flex-grow-1">
                      <h3 className="regular mb-2">
                        Apartment{" "}
                        <span className="cyan-text semi-bold"> Features</span>
                      </h3>
                    </div>
                    <div className="flex-shrink-0">
                      {ad.status === 1 ? (
                        <button
                          type="button"
                          className="rent-details-tag rent-active"
                        >
                          <span>
                            <FontAwesomeIcon icon={faCircle} />
                          </span>{" "}
                          Active
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="rent-details-tag rent-inactive"
                        >
                          <span>
                            <FontAwesomeIcon icon={faCircle} />
                          </span>{" "}
                          InActive
                        </button>
                      )}
                    </div>
                    {/* <div className="flex-shrink-0">
                    {isAuthenticated() == "renter" ?
                      ad.status == "Active" ? <button type='button' className='rent-details-tag rent-active'><span><FontAwesomeIcon icon={faCircle} /></span> {ad.status}</button> : <button type='button' className='rent-details-tag rent-inactive'><span><FontAwesomeIcon icon={faCircle} /></span> {ad.status}</button>
                      : <SiteButton onClick={handleShow} buttonText="Interested" className="mb-2 web-btn" />
                    }
                  </div> */}
                  </div>
                  <ul className="about-list">
                    {ad.apartment_features.map((item) => (
                      <li className="mt-3">
                        <div className="d-flex align-items-center gap-3">
                          <div className="flex-shrink-0">
                            <span className="list-icon"></span>
                          </div>
                          <div className="flex-grow-1">
                            <p className="l-blue-text mb-0">
                              {item?.feature} - <span>{item?.quantity}</span>
                            </p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-xxl-10 col-12">
                  <div className="my-3">
                    <h3 className="regular mb-0">Renter Contact Details</h3>
                  </div>
                  <div className="row">
                    <div className="col-xl-2 col-sm-4 my-2">
                      <label htmlFor="" className="semi-bold l-blue-text">
                        Renter Name
                      </label>
                      <p className="semi-bold mb-0">{ad.renter_name}</p>
                    </div>
                    <div className="col-xl-2 col-sm-4 my-2">
                      <label htmlFor="" className="semi-bold l-blue-text">
                        Prefferd Roommate
                      </label>
                      <p className="semi-bold mb-0">{ad.preferred_roommate}</p>
                    </div>
                    <div className="col-xl-2 col-sm-4 my-2">
                      <label htmlFor="" className="semi-bold l-blue-text">
                        Posted On
                      </label>
                      <p className="semi-bold mb-0">{ad.date_posted}</p>
                    </div>
                    <div className="col-xl-6 col-12 my-2">
                      <label htmlFor="" className="semi-bold l-blue-text">
                        Address{" "}
                      </label>
                      <p className="semi-bold mb-0">{ad.address}, {ad.area}, {ad.city}</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xxl-9 col-xl-10 col-lg-11 col-12 my-2">
                    <label htmlFor="" className="semi-bold l-blue-text">
                      Additional Details{" "}
                    </label>
                    <p className="semi-bold mb-0">{ad?.additional_details}</p>
                  </div>
                  <div className="col-12 my-2">
                    <label htmlFor="" className="semi-bold l-blue-text">
                      Interests
                    </label>
                    <div className="d-flex align-items-center flex-wrap gap-3">
                      {ad.interests.map((ele) => (
                        <span className="interest-tag mt-2">
                          {ele?.interest_name}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="col-12">
                    <SiteButton
                      buttonText="Edit"
                      type="button"
                      onClick={() => navigate(`/edit-rent-place/${ad.id}`)}
                      className="web-btn"
                    />
                  </div>
                  {/* {isAuthenticated() == "renter" ? (
                  <div className="col-12 my-4">
                    <SiteButton
                      buttonText="Edit Details"
                      onClick={() =>
                        navigate(`/edit-rent-place/${ad.id}`)
                      }
                      className="web-btn"
                    />
                  </div>
                ) : (
                  ""
                )} */}
                </div>
              </div>
            </div>
          </section>
        )}
      </UserLayout>
    </>
  );
};
