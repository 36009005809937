import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SyncedSlider2 = ({ mainImages }) => {
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  return (
    <>
      <Slider asNavFor={slider2} ref={(slider) => setSlider1(slider)}>
        {mainImages.map((imageUrl) => {
          return (
            <div className="product-detail-img">
              <img
                className="rounded-0"
                key={imageUrl.id}
                src={imageUrl.image}
                alt={imageUrl.image_name}
              />
            </div>
          );
        })}
      </Slider>
      {mainImages.length > 1 ? (
        <Slider
          asNavFor={slider1}
          className="image-carousel-secondary"
          focusOnSelect={true}
          ref={(slider) => setSlider2(slider)}
          slidesToShow={3}
          swipeToSlide={true}
        >
          {mainImages.map((imageUrl) => {
            return (
              <img
                key={imageUrl.id}
                src={imageUrl.image}
                alt={imageUrl.image_name}
                className="product-details-thumbnail-img"
              />
            );
          })}
        </Slider>
      ) : null}
    </>
  );
};

export default SyncedSlider2;
