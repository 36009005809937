import './index.css'
import "react-intl-tel-input/dist/main.css";
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faPencil } from '@fortawesome/free-solid-svg-icons'
import { images } from '../../Assets'
import { BASE_URL } from '../../config/constants'
import { ADMIN } from '../../config/constants/admin'
import { updateUserData } from '../../redux/slice/authSlice'
import { profileValidate } from '../../config/validations'
import { Layout } from '../../Components/Layout/layout'
import { SiteModal } from '../../Components/SiteModal'
import SiteButton from '../../Components/Button/button'
import SiteInput from '../../Components/Input/input'
import IntlTelInput from "react-intl-tel-input";
import axios from 'axios'

export const EditProfile = () => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.userData);
    const token = useSelector((state) => state.user.userToken);
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [load, setLoad] = useState(false);
    const [load2, setLoad2] = useState(false);
    const [formData, setFormData] = useState({}) 
    const [profileData, setProfileData] = useState({});
    const [profilePic, setProfilePic] = useState(images.userProfilePic);

    useEffect(() => {
        setProfileData(user);
        setFormData({
            first_name: user.fullname,
            phone: user.phone,
            date_of_birth: user.date_of_birth
        });
    }, []);

    const {values, errors, touched, setFieldTouched, handleChange, handleBlur, handleSubmit} = 
    useFormik({
        enableReinitialize: true,
        initialValues: formData,
        validationSchema: profileValidate,
        onSubmit: (values) => handleFormSubmit(values)
    });

    const handleFormSubmit = async (data) => {
        setLoad(true);

        let response = await axios.post(
            BASE_URL + ADMIN.profile.edit,
            data, 
            { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((resp) => {
            dispatch(
                updateUserData({
                    user: resp.data.data
                })
            );
            setLoad(false);
            setShow(true);
        })
        .catch(err => {
            console.error(err.response.data.message);
            setLoad(false);
        })
    }

    const actionMethod = () => {
        setShow(false)
        navigate('/admin/profile')
    }

    // function changePic(e) {
    //     setLoad2(true);
    //     setTimeout(() => {
    //         setLoad2(false)
    //         setProfilePic(URL.createObjectURL(e.target.files[0]))
    //     }, 1000);
    // }

    return (
        <>
            <Layout>
                <div className="row">
                    <div className="col-12">
                        <h3>
                            <Link to="/admin/profile" className='white-text'>
                                <FontAwesomeIcon icon={faAngleLeft} />
                            </Link>
                            Edit Profile
                        </h3>
                        {/* <div className="admin-img mt-3">
                            <img 
                                src={profilePic} 
                                alt="" 
                                className="img-fluid" 
                            />
                            <label htmlFor={!load2 && "upload-dp"} className="camera-btn">
                                {load2 ? (
                                    <div className="spinner-border text-white loader spinner-border-sm" role="status"></div>
                                ) : (
                                    <FontAwesomeIcon icon={faPencil} />
                                )}
                            </label>
                            <div className="d-none"><input type="file" onChange={changePic} id="upload-dp" /></div>
                        </div> */}
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-xxl-4 col-xl-6 col-md-8">
                                    <div className='my-3'>
                                        <label className="mb-1">Name *</label>
                                        <SiteInput 
                                            type="text"
                                            id='first_name' 
                                            value={values.first_name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.first_name && touched.first_name && (
                                            <small className='text-danger ms-1'>{errors.first_name}</small>
                                        )}
                                    </div>
                                    <div className='my-3'>
                                        <label className="mb-1">Phone Number *</label>
                                        <div className="position-relative">
                                            <IntlTelInput
                                                id="phone"
                                                value={values.phone}
                                                onPhoneNumberChange={(isValid, value, selectedCountryData, fullNumber) => {
                                                    handleChange("phone")(fullNumber);
                                                }}
                                                onPhoneNumberBlur={() => setFieldTouched("phone", true)}
                                                containerClassName="intl-tel-input contact-input-drop"
                                                inputClassName="site-input grey-input with-shadow contact-input"
                                            />
                                        </div>
                                        {errors.phone && touched.phone && (
                                            <small className='text-danger ms-1'>{errors.phone}</small>
                                        )}
                                    </div>
                                    <div className='my-3'>
                                        <label className="mb-1">Date Of Birth *</label>
                                        <SiteInput 
                                            type="date" 
                                            id="date_of_birth"
                                            value={values.date_of_birth} 
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.date_of_birth && touched.date_of_birth && (
                                            <small className='text-danger ms-1'>{errors.date_of_birth}</small>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xxl-4 col-xl-6 col-md-8 my-3">
                                    <label className="mb-1">Email</label>
                                    <SiteInput value={profileData.email} readOnly type="email" />
                                </div>
                            </div>
                            <SiteButton type="submit" buttonText="Update" className="mt-4" load={load} />
                            <SiteButton buttonText="Change Password" className="mt-4 ms-2 border-btn" 
                                onClick={() => navigate('/admin/change-password')} 
                            />
                        </form>
                    </div>
                </div>
            </Layout>

            <SiteModal
                show={show}
                handleClose={actionMethod}
                actionMethod={actionMethod}
                lottieIcon={images.checkIcon}
                modalTitle="Sucessfully"
                modalText="Profile Updated Successfully!"
                normalModal
            />
        </>
    )
}
