import React from 'react';
import loginLeft from './images/login-left.png';
import logo from './images/logo.png';
import loginTop from './images/login-top.png';
import loginBottom from './images/login-bottom.png';
import sideBarIcon1 from './images/sidebar-icons/1.png';
import sideBarIcon2 from './images/sidebar-icons/2.png';
import sideBarIcon3 from './images/sidebar-icons/3.png';
import sideBarIcon4 from './images/sidebar-icons/4.png';
import sideBarIcon5 from './images/sidebar-icons/5.png';
import sideBarIcon6 from './images/sidebar-icons/6.png';
import sideBarIcon7 from './images/sidebar-icons/7.png';
import sideBarIcon8 from './images/sidebar-icons/8.png';
import sideBarIcon9 from './images/sidebar-icons/9.png';
import sideBarIcon10 from './images/sidebar-icons/10.png';
import sideBarIcon11 from './images/sidebar-icons/11.png';
import profilePic from './images/profile-pic.png';
import notiIcon from './images/noti-icon.png';
import statIcon1 from './images/stat-icon-1.png';
import statIcon2 from './images/stat-icon-2.png';
import statIcon3 from './images/stat-icon-3.png';
import userImg from './images/user-img.png';
import propertyImg1 from './images/property-1.png';
import propertyImg2 from './images/property-2.png';
import propertyImg3 from './images/property-3.png';
import ad1 from './images/ad-1.png';
import ad2 from './images/ad-2.png';
import ad3 from './images/ad-3.png';
import ad4 from './images/ad-4.png';
import notiBell from './images/bell-icon.png';
import fbIcon from './images/fb.png';
import tiktokIcon from './images/tik-tok.png';
import instaIcon from './images/insta.png';
import linkedinIcon from './images/linkedin.png';
import youtubeIcon from './images/youtube.png';
import twitterIcon from './images/twitter.png';
import pintrestIcon from './images/pintrest.png';
import bannerImg from './images/banner.png';
import rentAd1 from './images/rent-ad-1.png';
import rentAd2 from './images/rent-ad-2.png';
import rentAd3 from './images/rent-ad-3.png';
import rentAd4 from './images/rent-ad-4.png';
import aboutImg from './images/about-img.png';
import videoThumbnail from './images/video.png';
import video from './images/video.mp4';
import reviewerImg1 from './images/reviewer-img-1.png';
import reviewerImg2 from './images/reviewer-img-2.png';
import chatIcon from './images/chat.json';
import messageIcon from './images/message.json';
import userCheckIcon from './images/check-icon.png';
import loginLogo from './images/login-logo.png';
import rentAdThumbnail1 from './images/rent-ad-thumbnail-1.png';
import rentAdThumbnail2 from './images/rent-ad-thumbnail-2.png';
import rentAdThumbnail3 from './images/rent-ad-thumbnail-3.png';
import questionImg from './images/question.png';
import error404 from './images/error.png';
import userProfilePic from './images/user-profile-pic.png';
import uploadIcon from './images/upload-icon.png';
import previewPlaceholder from './images/preview-placeholder.png';
import cameraIcon from './images/camera-icon.png';
import mapImg from './images/map.png';
import userApartment from './images/interested-user-apartment.png';

export const images = {
    loginLeft,
    logo,
    loginTop,
    loginBottom,
    sideBarIcon1,
    sideBarIcon2,
    sideBarIcon3,
    sideBarIcon4,
    sideBarIcon5,
    sideBarIcon6,
    sideBarIcon7,
    sideBarIcon8,
    sideBarIcon9,
    sideBarIcon10,
    sideBarIcon11,
    profilePic,
    notiIcon,
    statIcon1,
    statIcon2,
    statIcon3,
    userImg,
    propertyImg1,
    propertyImg2,
    propertyImg3,
    ad1,
    ad2,
    ad3,
    ad4,
    notiBell,
    fbIcon,
    tiktokIcon,
    instaIcon,
    linkedinIcon,
    youtubeIcon,
    twitterIcon,
    pintrestIcon,
    bannerImg,
    rentAd1,
    rentAd2,
    rentAd3,
    rentAd4,
    aboutImg,
    videoThumbnail,
    video,
    reviewerImg1,
    reviewerImg2,
    chatIcon,
    messageIcon,
    userCheckIcon,
    loginLogo,
    rentAdThumbnail1,
    rentAdThumbnail2,
    rentAdThumbnail3,
    questionImg,
    error404,
    userProfilePic,
    uploadIcon,
    previewPlaceholder,
    cameraIcon,
    mapImg,
    userApartment,
}
