import React, { useState } from "react";
import { UserLayout } from "../../../Components/UserComponents/UserLayout/userLayout";
import { InnerBanner } from "../../../Components/UserComponents/InnerBanner";
import SiteButton from "../../../Components/Button/button";
import { useNavigate } from "react-router";
import { SiteModal } from "../../../Components/SiteModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SiteInput from "../../../Components/Input/input";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { images } from "../../../Assets";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { FORGOT_PASSWORD } from "../../../config/constants";
import { Post } from "../../../config/api/post";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const UserProfile = () => {
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const [loading, setLoading] = React.useState(false);
  const [validated, setValidated] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");

  const [isAuth, setIsAuth] = useState(false);
  useEffect(() => {
    setIsAuth(isAuthenticated());
  }, []);

  const isAuthenticated = () => {
    const token = user?.role?.role_name;
    return token;
  };

  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const handleClose3 = () => {
    setShow3(false);
    setShow4(true);
  };
  const handleShow3 = () => {
    setShow3(true);
  };
  const handleClose4 = () => setShow4(false);

  const [eyeIcon, seteyeIcon] = useState(faEyeSlash);
  const [passwordType, setPasswordType] = useState(0);
  const toggleIcon = () => {
    if (eyeIcon == faEyeSlash) {
      seteyeIcon(faEye);
      setPasswordType(1);
    } else {
      seteyeIcon(faEyeSlash);
      setPasswordType(0);
    }
  };
  const [eyeIcon2, seteyeIcon2] = useState(faEyeSlash);
  const [passwordType2, setPasswordType2] = useState(0);
  const toggleIcon2 = () => {
    if (eyeIcon2 == faEyeSlash) {
      seteyeIcon2(faEye);
      setPasswordType2(1);
    } else {
      seteyeIcon2(faEyeSlash);
      setPasswordType2(0);
    }
  };
  const [eyeIcon3, seteyeIcon3] = useState(faEyeSlash);
  const [passwordType3, setPasswordType3] = useState(0);
  const toggleIcon3 = () => {
    if (eyeIcon3 == faEyeSlash) {
      seteyeIcon3(faEye);
      setPasswordType3(1);
    } else {
      seteyeIcon3(faEyeSlash);
      setPasswordType3(0);
    }
  };
  const changePassword = async () => {};
  const handleClick = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    setLoading(true);

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setLoading(false);
    } else {
      let data = {
        old_password: oldPassword,
        new_password: password,
        confirm_password: confirmPassword,
      };
      Post(FORGOT_PASSWORD.changePassword, data, token)
        .then((response) => {
          // console.log(response.data);
          setLoading(false);
          if (response?.data?.status) {
            setOldPassword("");
            setPassword("");
            setConfirmPassword("");
            handleClose3();
            MySwal.fire({
              title: "Success!",
              text: response?.data?.message,
              icon: "success",
              confirmButtonColor: "#19D8C5",
              confirmButtonText: "Done",
            });
            console.log("response", response?.data?.message);
          } else {
            MySwal.fire({
              title: "Error!",
              text: response?.data?.message,
              icon: "error",
              confirmButtonColor: "#19D8C5",
              confirmButtonText: "Cancel",
            });
          }
          if (response?.response?.status === 422) {
            setLoading(false);

            MySwal.fire({
              title: "Error!",
              text: response.response.data.message,
              icon: "error",
              confirmButtonColor: "#19D8C5",
              confirmButtonText: "Cancel",
            });
          }
        })
        .catch((e) => {
          console.log(":::;", e);
          MySwal.fire({
            title: "Error!",
            text: "Something went wrong",
            icon: "error",
            confirmButtonColor: "#19D8C5",
            confirmButtonText: "Cancel",
          });
          setLoading(false);
        });
    }
  };
  return (
    <>
      <UserLayout>
        <InnerBanner
          backButton="false"
          bannerHeading="My"
          coloredHeading="Profile"
        />
        <section className="my-profile">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6 my-2">
                <label htmlFor="" className="semi-bold l-blue-text">
                  First Name
                </label>
                <p className="mb-0 semi-bold">{user?.first_name}</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 my-2">
                <label htmlFor="" className="semi-bold l-blue-text">
                  Last Name
                </label>
                <p className="mb-0 semi-bold">{user?.last_name}</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 my-2">
                <label htmlFor="" className="semi-bold l-blue-text">
                  Email Address
                </label>
                <p className="mb-0 semi-bold">{user?.email}</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 my-2">
                <label htmlFor="" className="semi-bold l-blue-text">
                  Contact Number
                </label>
                <p className="mb-0 semi-bold">{user?.phone}</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 my-2">
                <label htmlFor="" className="semi-bold l-blue-text">
                  Gender
                </label>
                <p className="mb-0 semi-bold">{user?.gender}</p>
              </div>
              <div className="col-12 my-2">
                <label htmlFor="" className="semi-bold l-blue-text">
                  Bio
                </label>
                <p className="mb-0 semi-bold">{user?.bio}</p>
              </div>
              <div className="col-12 mt-lg-5 mt-3 mb-2">
                <div className="d-flex align-items-center justify-content-center flex-wrap gap-3">
                  <SiteButton
                    className="mt-2"
                    onClick={() => navigate("/edit-profile")}
                    buttonText="Edit Profile"
                  />
                  <SiteButton
                    className="mt-2 grey-btn"
                    onClick={handleShow3}
                    buttonText="Change Password"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </UserLayout>
      <SiteModal show={show3} handleClose={handleClose3}>
        <p className="cyan-text text-center p-md semi-bold">Change Password</p>
        <Form
          className="mt-4 px-sm-3"
          noValidate
          validated={validated}
          onSubmit={handleClick}
        >
          <Form.Group controlId="validationCustom01">
            <Form.Label className="site-label ms-3 bold mb-1">
              Old Password<span className="red-text">*</span>
            </Form.Label>
          </Form.Group>
          <div className="position-relative">
            <Form.Control
              required
              className="grey-input password-input with-shadow"
              placeholder="Enter old Password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              type={passwordType == 0 ? "password" : "text"}
            />
            <Form.Control.Feedback type="invalid">
              Please enter old password.
            </Form.Control.Feedback>
            <FontAwesomeIcon
              className="right-icon cyan-text"
              onClick={toggleIcon}
              icon={eyeIcon}
            />
          </div>
          <Form.Group controlId="validationCustom02">
            <Form.Label className="site-label ms-3 bold mb-1">
              New Password<span className="red-text">*</span>
            </Form.Label>
          </Form.Group>
          <div className="position-relative">
            <Form.Control
              required
              className="grey-input password-input with-shadow"
              placeholder="Enter new Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={passwordType2 == 0 ? "password" : "text"}
            />
            <Form.Control.Feedback type="invalid">
              Please enter new password.
            </Form.Control.Feedback>
            <FontAwesomeIcon
              className="right-icon cyan-text"
              onClick={toggleIcon2}
              icon={eyeIcon2}
            />
          </div>
          <Form.Group controlId="validationCustom03">
            <Form.Label className="site-label ms-3 bold mb-1">
              Confirm Password<span className="red-text">*</span>
            </Form.Label>
          </Form.Group>
          <div className="position-relative">
            <Form.Control
              required
              className="grey-input password-input with-shadow"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              type={passwordType3 == 0 ? "password" : "text"}
            />
            <Form.Control.Feedback type="invalid">
              Please enter confirm password.
            </Form.Control.Feedback>
            <FontAwesomeIcon
              className="right-icon cyan-text"
              onClick={toggleIcon3}
              icon={eyeIcon3}
            />
          </div>
          <div className="text-center mt-3">
            {/* <SiteButton
              buttonText="Ok"
              className="mt-3"
              onClick={handleClose3}
            /> */}
            <button
              type="submit"
              className="site-btn mt-3"
              disabled={loading ? true : false}
            >
              {loading ? "Loading..." : "Continue"}
            </button>
          </div>
        </Form>
      </SiteModal>
    </>
  );
};
