import React from "react";
import { SetupCard } from "./SetupCard";

export const WithSetup = () => {
  const setupData = [
    {
      id: 1,
      heading: "Guest Identity ",
      coloredHeading: "Verification",
      description:
        "We believe in the complete verification of both parties getting into a rental agreement through legal ways. Every essential personal identity document must be posted on the portal to support authenticity.",
    },
    {
      id: 2,
      heading: "Reservation ",
      coloredHeading: "Screening",
      description:
        "Roomies and Rentals suggest evaluating people by meeting them through proper channels and finding potential candidates for renting your dear place. ",
    },
    {
      id: 3,
      heading: "$3M Damage ",
      coloredHeading: "Protection",
      description:
        " (unable to provide content for this section. Kindly clarify what the client wants us to write.)",
    },
    {
      id: 4,
      heading: "24-Hour ",
      coloredHeading: "Safety Line",
      description:
        "Download our mobile application to get the quickest safety and security services 24/7 during your stay at our suggested place. It will be activated 24 hours before check-in and terminated after 24 hours of check-out.",
    },
    {
      id: 5,
      heading: "$1M Liability ",
      coloredHeading: "Insurance",
      description: "Vague (kindly provide clearance)",
    },
  ];

  return (
    <section className="with-setup">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3 className="with-setup-heading">
              Be a Part of Roomies and Rentals and
              <span className="cyan-text bold"> Start Renting Out</span>
            </h3>
            <p className="text-center mt-3">
              Find the most reliable tenants that will live and care for your
              abode.
            </p>
          </div>
        </div>
        <div className="row">
          {setupData.map((item) => (
            <div className="col-lg-4 col-md-6 my-3">
              <SetupCard item={item} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
