import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import SiteInput from '../../Input/input';
import SiteButton from '../../Button/button';

export function CanvasFilters (props) {
  const today = new Date().toISOString().split("T")[0];
  const [canvasShow, setCanvasShow] = useState(false);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const handleCanvasClose = () => setCanvasShow(false);
  const handleCanvasShow = () => setCanvasShow(true);

  const applyFilters = () => {
    props.setFilters((filters) => ({
      ...filters, 
      from,
      to,
      status: props.status
    }));
    handleCanvasClose();
  }

  return (
    <>
      <Button onClick={handleCanvasShow} className="mt-3 filter-btn">
        <FontAwesomeIcon icon={faFilter} />
      </Button>

      <Offcanvas show={canvasShow} onHide={handleCanvasClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filters</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <label htmlFor="" className='mb-1'>From</label>
          <SiteInput 
            type="date" 
            id="from"
            value={from ?? props?.filters?.from}
            max={to ? to : today}
            onChange={e => {
              setFrom(e.target.value);
              document.getElementById('to').setAttribute('min', e.target.value);
            }}
          />

          <label htmlFor="" className='mb-1 mt-3'>To</label>
          <SiteInput 
            type="date" 
            id="to"
            value={to ?? props?.filters?.to}
            min={from}
            max={today}
            onChange={e => {
              setTo(e.target.value);
              document.getElementById('from').setAttribute('max', e.target.value);
            }}
          />

          {props.children}
          <div className="text-center">
            <SiteButton className="mt-4" onClick={applyFilters} buttonText="Apply"></SiteButton>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

// render(<CanvasFilters />);