import React, { useState } from "react";
import { UserLayout } from "../../../Components/UserComponents/UserLayout/userLayout";
import { InnerBanner } from "../../../Components/UserComponents/InnerBanner";
import SiteButton from "../../../Components/Button/button";
import { useNavigate } from "react-router";
import SiteInput from "../../../Components/Input/input";
import SiteTextarea from "../../../Components/Input/textarea";
import { SiteModal } from "../../../Components/SiteModal";
import { images } from "../../../Assets";
import { useEffect } from "react";
import { CustomSelect } from "../../../Components/Select";
import { useSelector, useDispatch } from "react-redux";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Post } from "../../../config/api/post";
import { PROFILE } from "../../../config/constants";
import { updateUserData } from "../../../redux/slice/authSlice";
import IntlTelInput from "react-intl-tel-input";
import 'react-intl-tel-input/dist/main.css';

export const EditUserProfile = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [validated, setValidated] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [bio, setBio] = useState("");

  useEffect(() => {
    setIsAuth(isAuthenticated());
    setFirstName(user?.first_name);
    setLastName(user?.last_name);
    setEmail(user?.email);
    setPhone(user?.phone);
    setGender(user?.gender);
    setBio(user?.bio);
  }, []);

  const isAuthenticated = () => {
    const token = user?.role?.role_name;
    return token;
  };
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    navigate("/profile");
  };

  const handleClick = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    setLoading(true);

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setLoading(false);
    } else {
      let data = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phone,
        gender: gender,
        bio: bio,
      };
      console.log(data);
      Post(PROFILE.edit, data, token)
        .then((response) => {
          // console.log(response.data);
          setLoading(false);
          if (response?.data?.status) {
            MySwal.fire({
              title: "Success!",
              text: response?.data?.message,
              icon: "success",
              confirmButtonColor: "#19D8C5",
              confirmButtonText: "Done",
            });
            dispatch(
              updateUserData({
                user: response?.data?.data,
              })
            );
            navigate("/profile", { replace: true });
            console.log("response", response?.data?.data);
          } else {
            MySwal.fire({
              title: "Error!",
              text: response?.data?.message,
              icon: "error",
              confirmButtonColor: "#19D8C5",
              confirmButtonText: "Cancel",
            });
          }
          if (response?.response?.status === 422) {
            setLoading(false);

            MySwal.fire({
              title: "Error!",
              text: response.response.data.message,
              icon: "error",
              confirmButtonColor: "#19D8C5",
              confirmButtonText: "Cancel",
            });
          }
        })
        .catch((e) => {
          MySwal.fire({
            title: "Error!",
            text: "Something went wrong",
            icon: "error",
            confirmButtonColor: "#19D8C5",
            confirmButtonText: "Cancel",
          });
          setLoading(false);
        });
    }
  };
  return (
    <>
      <UserLayout>
        <InnerBanner bannerHeading="Edit" coloredHeading="Profile" />
        <section className="my-profile">
          <div className="container">
            <Form
              className="mt-4 px-sm-3"
              noValidate
              validated={validated}
              onSubmit={handleClick}
            >
              <div className="row align-items-center">
                <div className="col-md-6 my-2">
                  <Form.Group as={Col} controlId="validationCustom01">
                    <Form.Label className="ms-3 bold">First Name*</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      className="site-input l-blue-text"
                      placeholder="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter first name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-md-6 my-2">
                  <Form.Group as={Col} controlId="validationCustom02">
                    <Form.Label className="ms-3 bold">Last Name*</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      className="site-input l-blue-text"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter last name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-md-6 my-2">
                  <label htmlFor="" className="semi-bold ms-3 mb-2">
                    Contact Number*
                  </label>
                  <IntlTelInput
                    id="phone"
                    value={phone}
                    preferredCountries={['us']}
                    inputClassName="site-input l-blue-text form-control"
                    containerClassName="intl-tel-input text-dark"
                    onPhoneNumberChange={(isValid, value, selectedCountryData, fullNumber) => {
                      setPhone(fullNumber);
                    }}
                  />
                </div>
                <div className="col-md-6 my-2">
                  <label htmlFor="" className="l-blue-text p-md semi-bold ms-3">
                    Email Address
                  </label>
                  <p className="mb-0 semi-bold p-md ms-3">{user?.email}</p>
                </div>
                <div className="col-md-6 my-2">
                  <label htmlFor="" className="semi-bold ms-3 mb-2">
                    Gender*
                  </label>
                  <CustomSelect
                    value={gender}
                    className="l-blue-text filter-select"
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </CustomSelect>
                </div>
                <div className="col-12 my-2">
                  <Form.Group as={Col} controlId="validationCustom02">
                    <Form.Label className="semi-bold mb-2 ms-3">
                      Bio*
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      className="site-input"
                      placeholder="Enter Bio"
                      value={bio}
                      onChange={(e) => setBio(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div className="col-12 mt-lg-5 text-center mb-2">
                  {/* <SiteButton
                    className="mt-2"
                    onClick={handleShow}
                    buttonText="Update"
                  /> */}
                  <button
                    type="submit"
                    className="site-btn mt-2"
                    disabled={loading ? true : false}
                  >
                    {loading ? "Loading..." : "Update"}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </section>
      </UserLayout>
      <SiteModal
        show={show}
        handleClose={handleClose}
        modalImg={images.userCheckIcon}
        modalText="Profile Updated Successfully"
        normalModal
      />
    </>
  );
};
