import React from "react";
import { UserLayout } from "../../../Components/UserComponents/UserLayout/userLayout";
import { InnerBanner } from "../../../Components/UserComponents/InnerBanner";
import { AboutRoomies } from "../Home/AboutRoomies";
import { HomeVideo } from "../Home/HomeVideo";
import { Testimonials } from "../Home/Testimonials";

export const AboutUs = () => {
  return (
    <UserLayout>
      <InnerBanner
        backButton="false"
        bannerHeading="About"
        coloredHeading="Us"
      />
      <AboutRoomies />
      <HomeVideo />
      <Testimonials />
    </UserLayout>
  );
};
