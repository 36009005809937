import React from "react";
import { UserLayout } from "../../../Components/UserComponents/UserLayout/userLayout";
import { InnerBanner } from "../../../Components/UserComponents/InnerBanner";
import SiteButton from "../../../Components/Button/button";
import { PlaceDetailSlider } from "./PlaceDetailSlider";
import { SiteModal } from "../../../Components/SiteModal";
import { images } from "../../../Assets";
import { useState } from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import SiteInput from "../../../Components/Input/input";
import SiteTextarea from "../../../Components/Input/textarea";
import Select from "react-select";
import { ProgressBar } from "react-bootstrap";
import ImageUploader from "../../../Components/ImageUploader/ImageUploader";
import SyncedSlider from "./PlaceDetailSlider/SyncedSlider";
import SyncedSlider2 from "./PlaceDetailSlider/SyncedSlider2";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FormPost, Post } from "../../../config/api/post";
import { ADS, CONTACT_US, RENTER } from "../../../config/constants";
import { useSelector } from "react-redux";
import { Get } from "../../../config/api/get";
import { useNavigate, useParams } from "react-router";
import { Quantity } from "../../../Components/UserComponents/Quantity";

export const EditRentPlace = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const token = useSelector((state) => state.user.userToken);

  const productImages = [
    images.rentAdThumbnail1,
    images.rentAdThumbnail2,
    images.rentAdThumbnail3,
  ];
  const MySwal = withReactContent(Swal);
  const [renterName, setRenterName] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [preferedRoommate, setPreferedRoommate] = useState("");
  const [postedOn, setPostedOn] = useState("");
  const [address, setAddress] = useState("");
  const [additionalDetails, setAdditionalDetails] = useState("");
  const [intersts, setIntersts] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [rawFiles, setRawFiles] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [validated, setValidated] = useState(false);
  const [options, setOptions] = useState([
    {
      id: 1,
      interest_name: "Tiktok",
      status: 1,
      created_at: "2023-08-01T13:04:19.000000Z",
      updated_at: "2023-08-01T13:04:19.000000Z",
    },
  ]);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show2, setShow2] = useState(false);
  const handleShow2 = () => setShow2(true);
  const handleClose2 = () => setShow2(false);
  const [show3, setShow3] = useState(false);
  const handleShow3 = () => {
    setShow2(false);
    setShow3(true);
  };
  const handleClose3 = () => setShow3(false);
  const [show4, setShow4] = useState(false);
  const handleShow4 = () => {
    setShow3(false);
    setShow4(true);
  };
  const handleClose4 = () => setShow4(false);
  const [isAuth, setIsAuth] = useState(false);
  useEffect(() => {
    setIsAuth(isAuthenticated());
    getInterests();
  }, []);

  const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    return token;
  };
  const [ad, setAd] = useState({
    id: "",
    renter_id: "4",
    renter_name: "test",
    preferred_roommate: "Female",
    address: "Address",
    area: "Address",
    city: "Address",
    additional_details: "Additional",
    status: 1,
    date_posted: "21/11/2023",
    images: [
      {
        "id,": 24,
        ads_id: 37,
        image_name: null,
        image:
          "http://localhost/roomie-and-rental/storage/ads/ads_202311211217340000000.jpg",
      },
      {
        "id,": 25,
        ads_id: 37,
        image_name: null,
        image:
          "http://localhost/roomie-and-rental/storage/ads/ads_202311211217340000000.jpg",
      },
    ],
    interested_users: [
      {
        name: "John Doe",
        email: "abdulbasit@metafroliclabs.com",
        phone: null,
        gender: null,
      },
    ],
    apartment_features: [
      {
        "id,": 55,
        ads_id: 37,
        feature: "Kitchen",
      },
      {
        "id,": 56,
        ads_id: 37,
        feature: "Air Condition",
      },
      {
        "id,": 57,
        ads_id: 37,
        feature: "Washing Machine",
      },
      {
        "id,": 58,
        ads_id: 37,
        feature: "Street View",
      },
      {
        "id,": 59,
        ads_id: 37,
        feature: "Bathroom",
      },
      {
        "id,": 60,
        ads_id: 37,
        feature: "Air Condition",
      },
    ],
    interests: [
      {
        "id,": 1,
        ads_id: 37,
        interest_name: "Tiktok",
      },
      {
        "id,": 2,
        ads_id: 37,
        interest_name: "Cooking",
      },
      {
        "id,": 3,
        ads_id: 37,
        interest_name: "Football",
      },
    ],
  });
  const [featureValue, setFeatureValue] = useState([]);
  const handleChange = (index, event) => {
    const updatedValues = [...featureValue];
    updatedValues[index] = event.target.value;
    setFeatureValue(updatedValues);
  };
  // const options = [
  //   { value: "tiktok", label: "Tikt  ok" },
  //   { value: "cooking", label: "Cooking" },
  //   { value: "football", label: "Football" },
  // ];
  const handleImageUpload = async (files) => {
    setRawFiles(files);
    // Assuming you want to display image previews
    const fileURLs = files.map((file) => URL.createObjectURL(file));
    setUploadedImages(fileURLs);
  };
  const getInterests = () => {
    Get(RENTER.getInterents, token)
      .then((response) => {
        let interestsOptions = [];

        if (response?.status) {
          response?.data.map(
            (value, index) =>
              (interestsOptions[index] = {
                value: value.id,
                label: value.interest_name,
              })
          );
          setOptions(interestsOptions);
          setLoading(false);
        }
        if (response?.response?.status === 422) {
          setLoading(false);
          MySwal.fire({
            title: "Error!",
            text: response.response.data.message,
            icon: "error",
            confirmButtonColor: "#19D8C5",
            confirmButtonText: "Cancel",
          });
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        MySwal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
          confirmButtonColor: "#19D8C5",
          confirmButtonText: "Cancel",
        });
        setLoading(false);
      });
  };
  const handleClick = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setLoading(false);
    } else {
      setLoading(false);
      let data = {
        id: ad.id,
        renter_name: ad.renter_name,
        preferred_roommate: ad.preferred_roommate,
        address: ad.address,
        area: ad.area,
        city: ad.city,
        date_posted: ad.date_posted,
        additional_details: ad.additional_details,
        interests: JSON.stringify(selectedOptions),
        images: rawFiles,
        apartment_features: JSON.stringify(featureValue),
      };
      console.log("------------------");
      console.log(data);

      FormPost(RENTER.updateAd, data, token)
        .then((response) => {
          // console.log(response.data);
          setLoading(false);
          if (response?.data?.status) {
            MySwal.fire({
              title: "Success!",
              text: response?.data?.message,
              icon: "success",
              confirmButtonColor: "#19D8C5",
              confirmButtonText: "Done",
            });
            navigate("/view-add/" + id, {
              replace: true,
            });
            console.log("response", response?.data?.data);
          } else {
            MySwal.fire({
              title: "Error!",
              text: response?.data?.message,
              icon: "error",
              confirmButtonColor: "#19D8C5",
              confirmButtonText: "Cancel",
            });
          }
          if (response?.response?.status === 422) {
            setLoading(false);

            MySwal.fire({
              title: "Error!",
              text: response.response.data.message,
              icon: "error",
              confirmButtonColor: "#19D8C5",
              confirmButtonText: "Cancel",
            });
          }
        })
        .catch((e) => {
          console.log(":::;", e);
          MySwal.fire({
            title: "Error!",
            text: "Something went wrong",
            icon: "error",
            confirmButtonColor: "#19D8C5",
            confirmButtonText: "Cancel",
          });
          setLoading(false);
        });
    }
  };
  const getInterestedAds = async () => {
    setLoading(true);
    let path = ADS.AdDetail + id;
    console.log(path);
    Get(path, token)
      .then((response) => {
        console.log(response);
        if (response?.status) {
          let interestsOptions = [];
          let featuresOptions = [];

          setAd(response?.data);
          setLoading(false);
          response?.data.interests.map(
            (value, index) =>
              (interestsOptions[index] = {
                value: value.id,
                label: value.interest_name,
              })
          );
          console.log("----------");
          console.log(response?.data.apartment_features);
          response?.data.apartment_features.map(
            (value, index) =>
              (featuresOptions[index] = {
                id: value.id,
                name: value.feature,
                quantity: value.quantity,
              })
          );
          // setOptions(interestsOptions);
          setFeatureValue(featuresOptions);
          setSelectedOptions(interestsOptions);
          // console.log(ad.renter_name);
        }
        if (response?.response?.status === 422) {
          setLoading(false);
          MySwal.fire({
            title: "Error!",
            text: response.response.data.message,
            icon: "error",
            confirmButtonColor: "#19D8C5",
            confirmButtonText: "Cancel",
          });
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        MySwal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
          confirmButtonColor: "#19D8C5",
          confirmButtonText: "Cancel",
        });
        setLoading(false);
      });
  };
  useEffect(() => {
    getInterestedAds();
  }, []);
  const updateProperty = (propertyName, newValue) => {
    setAd((ad) => ({
      ...ad, // Spread the previous state
      [propertyName]: newValue, // Update the specific property
    }));
  };
  const handleChangeQuantity = (e) => {
    const updatedState = featureValue.map((obj) =>
      obj.id === e.id ? { ...obj, quantity: e.quantity } : obj
    );
    setFeatureValue(updatedState);
  };
  return (
    <>
      <UserLayout>
        <InnerBanner bannerHeading="Edit" coloredHeading="Rent Place" />
        <section className="rent-place-details py-sm-5 py-4">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 position-relative my-3">
                {uploadedImages.length > 0 ? (
                  <SyncedSlider
                    mainImages={uploadedImages}
                    navImages={uploadedImages}
                  />
                ) : (
                  <SyncedSlider2 mainImages={ad.images} />
                )}
              </div>
              <div className="col-lg-6 my-3 ps-lg-5">
                <div className="d-flex gap-3">
                  <div className="flex-grow-1">
                    <h3 className="regular mb-2">
                      Apartment{" "}
                      <span className="cyan-text semi-bold"> Features</span>
                    </h3>
                    {featureValue.map((item) => (
                      <div className="d-flex align-items-center gap-2">
                        <SiteInput className="mt-3" value={item.name} />
                        <div className="mt-3">
                          <Quantity
                            key={item.id}
                            initialValue={item.quantity}
                            featureId={item.id}
                            onChangeQuantity={handleChangeQuantity}
                          />
                        </div>
                      </div>
                    ))}
                    <div className="mt-3">
                      <button
                        className="transparent-btn white-text d-inline-flex gap-2 align-items-center underline"
                        // onClick={handleShow2}
                      >
                        <img
                          src={images.uploadIcon}
                          alt=""
                          className="img-fluid"
                        />
                        <span className="p-sm semi-bold">
                          <ImageUploader onUpload={handleImageUpload} />
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className="rent-details-tag rent-active"
                    >
                      <span>
                        <FontAwesomeIcon icon={faCircle} />
                      </span>{" "}
                      Active
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <Form
                className="mt-4 px-sm-3"
                noValidate
                validated={validated}
                onSubmit={handleClick}
              >
                <div className="col-12">
                  <div className="my-3">
                    <h3 className="regular mb-0">Renter Contact Details</h3>
                  </div>
                  <div className="row">
                    <div className="col-xl-3 col-md-4 col-12 my-2">
                      <Form.Group as={Col} controlId="validationCustom01">
                        <Form.Label className="semi-bold ms-3">
                          Appartment Name*
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          className="site-input"
                          placeholder="Enter Appartment Name"
                          value={ad.renter_name}
                          onChange={(e) =>
                            updateProperty("renter_name", e.target.value)
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter appartment name.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-xl-3 col-md-4 col-12 my-2">
                      <Form.Group as={Col} controlId="validationCustom02">
                        <Form.Label className="semi-bold ms-3">
                          Prefferd Roommate*
                        </Form.Label>
                        <Form.Control
                          required
                          as="select"
                          type="select"
                          rows={4}
                          className="site-input multiple-select-box"
                          onChange={(e) =>
                            updateProperty("preferred_roommate", e.target.value)
                          }
                        >
                          <option value="" disabled selected>
                            Select Prefferd Roommate
                          </option>
                          <option
                            value="male"
                            selected={
                              ad.preferred_roommate === "male" ? true : false
                            }
                          >
                            Male
                          </option>
                          <option
                            value="female"
                            selected={
                              ad.preferred_roommate === "female" ? true : false
                            }
                          >
                            Female
                          </option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please select prefferd roommate.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    {/* <div className="col-xl-3 col-md-4 col-12 my-2">
                      <Form.Group as={Col} controlId="validationCustom03">
                        <Form.Label className="semi-bold ms-3">
                          Posted On*
                        </Form.Label>
                        <Form.Control
                          required
                          type="date"
                          className="site-input"
                          placeholder="Enter Posted On"
                          value={ad.date_posted}
                          // onChange={(e) => setPostedOn(e.target.value)}
                          onChange={(e) =>
                            updateProperty("date_posted", e.target.value)
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter posted on.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div> */}
                    <div className="col-xl-3 col-md-4 col-12 my-2">
                      <Form.Group as={Col} controlId="validationCustom03">
                        <Form.Label className="semi-bold ms-3">
                          Address*
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          className="site-input"
                          placeholder="Enter Address"
                          value={ad.address}
                          // onChange={(e) => setPostedOn(e.target.value)}
                          onChange={(e) =>
                            updateProperty("address", e.target.value)
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter Address.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-xl-3 col-md-4 col-12 my-2">
                      <Form.Group as={Col} controlId="validationCustom03">
                        <Form.Label className="semi-bold ms-3">
                          Area*
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          className="site-input"
                          placeholder="Enter Area"
                          value={ad.area}
                          onChange={(e) =>
                            updateProperty("area", e.target.value)
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter area.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-xl-3 col-md-4 col-12 my-2">
                      <Form.Group as={Col} controlId="validationCustom03">
                        <Form.Label className="semi-bold ms-3">
                          City*
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          className="site-input"
                          placeholder="Enter City"
                          value={ad.city}
                          onChange={(e) =>
                            updateProperty("city", e.target.value)
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter city.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 my-2">
                    <Form.Group as={Col} controlId="validationCustom02">
                      <Form.Label className="semi-bold ms-3">
                        Additional Details*
                      </Form.Label>
                      <Form.Control
                        required
                        as="textarea"
                        rows={4}
                        className="site-input"
                        placeholder="Enter Additional Details"
                        value={ad.additional_details}
                        // onChange={(e) => setPostedOn(e.target.value)}
                        onChange={(e) =>
                          updateProperty("additional_details", e.target.value)
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter additional details.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  {selectedOptions.length > 0 ? (
                    <div className="col-xxl-5 col-xl-6 col-lg-8 col-md-10 col-12 my-2">
                      <div className="interest-tags">
                        <label htmlFor="" className="semi-bold ms-3">
                          Interests
                        </label>

                        <Select
                          // isMulti
                          // placeholder="Select Interests"
                          // defaultValue={[options]}
                          // options={options}
                          defaultValue={selectedOptions}
                          isMulti
                          name="colors"
                          options={options ?? options}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={
                            (selectedOptions) =>
                              setSelectedOptions(selectedOptions)
                            // console.log(selectedOptions)
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="col-12 my-4">
                    <button
                      type="submit"
                      className="site-btn mt-2 web-btn"
                      disabled={loading ? true : false}
                    >
                      {loading ? "Loading..." : "Update"}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </section>
      </UserLayout>

      <SiteModal
        show={show}
        handleClose={handleClose}
        modalImg={images.userCheckIcon}
        modalText="New Place Successfully Added"
        normalModal
      />

      <SiteModal show={show2} handleClose={handleClose2}>
        <div className="upload-property-images">
          <img src={images.uploadIcon} alt="" className="img-fluid" />
          <p className="semi-bold">Upload Images</p>
          <SiteButton buttonText="Upload Image" onClick={handleShow3} />
        </div>
        <div className="upload-progress">
          <img
            src={images.previewPlaceholder}
            alt=""
            className="img-fluid preview-img"
          />
          <ProgressBar now={60} />
          <button className="delete-upload">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      </SiteModal>

      <SiteModal show={show3} handleClose={handleClose3}>
        <div className="upload-property-images sm-upload">
          <img src={images.cameraIcon} alt="" className="img-fluid" />
          <SiteButton buttonText="Take From Camera" className="mt-3" />
        </div>
        <div className="upload-property-images mt-4 sm-upload">
          <img src={images.previewPlaceholder} alt="" className="img-fluid" />
          <label
            htmlFor="upload-property-img"
            className="upload-property-btn mt-3"
          >
            Take From Gallery
          </label>
          <div className="d-none">
            <input type="file" multiple name="" id="upload-property-img" />
          </div>
        </div>
        <div className="text-center">
          <SiteButton
            className="mt-4"
            buttonText="Upload"
            onClick={handleShow4}
          />
        </div>
      </SiteModal>

      <SiteModal
        show={show4}
        handleClose={handleClose4}
        modalImg={images.userCheckIcon}
        modalText="Picture Changed Successfully"
        normalModal
      />
    </>
  );
};
