import { useEffect, useRef, useState } from 'react'
import SiteInput from '../../Input/input'
import { CustomSelect } from '../../Select'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CanvasFilters } from '../CanvasFilters'

export const BasicTableFilters = (props) => {
  const [search, setSearch] = useState("");
  const isMounted = useRef(false);

  useEffect(() => {
    if(isMounted.current){
      props.setLoad(true);
      const timeoutId = setTimeout(() => {
        props.setFilters(filters => ({...filters, search}))
      }, 750);
      return () => clearTimeout(timeoutId);
    }
  }, [search])

  return (
    <div className="d-sm-flex mb-4 align-items-center justify-content-between">
        <div className="d-flex flex-shrink-0 align-items-center mt-3">
          <label htmlFor="" className='mb-0'>Show Entries</label>
          <CustomSelect 
            className="w-80 filter-select ms-3 me-sm-3" 
            onChange={e => props.setFilters(filters => ({...filters, records: e.target.value}))}
          >
            <option value="5">5</option>
            <option value="15">15</option>
            <option value="25">25</option>
          </CustomSelect>
        </div>

        <div className="d-flex flex-grow-1 align-items-center justify-content-end">
          {!props.noSearch && (
            <div className="position-relative w-360 me-3 mt-3">
              <SiteInput 
                type="text" 
                id="search"
                className="ps-5" 
                placeholder="Search" 
                autoComplete="off"
                value={search}
                onChange={e => {
                  isMounted.current=true;
                  setSearch(e.target.value);
                }}
              />
              <FontAwesomeIcon icon={faSearch} className="cyan-text left-icon"></FontAwesomeIcon>
            </div>
          )}
          <CanvasFilters status={props.status} filters={props.filters} setFilters={props.setFilters}>
            {props.children}
          </CanvasFilters>
        </div>
    </div>
  )
}
