import { Link } from "react-router-dom";
import { images } from "../../../Assets";

export const NotificationCard = (props) => {
  return (
    <>
      <hr className="grey-line mb-0 mt-2" />
      <Link className={`d-block my-4 noti-block ${props.item?.read_at ? "" : "unread-noti"}`} key={props.item.id}>
        <div className="d-flex gap-2">
          <div className="flex-shrink-0 position-relative">
            <img src={images.notiBell} alt="" className="img-fluid noti-icon" />
          </div>
          <div className="flex-grow-1 gap-2 d-sm-flex align-items-center justify-content-between">
            <div className="flex-grow-1">
              <p className="mb-0">
                {props.item.data.content}
              </p>
            </div>
            <div className="flex-shrink-0">
              <p className="p-sm grey-text semi-bold mb-0">{props.item.time}</p>
            </div>
          </div>
        </div>
        <div className="text-end">
          {!props.item.read_at && (
            <button
                onClick={() => props.handleRead(props.item?.id)} 
                className="transparent-btn blue-text underline p-sm bold"
            >
                Mark as read
            </button>
          )}
        </div>
      </Link>
    </>
  );
};
